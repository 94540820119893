import React from 'react';
import { BriefcaseIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { FitScoreLabelTag } from '/src/util/generateTagCloud';
import VerifiedInvestorBadge from '/src/components/Badges/VerifiedInvestorBadge';
import NavigationBackButton from '/src/components/utility/NavigationBackButton';
import { formatDate, howLongBetween } from '/src/util/formatting/dates';
import { InvestorMeetingView } from '../../../types/view/InvestorMeetingView';

interface MeetingDetailHeaderProps {
  meeting: InvestorMeetingView;
}

function MeetingDetailHeader({
  meeting,
}: MeetingDetailHeaderProps): JSX.Element {
  const investor = meeting.investor;
  return (
    <section>
      <NavigationBackButton className="-mt-4 mb-2" />

      <div className="mx-auto">
        <div className="sm:flex sm:items-center sm:space-x-5">
          <div className="sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="block min-w-0 flex-1 space-y-1">
              <h1 className="flex items-center">
                <span className="text-wrap text-2xl font-bold leading-tight text-gray-900">
                  {meeting.type} meeting with{' '}
                  {`${investor.firstName} ${investor.lastName}`} -{' '}
                  {formatDate(meeting.meetingOccurredOn)} (
                  {howLongBetween(meeting.meetingOccurredOn)})
                </span>
                {investor.isVerified && <VerifiedInvestorBadge />}
              </h1>

              <div className="flex flex-wrap space-x-5">
                <div className="flex items-center">
                  <BriefcaseIcon className="h-5 w-5 text-gray-600" />
                  <p className="truncate pl-1 font-medium text-gray-700">
                    {investor.role && investor.firm
                      ? `${investor.role} @ ${investor.firm}`
                      : 'Angel Investor'}
                  </p>
                </div>

                {investor.location && (
                  <div className="flex items-center">
                    <MapPinIcon className="h-5 w-5 text-gray-600" />
                    <p className="truncate pl-1 font-medium text-gray-600">
                      {investor.location}
                    </p>
                  </div>
                )}

                {investor.fitLabel && (
                  <div className="flex items-center space-x-3">
                    <FitScoreLabelTag
                      fitLabel={investor.fitLabel}
                      suffix="fit investor"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MeetingDetailHeader;
