import React, { useContext, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { ConnectionNoteView } from '../../types/view/ConnectionNoteView';
import { TextEditorCallBackProps } from '../TextEditor/TextEditor';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  DocumentPlusIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import ConnectionNoteEditor, {
  DEFAULT_NOTE_TITLE,
} from './ConnectionNoteEditor';
import ConnectionNoteList from './ConnectionNoteList';
import EmptyState from '../notifications/EmptyState';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SubscriptionTiers } from '../../../../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '../SubscriptionCTA';
import { SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { getEmptyConnectionNotesCount } from '/src/services/lexical/getContentLength';
import { NOTES_PER_CONNECTION_MAX } from '../../../../libs/shared-types/src/constants/ObjectArrayLengthRanges';
import { cn } from '/src/util/cn';

const emptyNoteContentV1 = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
        textFormat: 0,
        textStyle: '',
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};
export interface ConnectionNoteTabProps {
  connectionNotes?: ConnectionNoteView[];
  isLoading: boolean;
  onChange: (
    props: TextEditorCallBackProps,
  ) => Promise<ConnectionNoteView | undefined>;
  onDelete: (connectionNoteId: string) => void;
}

function ConnectionNoteTab({
  connectionNotes,
  isLoading,
  onChange,
  onDelete,
}: ConnectionNoteTabProps) {
  const [selectedNote, setSelectedNote] = useState<ConnectionNoteView | null>(
    connectionNotes?.length ? connectionNotes[0] : null,
  );
  const [isLeftColumnVisible, setIsLeftColumnVisible] = useState(true);
  const [isCreateFirstNoteDisabled, setIsCreateFirstNoteDisabled] =
    useState(true);
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  const toggleLeftColumn = () => {
    setIsLeftColumnVisible(!isLeftColumnVisible);
  };

  const handleChange = async ({
    id,
    content,
    title,
    isEmptyContent,
    childrenSize,
  }: TextEditorCallBackProps) => {
    if (onChange) {
      const updatedNote = await onChange({
        id,
        content,
        title,
        isEmptyContent,
        childrenSize,
      });
      if (updatedNote) {
        setSelectedNote(updatedNote);
      }
    }
  };

  const handleDelete = async () => {
    setIsCreateFirstNoteDisabled(true);
    if (selectedNote?._id) {
      onDelete(selectedNote?._id);
    }
  };

  const handleCreateConnectionNote = async () => {
    const notesWithoutContent = getEmptyConnectionNotesCount(connectionNotes);

    // Only create a new note if there is no empty note
    if (!notesWithoutContent) {
      setIsCreateButtonDisabled(true);
      // create a new connection note with a mock title
      await handleChange({
        id: undefined,
        content: {
          root: emptyNoteContentV1 as SerializedEditorState<SerializedLexicalNode>,
        },
        title: DEFAULT_NOTE_TITLE,
        isEmptyContent: false,
        childrenSize: 1,
      });
      setIsCreateButtonDisabled(false);
    }
  };

  useEffect(() => {
    // Count the number of notes that have no content
    const notesWithoutContent = getEmptyConnectionNotesCount(connectionNotes);

    // if a previous selected note was deleted, selects the last updated
    if (
      connectionNotes?.length &&
      !connectionNotes.some(
        (connectionNote) => connectionNote._id === selectedNote?._id,
      )
    ) {
      setSelectedNote(connectionNotes[0]);
    }

    const canCreateNewNotes = connectionNotes?.length
      ? connectionNotes.length <= NOTES_PER_CONNECTION_MAX
      : false;

    // Disable create button if there is an empty connection note in the list
    setIsCreateButtonDisabled(!!notesWithoutContent || !canCreateNewNotes);
  }, [connectionNotes]);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-2 h-fit w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Write and manage notes in one simple place
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>
            Create notes for each investor to track research, calls, and to-dos
          </li>
          <li>
            Format notes with rich text capabilities and insert links to other
            resources
          </li>
          <li>Keep notes organized by adding a title for each one</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the ability to write
          notes for each investor and much more.
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_connection_detail_connection_notes_tab"
            text="Organize your investor notes today"
          />
        </div>
      </div>
    );
  }

  if (!isLoading && !connectionNotes?.length && isCreateFirstNoteDisabled) {
    return (
      <div className="w-full">
        <EmptyState
          title="You have not set any note for this connection"
          subTitle="Write and organize research and call notes in one place for this investor"
          icon={
            <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
          }
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                setIsCreateFirstNoteDisabled(false);
                handleCreateConnectionNote();
              }}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              Create Note
            </button>
          }
        />
      </div>
    );
  }

  return (
    <div className="flex w-full divide-x-2 border-gray-200 bg-gray-50 p-0 shadow sm:rounded-lg">
      <Transition
        as="div"
        show={isLeftColumnVisible}
        transition
        className={cn(
          'w-1/5 min-w-48',
          'transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0',
        )}
      >
        <section className="flex h-full flex-col space-y-7 pt-3">
          <div className="mx-3 flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleDelete}
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              title="Delete selected note"
              aria-label="Delete selected note"
              disabled={!selectedNote}
            >
              <TrashIcon className="size-6" />
            </button>
            <button
              type="button"
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              title="Create new note"
              aria-label="Create new note"
              onClick={handleCreateConnectionNote}
              disabled={isCreateButtonDisabled}
            >
              <DocumentPlusIcon className="size-6" />
            </button>

            <button
              type="button"
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              title="Hide list of notes"
              aria-label="Hide list of notes"
              onClick={toggleLeftColumn}
            >
              <ChevronDoubleLeftIcon className="size-6" aria-hidden="true" />
            </button>
          </div>

          <ConnectionNoteList
            notes={connectionNotes ?? []}
            onSelect={(newSelectedNote) => setSelectedNote(newSelectedNote)}
            selectedNote={selectedNote}
          />
        </section>
      </Transition>

      <div className="relative flex flex-1 overflow-auto px-6">
        {!isLeftColumnVisible && (
          <button
            onClick={toggleLeftColumn}
            className="absolute -left-6 top-4 rounded-full border-r border-gray-300 bg-gray-100 px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            title="Expand to view list of notes"
            aria-label="Expand to view list of notes"
          >
            <ChevronDoubleRightIcon
              className="ml-1 size-6"
              aria-hidden="true"
            />
          </button>
        )}

        <div className="mt-3 flex w-full flex-1">
          <ConnectionNoteEditor
            connectionNote={selectedNote}
            isLoading={false}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default ConnectionNoteTab;
