enum InvestorTypes {
  Angel = 'Angel',
  FamilyOffice = 'Family Office',
  Syndicator = 'Syndicator',
  VC = 'VC',
  CorporateVC = 'Corporate VC',
  GovernmentalVC = 'Governmental VC',
  Foundation = 'Foundation',
  Accelerator = 'Accelerator',
}

// There must be an option for every investor type in InvestorTypes that can actually invest in a round
// aka not accelerators
export enum RoundOpenToInvestorType {
  Accelerators = 'Accelerators',
  AngelIndividuals = 'Individual Angels',
  AngelSyndicates = 'Angel Syndicates',
  FamilyOffices = 'Family Offices',
  VcCorporateFirms = 'Corporate VC Firms',
  VcFirms = 'VC Firms',
  VcGovernmentalFirms = 'Governmental VC Firms',
  Foundations = 'Foundations',
}

export default InvestorTypes;
