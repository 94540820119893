import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  NOT_FOUND_ROUTE,
  STARTUP_INVESTOR_LEADS_ROUTE,
} from '/src/constants/Routes';
import { ComparableInvestorDiscoveryView } from '/../libs/shared-types/src/types/view/InvestorDiscoveryView';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import { EXAMPLE_INVESTOR_DISCOVERY_VIEW } from '/../libs/shared-types/src/constants/UserActivation/MockData/ExampleInvestor';
import Alert from '/src/components/notifications/Alert';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  StartupGetUserActivationTasks,
  StartupGetInvestorLists,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { joinClassNames, toDashCase } from '/src/util/formatting/strings';
import { Task } from '/../libs/shared-types/src/types/model/Task';
import { StartupActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/StartupActivationTasks';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { completeActivationTask } from '/src/services/CompleteActivationTask';
import TabsHeader from '/src/components/tabs/TabsHeader';
import InvestorDatabaseInvestors from './InvestorDatabaseInvestors';
import InvestorDatabaseLists from './InvestorDatabaseLists';
import { InvestorList } from '../../../types/model/InvestorList';
import InvestorDatabaseImportedInvestors from './InvestorDatabaseImportedInvestors';
import { ComparableInvestorFragmentView } from '../../../types/view/InvestorFragmentView';
import useGetInvestorDatabase from '../../../hooks/useGetInvestorDatabase';
import useGetImportedInvestor, {
  IMPORTED_INVESTOR_KEY,
} from '../../../hooks/useGetImportedInvestor';
import { useQueryClient } from '@tanstack/react-query';
import { SortOrder } from '/../libs/shared-types/src/constants/SortOrder';
import useUrlParams from '/src/hooks/useUrlParams';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';

export const defaultSortKeyInvestorDbInvestors =
  'fitScore' as keyof ComparableInvestorDiscoveryView;
export const defaultSortKeyImportedInvestors =
  'fitLabel' as keyof ComparableInvestorFragmentView;

function InvestorDatabaseRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenActivationTaskModal, setIsOpenActivationTaskModal] =
    useState(false);
  const [hasCheckedInvestorDbInfo, setHasCheckedInvestorDbInfo] =
    useState(false);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);

  const [investorListsData, setInvestorListsData] = useState<InvestorList[]>(
    [],
  );
  const { pathname: currentPathname } = useLocation();

  const { subscriptionTier } = useContext(AccountMetadataContext);

  const { getParam } = useUrlParams();

  const isUserInDatabaseTable = [
    STARTUP_INVESTOR_LEADS_ROUTE,
    `${STARTUP_INVESTOR_LEADS_ROUTE}/investor-database`,
  ].some((pathname) => pathname === currentPathname);

  const isUserInImportedInvestors =
    currentPathname === `${STARTUP_INVESTOR_LEADS_ROUTE}/imported-investors`;

  const queryClient = useQueryClient();
  const {
    data: investorDbData,
    isLoading: isLoadinginvestorDbData,
    isFetching,
  } = useGetInvestorDatabase({
    page: isUserInImportedInvestors ? 1 : getParam('page') || 1,
    perPage: isUserInImportedInvestors ? 20 : getParam('perPage') || 20,
    filter: isUserInImportedInvestors ? null : getParam('filter'),
    sortKey: isUserInImportedInvestors
      ? 'fitScore'
      : getParam('sortKey') || 'fitScore',
    sortOrder: isUserInImportedInvestors
      ? String(SortOrder.Desc)
      : getParam('sortOrder') || String(SortOrder.Desc),
    fieldFilters: isUserInImportedInvestors ? null : getParam('fieldFilters'),
    subscriptionTier,
  });

  const { data: importedInvestorData, isFetching: isFetchingImportedInvestor } =
    useGetImportedInvestor({
      page: isUserInDatabaseTable ? 1 : getParam('page') || 1,
      perPage: isUserInDatabaseTable ? 20 : getParam('perPage') || 20,
      filter: isUserInDatabaseTable ? null : getParam('filter'),
      sortKey: isUserInDatabaseTable
        ? 'fitLabel'
        : getParam('sortKey') || 'fitLabel',
      sortOrder: isUserInDatabaseTable
        ? String(SortOrder.Desc)
        : getParam('sortOrder') || String(SortOrder.Desc),
      subscriptionTier,
    });

  function invalidateImportedInvestor() {
    queryClient.invalidateQueries({ queryKey: [IMPORTED_INVESTOR_KEY] });
  }

  const tabs = [
    { name: 'Investor Database', stat: investorDbData?.totalCount ?? 0 },
    {
      name: 'Target Lists',
      stat: investorListsData?.length ?? 0,
    },
    {
      name: 'Imported Investors',
      stat: importedInvestorData?.totalCount ?? 0,
    },
  ];

  async function fetchInvestorLists() {
    if (
      !hasFeatureAccess(StartupPaidFeatures.InvestorLists, subscriptionTier)
    ) {
      return;
    }

    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorListsData(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function fetchActivationTasks() {
    if (
      localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      )
    ) {
      return;
    }

    try {
      const taskList = await API.get<Task[]>(
        StartupGetUserActivationTasks.buildEndpoint(),
      );

      if (
        taskList.some(
          (x) =>
            x._id === StartupActivationTasks.ViewInvestorDb &&
            x.completedOn.getTime() === MIN_DATE.getTime(),
        )
      ) {
        setIsOpenActivationTaskModal(true);
      }
    } catch (error) {
      Logger.error(error);
    }
  }

  async function handleCompleteActivationTask() {
    if (!hasCheckedInvestorDbInfo) {
      return;
    }
    try {
      await completeActivationTask(StartupActivationTasks.ViewInvestorDb);
    } catch {
    } finally {
      setIsOpenActivationTaskModal(!hasCheckedInvestorDbInfo);
    }
  }

  async function loadInitialInvestorDiscovery() {
    try {
      setIsLoading(true);
      await Promise.all([fetchActivationTasks(), fetchInvestorLists()]);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadInitialInvestorDiscovery();
  }, [subscriptionTier]);

  // Event listener for handling Activation Tour
  useEffect(() => {
    if (
      isLoading ||
      isLoadinginvestorDbData ||
      !investorDbData ||
      !localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      )
    ) {
      // Do not resume the tour yet
      return;
    }

    // Add mock data
    investorDbData.results.splice(0, 0, EXAMPLE_INVESTOR_DISCOVERY_VIEW);
    investorDbData.totalCount = investorDbData.results.length;

    // Resume joyride tour
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
  }, [investorDbData, isLoading, isLoadinginvestorDbData]);

  return (
    <main>
      <header>
        <h2 className="page-title">Investor Leads</h2>
        <Alert
          alertType="Info"
          canDismiss
          color="blue"
          content={
            <p>
              Leverage our curated Investor Database to find best-fit investor
              leads for your company, group them into Target Lists to plan your
              outreach, and Import all your other investor leads to manage them
              in one place.
            </p>
          }
          isShown
          onClose={() => false}
        />

        <TabsHeader tabs={tabs} />
      </header>

      <section className="my-4">
        {(isLoading || isLoadinginvestorDbData) && (
          <PageLoadingSpinner message="Finding great investors for you... 💰" />
        )}

        {!(isLoading || isLoadinginvestorDbData) && (
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <InvestorDatabaseInvestors
                      apiResponse={investorDbData}
                      isSearchLoading={isFetching}
                      investorLists={investorListsData ?? []}
                      fetchInvestorLists={fetchInvestorLists}
                    />
                  }
                />
              ))}
              <Route
                path={toDashCase(tabs[1].name)}
                element={
                  <InvestorDatabaseLists
                    apiResponse={investorListsData}
                    fetchInvestorLists={fetchInvestorLists}
                  />
                }
              />
              <Route
                path={toDashCase(tabs[2].name)}
                element={
                  <InvestorDatabaseImportedInvestors
                    apiResponse={importedInvestorData}
                    isSearchLoading={isFetchingImportedInvestor}
                    investorLists={investorListsData ?? []}
                    fetchInvestorLists={fetchInvestorLists}
                    invalidate={invalidateImportedInvestor}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        )}
      </section>

      <ModalWrapper
        open={isOpenActivationTaskModal}
        onClose={() => handleCompleteActivationTask}
      >
        <SimpleDialog
          onCancel={undefined}
          onPrimaryAction={() => handleCompleteActivationTask()}
          iconType="InformationCircle"
          title="We curated a list of active investors to kick-start your research!"
          text={
            <div className="my-4 space-y-10">
              <p className="text-base font-medium text-gray-700">
                Remember, Flowlie will not automatically submit or display your
                information to any investors.
                <br />
                <br />
                To share your deck and one-pager with investors you must send
                them your FlowLink.
              </p>
              <label
                className="mt-10 flex cursor-pointer flex-row"
                htmlFor="hasSeenInvestorDbInfo"
              >
                <input
                  id="hasSeenInvestorDbInfo"
                  name="hasSeenInvestorDbInfo"
                  type="checkbox"
                  className={joinClassNames(
                    'input-checkbox h-5 w-5',
                    !hasCheckedInvestorDbInfo
                      ? 'ring-2 ring-red-600 ring-offset-2'
                      : '',
                  )}
                  checked={hasCheckedInvestorDbInfo}
                  onChange={() =>
                    setHasCheckedInvestorDbInfo(!hasCheckedInvestorDbInfo)
                  }
                />
                <span className="ml-2 flex flex-row items-center">
                  I understand that my deck, one-pager, and round information
                  will not be shared with anyone unless I send them my FlowLink
                  and that this database is for my investor research only.
                </span>
              </label>
            </div>
          }
          primaryAction="Access the Database"
          color="blue"
        />
      </ModalWrapper>
    </main>
  );
}

export default InvestorDatabaseRoute;
