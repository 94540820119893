import React from 'react';
import { ConnectionNoteView } from '../../types/view/ConnectionNoteView';
import { capitalize } from '/src/util/formatting/strings';
import { cn } from '/src/util/cn';
import Logger from '/src/services/logger';
import { extractPlainTextFromContent } from '../TextEditor/TextEditor';

function groupNotesByDate(notes: ConnectionNoteView[]) {
  const groupedNotes: { [key: string]: ConnectionNoteView[] } = {};
  const now = new Date();
  const past30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const currentYear = now.getFullYear();

  notes.forEach((note) => {
    const updatedOn = new Date(note.updatedOn);
    const isToday = updatedOn.toDateString() === now.toDateString();

    if (isToday) {
      if (!groupedNotes['Today']) {
        groupedNotes['Today'] = [];
      }
      groupedNotes['Today'].push(note);
    } else if (updatedOn >= past30Days && updatedOn <= now) {
      if (!groupedNotes['Past 30 days']) {
        groupedNotes['Past 30 days'] = [];
      }
      groupedNotes['Past 30 days'].push(note);
    } else if (updatedOn.getFullYear() === currentYear) {
      const monthName = updatedOn.toLocaleString('en-US', { month: 'long' });
      if (!groupedNotes[monthName]) {
        groupedNotes[monthName] = [];
      }
      groupedNotes[monthName].push(note);
    } else {
      const year = updatedOn.getFullYear().toString();
      if (!groupedNotes[year]) {
        groupedNotes[year] = [];
      }
      groupedNotes[year].push(note);
    }
  });

  return groupedNotes;
}

interface ConnectionNoteListProps {
  notes: ConnectionNoteView[];
  selectedNote: ConnectionNoteView | null;
  onSelect: (selectedNote: ConnectionNoteView) => void;
}

export function ConnectionNoteList({
  notes,
  onSelect,
  selectedNote,
}: ConnectionNoteListProps) {
  const groupedNotes = groupNotesByDate(notes);

  const sortedGroups = Object.keys(groupedNotes).sort((a, b) => {
    // sort groups by date
    const dateA = new Date(groupedNotes[a][0].updatedOn).getTime();
    const dateB = new Date(groupedNotes[b][0].updatedOn).getTime();
    // Descending order
    return dateB - dateA;
  });

  return (
    <nav aria-label="List of notes" className="h-full overflow-y-auto">
      {sortedGroups.map((group, index) => (
        <div key={`${group}-${index}`} className="relative">
          <div className="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-100 px-3 py-1.5 text-sm/6 font-semibold text-gray-900">
            <h3>{group}</h3>
          </div>
          <ul className="divide-y divide-gray-100">
            {groupedNotes[group].map((note) => (
              <li
                key={`${group}-${note.title}-${note._id}`}
                className={cn(
                  'flex cursor-pointer gap-x-4 px-3 py-5',
                  selectedNote?._id === note._id ? 'bg-blue-100' : '',
                )}
                onClick={() => onSelect(note)}
                aria-hidden
              >
                <article className="min-w-0">
                  <header className="overflow-hidden text-ellipsis whitespace-nowrap text-sm/6 font-semibold text-gray-900">
                    {capitalize(note.title)}
                  </header>
                  <div className="flex space-x-4">
                    <p className="mt-1 text-xs/5 text-gray-500">
                      {note.updatedOn.toLocaleDateString()}
                    </p>
                    <p className="mt-1 truncate text-ellipsis text-xs/5 text-gray-500">
                      {extractPlainTextFromContent(note.content)}
                    </p>
                  </div>
                </article>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
}

export default ConnectionNoteList;
