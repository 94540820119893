import React from 'react';
import { StartupAccessManagerViewSessionView } from '../../../../../types/view/StartupAccessManagerView';
import { ViewerTypes } from '../../../../../../../libs/shared-types/src/constants/ViewerTypes';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import {
  ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS,
  AccessManagerViewSessionPropsActionProps,
} from '../AccessManagerViewSessionsMapper';

export interface ViewSessionsLastViewedOnCellProps {
  viewSession: StartupAccessManagerViewSessionView;
  onAction: ({
    type,
    payload,
  }: AccessManagerViewSessionPropsActionProps) => void;
}

function AccessManagerViewSessionsActionCell({
  viewSession,
  onAction,
}: ViewSessionsLastViewedOnCellProps) {
  return (
    <>
      {viewSession.viewerType === ViewerTypes.Visitor &&
        !viewSession.connectionId && (
          <button
            title="Import Investor"
            className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={() =>
              onAction({
                type: ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS.IMPORT_INVESTOR,
                payload: {
                  viewSession,
                },
              })
            }
          >
            <UserPlusIcon className="h-5 w-5" />
          </button>
        )}
    </>
  );
}

export default AccessManagerViewSessionsActionCell;
