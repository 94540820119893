import React, { createRef, useState } from 'react';
import * as yup from 'yup';
import DebugRender from '/src/components/utility/DebugRender';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import { Field, FieldArray, Form, Formik, FormikValues } from 'formik';
import { BOARD_STAGE_NAME_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormikInput from '/src/components/inputs/FormikInput';
import NumericInput from '/src/components/inputs/NumericInput';
import AnimateListItems from '/src/components/animations/AnimateListItems';
import Alert from '/src/components/notifications/Alert';
import { joinClassNames } from '/src/util/formatting/strings';
import { DAY_MS } from '/../libs/shared-types/src/constants/time';
import { Stage } from '/../libs/shared-types/src/types/model/Stage';
import { StageView } from '/../libs/shared-types/src/types/view/StageView';
import { getColorByIndex } from '/src/util/colorLookup';
import Logger from '/src/services/logger';
import {
  BOARD_STAGES_LENGTH_MIN,
  BOARD_STAGES_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/ObjectArrayLengthRanges';

// YUP EXTENSION METHODS
// - Not actually needed because I created a .test() validation rule
//  but we should decide how to implement universal custom validation methods

// declare module 'yup' {
//   interface ArraySchema<T> {
//     unique(
//       message: string,
//       mapper?: (value: T, index?: number, list?: T[]) => T[]
//     ): ArraySchema<T>;
//   }
// }

yup.addMethod(
  yup.array,
  'unique',
  function (message, mapper = (val: unknown) => val) {
    return this.test(
      'unique',
      message,
      (list = []) => list.length === new Set(list.map(mapper)).size,
    );
  },
);

enum StageFieldNames {
  Stages = 'stages',
  Name = 'name',
  Duration = 'duration',
}

function getNewStage(currentStages: StageView[]): StageView {
  let name = 'New Stage';
  // Find all stages where the name starts with 'New Stage'
  const newStages = currentStages
    .map((x) => x.name)
    .filter((x) => x.startsWith(name));

  if (newStages.length > 0) {
    name = `${name} ${newStages.length}`;
    if (newStages.findIndex((x) => x === name) !== -1) {
      name = `New Stage ${newStages.length + 1}`;
    }
  }

  return {
    _id: `RAND_${Date.now()}`,
    name,
    isSystemStage: false,
  };
}

const validationSchema = yup.object().shape({
  [StageFieldNames.Stages]: yup
    .array()
    .of(
      yup.object().shape({
        // these inner constraints take precedence
        [StageFieldNames.Name]: yup
          .string()
          .max(
            BOARD_STAGE_NAME_LENGTH_MAX,
            `Must be at most ${BOARD_STAGE_NAME_LENGTH_MAX} characters`,
          )
          .required('Required'),
        [StageFieldNames.Duration]: yup
          .number()
          .nullable()
          .min(1, `At least ${1} day`)
          .max(31, `At most ${31} days`),
      }),
    )
    .test(
      'uniqueName',
      'Stage names must be unique',
      (stages = []): boolean => {
        const stageNamesSet = new Set(
          stages
            .map((x) => x[StageFieldNames.Name])
            .map((x) => x?.toUpperCase().trim()),
        );
        return stages.length === stageNamesSet.size;
      },
    )
    .required('Must have a stage')
    .min(
      BOARD_STAGES_LENGTH_MIN,
      `At least ${BOARD_STAGES_LENGTH_MIN} stage is required`,
    )
    .max(
      BOARD_STAGES_LENGTH_MAX,
      `At most ${BOARD_STAGES_LENGTH_MAX} stages are allowed`,
    ),
});

interface EditStagesDialogProps {
  boardStages: StageView[];
  header: string;
  onCancel: () => void;
  onSave: (newStages: Stage[]) => Promise<void>;
  secondaryHeader: string;
  itemsPerStageCountMap: Map<string, number>;
}

function EditStagesDialog({
  boardStages,
  header,
  onCancel,
  onSave,
  secondaryHeader,
  itemsPerStageCountMap,
}: EditStagesDialogProps): JSX.Element {
  const [editedStages, setEditedStages] = useState({
    [StageFieldNames.Stages]: boardStages.map((stage) => ({
      ...stage,
      duration: stage.duration ? stage.duration / DAY_MS : undefined,
    })),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const toggleAlert = () => setErrorMessage('');

  async function saveStages(data: StageView[]) {
    const cleanData: Partial<Stage>[] = data.map((stage) => ({
      _id: stage._id.includes('RAND') ? undefined : stage._id,
      name: stage.name.trim(),
      duration: !stage.duration ? undefined : Number(stage.duration) * DAY_MS,
    }));
    try {
      await onSave(cleanData as Stage[]);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  function isMoveUpDisabled(values: FormikValues, index: number): boolean {
    const inputStages: StageView[] = values[StageFieldNames.Stages];
    const currentInput = inputStages[index];

    if (index === 0 || currentInput.isSystemStage) {
      return true;
    }

    // If the stage at index before is a system stage, we can't move up
    const previousStage = inputStages[index - 1];
    return previousStage.isSystemStage ?? false;
  }

  function isMoveDownDisabled(values: FormikValues, index: number): boolean {
    const inputStages: StageView[] = values[StageFieldNames.Stages];
    const currentInput = inputStages[index];

    if (index === inputStages.length - 1 || currentInput.isSystemStage) {
      return true;
    }

    // If the stage at index after is a system stage, we can't move down
    const nextStage = inputStages[index + 1];
    return nextStage.isSystemStage ?? false;
  }

  // Disable the delete button if:
  // - There is only 1 stage defined
  // - OR The stage contains at least a deal in it
  //    -> No matter if that stage is being renamed
  function isDeleteDisabled(values: FormikValues, index: number): boolean {
    // For convenience, let's grab the inputted stages
    const inputStages: StageView[] = values[StageFieldNames.Stages];
    const currentInput = inputStages[index];

    if (currentInput.isSystemStage) {
      return true;
    }

    const isNewInput = currentInput._id.startsWith('RAND');
    if (isNewInput) {
      // New Stages should always be deletable
      return false;
    }

    const isOnlyOneStageDefined = inputStages.length <= 1;
    const isStageEmpty =
      itemsPerStageCountMap.has(currentInput._id) &&
      itemsPerStageCountMap.get(currentInput._id) === 0;

    return isOnlyOneStageDefined || !isStageEmpty;
  }

  return (
    <div className="bg-white p-4 sm:w-screen sm:max-w-4xl sm:p-7">
      <Formik
        initialValues={editedStages}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await saveStages(values[StageFieldNames.Stages]);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 flex items-center justify-between">
              <h3 className="max-w-[39rem] text-lg font-medium leading-6 text-gray-900">
                {header}
                <p className="text-sm font-normal text-gray-500">
                  {secondaryHeader}
                </p>
              </h3>

              <div>
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green ml-3"
                  disabled={!isValid || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <FieldArray name={StageFieldNames.Stages}>
              {({ remove, push, insert }) => (
                <div>
                  <ol className="space-y-3">
                    <AnimateListItems animateX={false}>
                      {values[StageFieldNames.Stages] &&
                        values[StageFieldNames.Stages].map((stage, index) => (
                          <li
                            className="flex rounded-md shadow-sm"
                            id={stage._id}
                            key={stage._id}
                            ref={createRef()}
                          >
                            <div
                              className={joinClassNames(
                                // eslint-disable-next-line no-nested-ternary
                                stage.bgColor
                                  ? stage.bgColor + ' ' + stage.textColor
                                  : getColorByIndex(
                                      index,
                                      values[StageFieldNames.Stages].length,
                                    ).allColors,
                                'flex w-8 shrink-0 items-center justify-center rounded-l-md',
                              )}
                            >
                              {/* <EllipsisVerticalIcon
                              className="h-5 w-5 -mr-1.5"
                              aria-hidden="true"
                            />
                            <EllipsisVerticalIcon
                              className="h-5 w-5 -ml-2"
                              aria-hidden="true"
                            /> */}
                              <span className="rounded-full bg-gray-100 px-1.5 text-xs font-medium text-gray-800">
                                {itemsPerStageCountMap.get(stage._id)}
                              </span>
                            </div>
                            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                              <div className="mx-4 flex space-x-4 text-sm">
                                <Field
                                  component={FormikInput}
                                  label="Name"
                                  name={`${StageFieldNames.Stages}[${index}].${StageFieldNames.Name}`}
                                  type="text"
                                  disabled={stage.isSystemStage}
                                />

                                {!stage.isDurationDisabled && (
                                  <NumericInput
                                    label="Target Duration"
                                    name={`${StageFieldNames.Stages}[${index}].${StageFieldNames.Duration}`}
                                    step={1}
                                    suffix={` day${getPluralSuffix(
                                      values[StageFieldNames.Stages][index][
                                        StageFieldNames.Duration
                                      ] ?? 0,
                                    )}`}
                                  />
                                )}
                              </div>

                              <div className="mr-2 flex flex-row items-center space-x-2 pt-1.5">
                                <div className="flex flex-col space-y-1">
                                  <button
                                    type="button"
                                    className={joinClassNames(
                                      stage.isSystemStage ? 'hidden' : '',
                                      'inline-flex h-6 w-6 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                                    )}
                                    disabled={isMoveUpDisabled(values, index)}
                                    onClick={() => {
                                      const element = values[
                                        StageFieldNames.Stages
                                      ].splice(index, 1)[0];

                                      values[StageFieldNames.Stages].splice(
                                        index - 1,
                                        0,
                                        element,
                                      );

                                      setEditedStages({
                                        [StageFieldNames.Stages]:
                                          values[StageFieldNames.Stages],
                                      });
                                    }}
                                  >
                                    <span className="sr-only">
                                      Move stage up
                                    </span>
                                    <ArrowUpCircleIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                  <button
                                    type="button"
                                    className={joinClassNames(
                                      stage.isSystemStage ? 'hidden' : '',
                                      'inline-flex h-6 w-6 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
                                    )}
                                    disabled={isMoveDownDisabled(values, index)}
                                    onClick={() => {
                                      const element = values[
                                        StageFieldNames.Stages
                                      ].splice(index, 1)[0];

                                      values[StageFieldNames.Stages].splice(
                                        index + 1,
                                        0,
                                        element,
                                      );

                                      setEditedStages({
                                        [StageFieldNames.Stages]:
                                          values[StageFieldNames.Stages],
                                      });
                                    }}
                                  >
                                    <span className="sr-only">
                                      Move stage down
                                    </span>
                                    <ArrowDownCircleIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                                <button
                                  type="button"
                                  className="inline-flex h-6 w-6 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  disabled={isDeleteDisabled(values, index)}
                                  onClick={() => remove(index)}
                                  title={
                                    isDeleteDisabled(values, index)
                                      ? 'To delete this stage you must first remove all items in it'
                                      : ''
                                  }
                                >
                                  <span className="sr-only">Remove stage</span>
                                  <TrashIcon
                                    className={joinClassNames(
                                      'h-5 w-5',
                                      isDeleteDisabled(values, index)
                                        ? 'text-gray-300'
                                        : 'text-gray-400',
                                    )}
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                    </AnimateListItems>
                  </ol>

                  {
                    // Display error for outer validation
                    typeof errors[StageFieldNames.Stages] === 'string' && (
                      <div className="mt-4">
                        <Alert
                          alertType="Warning"
                          canDismiss={false}
                          color="yellow"
                          content={
                            <p>{errors[StageFieldNames.Stages]?.toString()}</p>
                          }
                          isShown
                          onClose={() => false}
                        />
                      </div>
                    )
                  }
                  <div className="my-4">
                    <Alert
                      color="red"
                      alertType="Error"
                      content={errorMessage}
                      isShown={errorMessage !== ''}
                      onClose={toggleAlert}
                    />
                  </div>

                  <button
                    type="button"
                    className="button mt-8"
                    disabled={
                      values[StageFieldNames.Stages].length ===
                      BOARD_STAGES_LENGTH_MAX
                    }
                    onClick={() => {
                      if (boardStages.some((x) => x.isSystemStage)) {
                        insert(1, getNewStage(values[StageFieldNames.Stages]));
                        return;
                      }
                      push(getNewStage(values[StageFieldNames.Stages]));
                    }}
                  >
                    Add Stage
                  </button>
                </div>
              )}
            </FieldArray>

            <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Stages Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre>
                    {JSON.stringify(values[StageFieldNames.Stages], null, 2)}
                  </pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Stages Errors:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre>
                    {JSON.stringify(errors[StageFieldNames.Stages], null, 2)}
                  </pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Stages Touched:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre>
                    {JSON.stringify(touched[StageFieldNames.Stages], null, 2)}
                  </pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditStagesDialog;
