import {
  SharedGetInvestorById,
  StartupAddInvestorsToLists,
  StartupAddInvestorToLists,
  StartupAddOrUpdateIntroPath,
  StartupDeleteIntroPathById,
  StartupDeleteInvestorFragment,
  StartupGetCurrentRound,
  StartupGetInvestorLists,
  StartupInvestorPipelineAddInvestors,
  StartupConnectionNotes,
  StartupRelationshipUpdate,
  StartupRemoveInvestorFromInvestorList,
  StartupInvestorMeetingById,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '../middleware/API';
import RoundView from '../types/view/RoundView';
import Logger from './logger';
import { InvestorIdentifier } from '../types/view/APIResponse';
import { InvestorDetailView } from '../types/view/InvestorDetailView';
import { InvestorDataType } from '../../../libs/shared-types/src/constants/InvestorDataType';
import { InvestorList } from '../types/model/InvestorList';
import { InvestorFragment } from '../types/model/InvestorFragment';
import { StartupRelationship } from '../types/model/StartupRelationship';
import { ConnectionDataType } from '../../../libs/shared-types/src/constants/ConnectionDataType';
import { AddOrUpdateConnectionNoteResponse } from '../../../libs/shared-types/src/types/view/ConnectionNoteView';
import { IntroPath } from '../types/model/IntroPath';
import { SerializedEditorState } from 'lexical';

export const fetchActiveRound = async () => {
  try {
    const round: RoundView = await API.get(
      StartupGetCurrentRound.buildEndpoint(),
    );
    if (round === null) {
      return;
    }
    return round;
  } catch (error: any) {
    Logger.error(error);
  }
};

export const addInvestorsToPipeline = async (
  investors: InvestorIdentifier[],
) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.put(StartupInvestorPipelineAddInvestors.buildEndpoint(), {
      investors,
    });
    isSuccess = true;
  } catch (error: any) {
    errorMessage = error?.message || '';
  } finally {
    return { isSuccess, error: errorMessage };
  }
};

export const getInvestorData = async ({ id }: { id: string }) => {
  let isSuccess = false;
  let data = undefined;
  try {
    const investorData = await API.get<InvestorDetailView>(
      SharedGetInvestorById.buildEndpoint(id),
    );
    isSuccess = true;
    data = investorData;
  } catch (error: any) {
  } finally {
    return { isSuccess, data };
  }
};

export const addInvestorToTargetList = async (
  investorId: string,
  investorDataType: InvestorDataType,
  investorListIds: string[],
) => {
  let isSuccess = false;
  try {
    await API.put(StartupAddInvestorToLists.buildEndpoint(), {
      investorId: investorId,
      investorDataType: investorDataType,
      investorListIds: investorListIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const addManyInvestorToTargetList = async ({
  investors,
  investorListIds,
}: {
  investors: InvestorIdentifier[];
  investorListIds: string[];
}) => {
  let isSuccess = false;

  try {
    await API.put(StartupAddInvestorsToLists.buildEndpoint(), {
      investors,
      investorListsIds: investorListIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const removeInvestorListFromTargetList = async ({
  listId,
  investorIds,
}: {
  listId: string;
  investorIds: string[];
}) => {
  let isSuccess = false;

  try {
    await API.post(StartupRemoveInvestorFromInvestorList.buildEndpoint(), {
      listId,
      investorIds,
    });
    isSuccess = true;
  } catch (error) {
    Logger.error(error);
  } finally {
    return { isSuccess };
  }
};

export const fetchInvestorLists = async () => {
  let isSuccess = false;
  let data = undefined;
  try {
    const investorLists = await API.get<InvestorList[]>(
      StartupGetInvestorLists.buildEndpoint(),
    );
    isSuccess = true;
    data = investorLists;
  } catch (error: any) {
    Logger.error(error.message);
  } finally {
    return { isSuccess, data };
  }
};

export const deleteFragmentInvestor = async (investorId: string) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.delete<InvestorFragment>(
      `${StartupDeleteInvestorFragment.buildEndpoint(investorId)}`,
    );
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    errorMessage = error.message;
  } finally {
    return { isSuccess, error: errorMessage };
  }
};

export async function updateStartupRelationship(
  investorId: string,
  investorDataType: ConnectionDataType,
  update: Partial<StartupRelationship>,
) {
  let isSuccess = false;
  try {
    await API.put(StartupRelationshipUpdate.buildEndpoint(), {
      investorId,
      investorDataType,
      update,
    });
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    throw error;
  } finally {
    return { isSuccess };
  }
}

export const deleteIntroPathById = async (introPathId: string) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.delete<InvestorFragment>(
      `${StartupDeleteIntroPathById.buildEndpoint(introPathId)}`,
    );
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    errorMessage = error.message;
  } finally {
    return { isSuccess, error: errorMessage };
  }
};

export const addOrUpdateIntroPath = async (introPath: Partial<IntroPath>) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.post<IntroPath>(
      StartupAddOrUpdateIntroPath.buildEndpoint(),
      introPath,
    );
    isSuccess = true;
  } catch (error: unknown) {
    if (error instanceof Error) {
      Logger.error(error.message);
      errorMessage = error.message;
    } else {
      Logger.error(error);
      errorMessage = String(error);
    }
  } finally {
    return { isSuccess, error: errorMessage };
  }
};

export interface AddOrUpdateConnectionNoteProps {
  _id?: string;
  title: string;
  content: {
    root: SerializedEditorState;
  };
  connectionId: string;
  connectionDataType: ConnectionDataType;
}

export const AddOrUpdateConnectionNote = async ({
  _id,
  content,
  title,
  connectionId,
  connectionDataType,
}: AddOrUpdateConnectionNoteProps) => {
  let isSuccess = false;
  let errorMessage = '';
  let responseData;
  try {
    responseData = await API.post<AddOrUpdateConnectionNoteResponse['payload']>(
      StartupConnectionNotes.buildEndpoint(),
      {
        content,
        title,
        connectionId,
        connectionDataType,
        _id,
      },
    );
    isSuccess = true;
  } catch (error: unknown) {
    if (error instanceof Error) {
      Logger.error(error.message);
      errorMessage = error.message;
    } else {
      Logger.error(error);
      errorMessage = String(error);
    }
  } finally {
    return { isSuccess, error: errorMessage, data: responseData };
  }
};

export const deleteConnectionNoteById = async (connectionNoteId: string[]) => {
  let isSuccess = false;
  let errorMessage = '';
  let responseData;
  try {
    responseData = await API.delete(
      StartupConnectionNotes.buildEndpoint(undefined, {
        ids: connectionNoteId,
      }),
    );
    isSuccess = true;
  } catch (error: unknown) {
    if (error instanceof Error) {
      Logger.error(error.message);
      errorMessage = error.message;
    } else {
      Logger.error(error);
      errorMessage = String(error);
    }
  } finally {
    return { isSuccess, error: errorMessage, data: responseData };
  }
};

export const deleteInvestorMeetingById = async (
  investorMeetingById: string,
) => {
  let isSuccess = false;
  let errorMessage = '';
  try {
    await API.delete<InvestorFragment>(
      `${StartupInvestorMeetingById.buildEndpoint(investorMeetingById)}`,
    );
    isSuccess = true;
  } catch (error: any) {
    Logger.error(error.message);
    errorMessage = error.message;
  } finally {
    return { isSuccess, error: errorMessage };
  }
};
