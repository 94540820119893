import React, { useEffect, useState } from 'react';
import {
  ComparableStartupAccessManagerViewSessionView,
  StartupAccessManagerViewSessionPaginatedCollectionResponse,
} from '../../../../types/view/StartupAccessManagerView';
import { StartupGetDealViewSessions } from '../../../../../../libs/shared-types/src/constants/ApiRoutes';
import { removeUndefinedOrNullProperties } from '/src/util/removeUndefinedOrNullProperties';
import useUrlParams from '/src/hooks/useUrlParams';
import { SortOrder } from '/src/interfaces/Sortable';
import Logger from '/src/services/logger';
import API from '/src/middleware/API';

export const defaultSortKeyViewSessions =
  'lastViewedOn' as keyof ComparableStartupAccessManagerViewSessionView;

export interface useGetAccessManagerViewSessionsProps {
  onSuccess?: (
    response: StartupAccessManagerViewSessionPaginatedCollectionResponse,
  ) => void;
  isEnabled?: boolean;
}

const useGetAccessManagerViewSessions = ({
  onSuccess,
  isEnabled = true,
}: useGetAccessManagerViewSessionsProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [response, setResponse] =
    useState<StartupAccessManagerViewSessionPaginatedCollectionResponse>();
  const [refetch, setRefetch] = useState(false);

  const { getParam } = useUrlParams();
  const page = getParam('page');
  const perPage = getParam('perPage');
  const sortKey = getParam('sortKey');
  const sortOrder = getParam('sortOrder');
  const filter = getParam('filter');
  const fieldFiltersParam = getParam('fieldFilters');
  const fieldFilters = fieldFiltersParam && JSON.parse(fieldFiltersParam);

  const handleRefetch = () => {
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    async function fetchViewSessionAccess(args: any): Promise<void> {
      try {
        setIsLoading(true);
        const requestParams = removeUndefinedOrNullProperties(args);
        const url = StartupGetDealViewSessions.buildEndpoint(
          undefined,
          requestParams,
        );

        const data =
          await API.get<StartupAccessManagerViewSessionPaginatedCollectionResponse>(
            url,
          );

        setResponse(data);
        if (onSuccess) {
          onSuccess(data);
        }
      } catch (error: any) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
        setIsFirstLoading(false);
      }
    }

    fetchViewSessionAccess({
      page,
      perPage,
      sortKey: sortKey ?? defaultSortKeyViewSessions,
      sortOrder: sortOrder ?? SortOrder.Desc,
      filter,
      fieldFilters,
    });
  }, [
    isEnabled,
    page,
    perPage,
    sortKey,
    sortOrder,
    filter,
    fieldFiltersParam,
    refetch,
  ]);

  return {
    isLoading,
    isFirstLoading,
    data: response?.results,
    totalCount: response?.totalCount ?? 0,
    response,
    refetch: handleRefetch,
    params: {
      page,
      perPage,
      sortKey,
      sortOrder,
      filter,
      fieldFilters,
    },
  };
};

export default useGetAccessManagerViewSessions;
