/* eslint-disable @typescript-eslint/no-var-requires */
// Sentry initialization should be imported first!
import './services/sentry/instrument';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import { Account } from './contexts/Accounts';
import { SharedState } from './contexts/SharedStateContext';
import routes from './routes/index';
import ScrollToTop from './components/utility/ScrollToTop';
import { createRoot } from 'react-dom/client';

import './css/index.css';
import './css/custom.css';
import './css/textEditorStyles.css';

import { AuthenticationContextProvider } from './contexts/AuthenticationContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

require('dotenv').config();

if (process.env.REACT_APP_ENV === EnvironmentTypes.Production) {
  const tagManagerArgs = {
    gtmId: 'GTM-KLXXP7W',
  };

  TagManager.initialize(tagManagerArgs);
}

if (process.env.REACT_APP_ENV === EnvironmentTypes.Development) {
  const tagManagerArgs = {
    gtmId: 'GTM-5SR27D7',
  };

  TagManager.initialize(tagManagerArgs);
}

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <AuthenticationContextProvider>
      <SharedState>
        <Account>
          <BrowserRouter>
            <ScrollToTop />
            {routes}
          </BrowserRouter>
        </Account>
      </SharedState>
    </AuthenticationContextProvider>
  </QueryClientProvider>,
  // </React.StrictMode>
);
