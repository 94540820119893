import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import AccessManagerInvestors from './AccessManagerInvestors/AccessManagerInvestors';
import AccessManagerViewSessions from './AccessManagerViewSessions/AccessManagerViewSessions';
import { PublicProfilePrivacySetting } from '/../libs/shared-types/src/constants/PublicProfilePrivacySetting';
import Alert from '/src/components/notifications/Alert';
import {
  NOT_FOUND_ROUTE,
  STARTUP_ACCESS_MANAGER_ROUTE,
  STARTUP_FLOWLINK_MANAGER_ROUTE,
} from '/src/constants/Routes';
import { getPrivacySetting } from '/src/services/GetPrivacySetting';
import useGetAccessManagerInvestors from './AccessManagerInvestors/useGetAccessManagerInvestors';
import useGetViewSessionsAccess from './AccessManagerViewSessions/useGetAccessManagerViewSessions';
import useActiveTab from '/src/hooks/useActiveTab';

const TAB_ROUTES = {
  viewSessions: {
    name: 'View Sessions',
    path: 'view-sessions',
  },
  investorsEngaged: {
    name: 'Investors Engaged',
    path: 'investors-engaged',
  },
};

function AccessManagerRoute(): JSX.Element {
  const [flowlinkPrivacySetting, setFlowlinkPrivacySetting] =
    useState<PublicProfilePrivacySetting>();
  const activeTab = useActiveTab(
    [TAB_ROUTES.viewSessions.path, TAB_ROUTES.investorsEngaged.path],
    STARTUP_ACCESS_MANAGER_ROUTE,
  );

  const {
    isLoading: isLoadingViewSessions,
    isFirstLoading: isFirstLoadingViewSessions,
    response: viewSessionsResponse,
    refetch: refetchViewSessions,
  } = useGetViewSessionsAccess({
    isEnabled: activeTab === TAB_ROUTES.viewSessions.path,
  });

  const {
    isLoading: isLoadingInvestorsEngaged,
    isFirstLoading: isFirstLoadingInvestorsEngaged,
    response: investorsEngagedResponse,
  } = useGetAccessManagerInvestors({
    isEnabled:
      activeTab === TAB_ROUTES.investorsEngaged.path ||
      isFirstLoadingViewSessions,
  });

  const tabs = [
    {
      name: TAB_ROUTES.viewSessions.name,
      stat: viewSessionsResponse?.totalCount,
    },
    {
      name: TAB_ROUTES.investorsEngaged.name,
      stat: investorsEngagedResponse?.totalCount,
    },
  ];

  async function fetchAndSetPrivacySetting() {
    try {
      const privacy = await getPrivacySetting();
      setFlowlinkPrivacySetting(privacy);
    } catch {
      Logger.error('Error fetching privacy setting');
    }
  }

  useEffect(() => {
    fetchAndSetPrivacySetting();
  }, []);

  return (
    <main>
      <header>
        <h2 className="page-title">Track FlowLink Views</h2>
        <Alert
          alertType="Info"
          canDismiss={true}
          color="blue"
          content={
            <ul className="list-inside list-disc">
              <li>
                When you send someone your FlowLink and they view your one-pager
                they will show up here, so you can monitor their activity.
              </li>
              <li>
                Remember: Your deck, one-pager, and round information will not
                be visible to anyone unless you send them your{' '}
                <Link
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                  className="hyperlink underline"
                >
                  FlowLink
                </Link>
              </li>
              {flowlinkPrivacySetting ===
                PublicProfilePrivacySetting.Public && (
                <li>
                  Your privacy setting is set to Public, so anyone who clicks on
                  your FlowLink will be shown as an anonymous visitor. Manage
                  privacy settings in&nbsp;
                  <Link
                    to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                    className="hyperlink underline"
                  >
                    FlowLink manager.
                  </Link>
                </li>
              )}
            </ul>
          }
          isShown
          onClose={() => false}
        />
        <TabsHeader tabs={tabs} />
      </header>
      <section className="my-4">
        {
          <>
            <Routes>
              {['', TAB_ROUTES.viewSessions.path].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <AccessManagerViewSessions
                      apiResponse={viewSessionsResponse}
                      isLoading={isLoadingViewSessions}
                      isFirstLoading={isFirstLoadingViewSessions}
                      refetchData={refetchViewSessions}
                    />
                  }
                />
              ))}
              <Route
                path={TAB_ROUTES.investorsEngaged.path}
                element={
                  <AccessManagerInvestors
                    apiResponse={investorsEngagedResponse}
                    isLoading={isLoadingInvestorsEngaged}
                    isFirstLoading={isFirstLoadingInvestorsEngaged}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        }
      </section>
    </main>
  );
}

export default AccessManagerRoute;
