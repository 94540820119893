import React from 'react';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import {
  ComparableStartupAccessManagerView,
  StartupAccessManagerView,
} from '../../../../types/view/StartupAccessManagerView';
import { isRowBlurred } from '/src/components/table/BackendPaginatedTable';
import { useNavigate } from 'react-router-dom';
import { cn } from '/src/util/cn';
import { PaginatedRequestParams } from '../../../../types/view/APIResponse';
import { rowBlurClass } from '/src/util/styles';
import { AccessManagerInvestorsMapper } from './AccessManagerInvestorsMapper';
import { INVESTOR_DETAIL } from '/src/constants/Routes';

interface AccessManagerInvestorsTableInterface {
  investorsEngaged: StartupAccessManagerView[];
  pageCount: number;
  investorsEngagedCount: number;

  requestParams: Required<
    Omit<
      PaginatedRequestParams<ComparableStartupAccessManagerView>,
      'isReturnAllResults'
    >
  >;
  canAccessInvestors: boolean;
  blurRowsAfterIndex?: number;
}

function AccessManagerInvestorsTable({
  investorsEngaged,
  pageCount,
  investorsEngagedCount,
  requestParams,
  canAccessInvestors,
  blurRowsAfterIndex,
}: AccessManagerInvestorsTableInterface): JSX.Element {
  const navigate = useNavigate();

  const onRowClick = (rowKey: string, rowIndex: number) => {
    if (!isRowBlurred(requestParams.page, rowIndex, blurRowsAfterIndex)) {
      navigate(`${INVESTOR_DETAIL}/${rowKey}`);
    }
  };

  const rows = new AccessManagerInvestorsMapper(
    canAccessInvestors,
    requestParams.page,
    blurRowsAfterIndex,
  ).mapAllTo(investorsEngaged);
  return (
    <BasePaginatedTable
      tableName="access-manager-investors-table"
      enableSort
      onRowClick={onRowClick}
      headerStructure={[
        {
          headerKey: 'name',
          label: 'Name',
        },
        {
          headerKey: 'userType',
          label: 'Type',
        },
        {
          headerKey: 'firm',
          label: 'Firm & Role',
        },
        {
          headerKey: 'fitScore',
          label: 'Fit',
        },
        {
          headerKey: 'totalViewTime',
          label: 'View Time',
          title: 'Total time spent viewing your profile',
        },
        {
          headerKey: 'totalViewCount',
          label: 'Views',
          title: 'Total number of profile views',
        },
        {
          headerKey: 'lastViewedOn',
          label: 'Last seen',
          title: 'Latest time your profile was viewed',
        },
      ]}
      rows={rows.map((row, index) => ({
        rowKey: row.id,
        data: row,
        className: cn(
          'bg-white',
          row.isClickable &&
            !isRowBlurred(requestParams.page, index, blurRowsAfterIndex)
            ? 'cursor-pointer hover:bg-gray-100'
            : 'select-none cursor-default hover:bg-white',
          rowBlurClass({
            parentPage: requestParams.page,
            index,
            blurRowsAfterIndex,
          }),
          index === 0 && requestParams.page <= 1
            ? 'joyride-accessManagerFirstRow'
            : '',
        ),
      }))}
      resultCount={investorsEngagedCount}
      perPage={requestParams.perPage}
      page={requestParams.page}
      pageCount={pageCount}
      requestParams={{
        page: requestParams.page,
        perPage: requestParams.perPage,
        sortOrder: requestParams.sortOrder,
        sortKey: requestParams.sortKey,
        filter: requestParams.filter,
        fieldFilters: requestParams.fieldFilters,
      }}
    />
  );
}

export default AccessManagerInvestorsTable;
