import React from 'react';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import {
  ArrowsPointingOutIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';

export interface FileCardProps {
  title: string;
  fileKey: string;
  onDownloadFile?: () => void;
  onViewFile?: () => void;
  processFileButton?: JSX.Element;
}

function FileCard({
  title,
  fileKey,
  onDownloadFile,
  onViewFile,
  processFileButton,
}: FileCardProps): JSX.Element {
  return (
    <section aria-labelledby="transcript-file">
      <div className="rounded-lg bg-white shadow">
        <div className="flex flex-wrap justify-between px-4 py-5 align-middle sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dd className="my-1 text-sm text-gray-900">
                <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li className="flex flex-wrap items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex items-center">
                      <PaperClipIcon className="h-5 w-5 shrink-0 text-gray-400" />
                      <span className="ml-2 break-all">{fileKey}</span>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>

            <div className="flex flex-wrap justify-end space-x-3 space-y-1 sm:col-span-2">
              <button
                type="button"
                onClick={onDownloadFile}
                className="mt-1 inline-flex items-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
                Download
              </button>
              {onViewFile && (
                <button
                  type="button"
                  onClick={onViewFile}
                  className="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  <ArrowsPointingOutIcon className="mr-2 h-4 w-4" />
                  View
                </button>
              )}
              {processFileButton && processFileButton}
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}

export default FileCard;
