export const FOUNDERS_LENGTH_MIN = 1;
export const FOUNDERS_LENGTH_MAX = 4;

export const STARTUP_HIGHLIGHTS_LENGTH_MAX = 6;

export const BOARD_STAGES_LENGTH_MIN = 1;
export const BOARD_STAGES_LENGTH_MAX = 12;

export const NOTES_PER_CONNECTION_MAX = 50;
export const TASKS_PER_MEETING_MAX = 10;

export const MEETING_POINTS_MAX = 20;
