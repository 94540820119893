import React, { ReactNode } from 'react';
import { cn } from '/src/util/cn';

export interface BaseTableCellProps {
  children: ReactNode;
  rowKey: string;
  rowIndex: number;
  className?: string;
  onRowClick?: (rowId: string, rowIndex: number) => void;
}

export default function TableCell({
  children,
  rowKey,
  rowIndex,
  className,
  onRowClick,
}: BaseTableCellProps) {
  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(rowKey, rowIndex);
    }
  };
  return (
    <td
      className={cn('app-table-row-item', className)}
      onClick={handleRowClick}
    >
      {children}
    </td>
  );
}
