import React, { useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import { Field, Form, Formik } from 'formik';
import { FormatOptionLabelMeta } from 'react-select';
import { SelectOption } from '../../../../types/SelectOptions';
import { InvestorList } from '../../../../types/model/InvestorList';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormatOptionWithTag from '/src/components/inputs/ReactSelectAdditions/formatOptionWithTag';
import DebugRender from '/src/components/utility/DebugRender';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { Link } from 'react-router-dom';
import { STARTUP_INVESTOR_LEADS_ROUTE } from '/src/constants/Routes';

enum FormFieldNames {
  InvestorLists = 'investorLists',
}

type AddInvestorToListsDialogProps =
  | {
      investorId: string;
      investorName: string;
      investorLists: InvestorList[];
      onCancel: () => void;
      onSave: (
        newInvestorId: string,
        investorListIds: string[],
      ) => Promise<void>;
    }
  | {
      investorId?: undefined;
      investorName?: undefined;
      investorLists: InvestorList[];
      onCancel: () => void;
      onSave: (investorListIds: string[]) => Promise<void>;
    };

function AddInvestorToListsDialog({
  investorId,
  investorName,
  investorLists,
  onCancel,
  onSave,
}: AddInvestorToListsDialogProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
        <p className="font-medium">
          Target Lists allow you to organize and plan your investor outreach.
        </p>
        <p className="my-6">
          Upgrade to a paid plan to unlock the full investor database and target
          lists.
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_database_add_to_list"
            text="Unlock investor target lists"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-3xl sm:p-7">
      <Formik
        initialValues={{
          [FormFieldNames.InvestorLists]: investorLists
            .filter((x) =>
              x.investors.some(
                (investor) => investor.investorId === investorId,
              ),
            )
            .map((x) => x._id),
        }}
        onSubmit={async (values) => {
          if (investorId !== undefined) {
            await onSave(investorId, values[FormFieldNames.InvestorLists]);
          } else {
            await onSave(values[FormFieldNames.InvestorLists]);
          }
        }}
      >
        {({ values, touched, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 flex items-center justify-between">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Select target lists
                {investorName && <span> for {investorName}</span>}
              </h3>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green ml-3"
                  disabled={!isValid || isSubmitting || !touched}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>
            <Field
              className="custom-select"
              component={CustomSelect}
              formatOptionLabel={(
                option: SelectOption,
                formatOptionLabelMeta: FormatOptionLabelMeta<any>,
              ) => (
                <FormatOptionWithTag
                  option={option}
                  tagValue={
                    (investorLists
                      .find((x) => x._id === option.value)
                      ?.investors.length.toString() ?? '0') + ' Investors'
                  }
                  formatOptionLabelMeta={formatOptionLabelMeta}
                />
              )}
              isClearable
              isMulti
              label=""
              name={FormFieldNames.InvestorLists}
              options={investorLists.map((x) => ({
                value: x._id,
                label: x.name,
              }))}
              format
              placeholder="Select lists..."
            />

            {investorLists.length === 0 && (
              <Link
                to={STARTUP_INVESTOR_LEADS_ROUTE + '/target-lists'}
                className="hyperlink"
              >
                You do not have any lists yet, go create a new one.
              </Link>
            )}
          </Form>
        )}
      </Formik>
      <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
        <Disclosure>
          <Disclosure.Button className="app-button--neutral">
            <pre>InvestorLists:</pre>
          </Disclosure.Button>
          <Disclosure.Panel>
            <pre>{JSON.stringify(investorLists, null, 2)}</pre>
          </Disclosure.Panel>
        </Disclosure>
      </DebugRender>
    </div>
  );
}

export default AddInvestorToListsDialog;
