export const EMAIL_LENGTH_MAX = 255;
export const FIRM_LENGTH_MAX = 50;
export const HIGHLIGHT_TITLE_LENGTH_MAX = 60;

export const DATA_ROOM_PASSWORD_LENGTH_MIN = 6;

export const PHONE_NUMBER_LENGTH = 10;
export const ROLE_LENGTH_MAX = 50;

export const BOARD_STAGE_NAME_LENGTH_MAX = 24;
export const STARTUP_NAME_LENGTH_MAX = 50;
export const LIST_NAME_LENGTH_MAX = 64;
export const WEBSITE_LENGTH_MAX = 255;

export const TASK_NAME_LENGTH_MAX = 140;
export const FIRST_NAME_LENGTH_MAX = 50;
export const LAST_NAME_LENGTH_MAX = 50;

export const EXTRA_LONG_TEXTAREA_LENGTH_MAX = 600;
export const LONG_TEXTAREA_LENGTH_MAX = 420;
export const MEDIUM_TEXTAREA_LENGTH_MAX = 280;
export const SHORT_TEXTAREA_LENGTH_MAX = 140;

export const NOTE_CONTENT_LENGTH_MAX = 10_000;
