import { getBaseBucketUrl } from '../urls';

/**
 * Converts a string to lower case dash-case
 * @param text The string to convert
 * @example "My Text" -> "my-text"
 */
export function toDashCase(text: string): string {
  return text.replaceAll(' ', '-').toLowerCase();
}

/**
 * Formats a phone number into the American format
 * @param phoneNumberString Phone number to format
 */
export function formatPhoneNumber(phoneNumberString: string): string | null {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

/**
 * Formats an S3 image key to be accessed by an image tag
 * @param x Number to format
 */
export function formatImageAddress(
  imageKey: string | undefined,
): string | undefined {
  if (!imageKey) {
    return undefined;
  }
  return `${getBaseBucketUrl()}${imageKey}`;
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Indicates whether a specified string is falsey, empty, or consists only of white-space characters
 * @param str The string to check
 * @returns True if the string is Falsey OR Empty OR White-space
 */
export function isBlank(str: string): boolean {
  return !str || /^\s*$/.test(str);
}

/**
 * This is used to dynamically build tailwind styles by concatenating class names
 * @param classes Strings to concatenate
 * @returns A single string with all class names
 * @deprecated Use cn() instead
 */
export function joinClassNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export function splitOnWhiteSpace(str: string): string[] {
  return str.split(/(\s+)/).filter((x) => x.trim().length > 0);
}

/**
 * Shortens a URL by removing the protocol and 'www.' string, if present.
 * @param url The URL to be shortened.
 * @returns The shortened URL without the protocol and 'www.' string.
 */
export function shortenWebUrl(url: string): string {
  const result = url.replace(/(^\w+:|^)\/\/(www\.)?/, '');
  return result;
}
