import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useContext, useEffect } from 'react';
import {
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import AuthService from '/src/services/AuthService';
import UserAvatarWithInitials from '../../UserAvatarWithInitials';
import { Link } from 'react-router-dom';
import { AccountMetadataContext } from '../../../contexts/AccountMetadataContext';
import { SubscriptionTierPill } from '../../SubscriptionTierPill';
import { EnvironmentTypes } from '/../libs/shared-types/src/constants/EnvironmentTypes';
import ToggleSwitch from '../../utility/ToggleSwitch';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import {
  localStorageGetDebugMode,
  localStorageGetIsDarkTheme,
} from '/src/middleware/LocalStorage';

const userNavigation = [
  // { name: 'Account Settings', href: '#' },
  // { name: 'Manage Notifications', href: '#' },
  { name: 'Manage Subscription', href: 'settings/billing' },
];

interface AccountDropDownMenuProps {
  email: string;
  showFullName: boolean;
}

function AccountDropDownMenu({
  email,
  showFullName,
}: AccountDropDownMenuProps): JSX.Element {
  const {
    subscriptionTier,
    firstName,
    lastName,
    profilePicKey,
    refreshUserMetadata,
  } = useContext(AccountMetadataContext);

  useEffect(() => {
    refreshUserMetadata();
  }, []);

  if (!subscriptionTier || !firstName || !lastName) {
    return <></>;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open account menu</span>
        <UserAvatarWithInitials
          containerStyles="size-10"
          firstName={firstName}
          lastName={lastName}
          textStyles="text-xl"
          imgSrc={formatImageAddress(profilePicKey)}
          imgAlt="Account Avatar"
        />
        {showFullName && (
          <span className="hidden lg:flex lg:items-center">
            <span
              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
              aria-hidden="true"
            >
              {`${firstName} ${lastName}`}
            </span>
            <ChevronDownIcon
              className="ml-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        )}
      </MenuButton>
      <Transition
        as="div"
        transition
        className={`data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
      >
        <MenuItems className="absolute bottom-12 left-10 z-10 mt-2 min-w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-4 py-3">
            <div className="flex w-full flex-row items-center justify-between space-x-6">
              <p className="text-sm">Signed in as</p>
              <SubscriptionTierPill />
            </div>
            <p
              title={email}
              className="truncate text-sm font-medium text-gray-900"
            >
              {email}
            </p>
          </div>
          <div className="py-1">
            {userNavigation.map((item) => (
              <MenuItem key={item.name}>
                {({ focus }) => (
                  <Link
                    to={item.href}
                    className={joinClassNames(
                      focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </MenuItem>
            ))}
          </div>
          <div className="py-1">
            <MenuItem>
              {({ focus }) => (
                <button
                  type="button"
                  className={joinClassNames(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm',
                  )}
                  onClick={AuthService.logout}
                >
                  Log Out
                </button>
              )}
            </MenuItem>
          </div>

          {process.env.REACT_APP_ENV !== EnvironmentTypes.Production && (
            <div className="space-y-3 px-4 pb-1 pt-2 text-sm text-gray-700">
              <ToggleSwitch
                showToggleIcon
                toggleOn={() =>
                  localStorage.setItem(LocalStorageKeysConst.DEBUG_MODE, 'true')
                }
                toggleOff={() =>
                  localStorage.setItem(
                    LocalStorageKeysConst.DEBUG_MODE,
                    'false',
                  )
                }
                name="enableDebugMode"
                label="Debug Mode"
                initialState={localStorageGetDebugMode()}
              />
              <ToggleSwitch
                showToggleIcon
                toggleOn={() => {
                  localStorage.setItem(
                    LocalStorageKeysConst.IS_DARK_THEME,
                    'true',
                  );
                  document.documentElement.classList.add('dark');
                }}
                toggleOff={() => {
                  localStorage.setItem(
                    LocalStorageKeysConst.IS_DARK_THEME,
                    'false',
                  );
                  document.documentElement.classList.remove('dark');
                }}
                name="enableDarkTheme"
                label="Dark Theme"
                initialState={localStorageGetIsDarkTheme()}
              />
            </div>
          )}
        </MenuItems>
      </Transition>
    </Menu>
  );
}

export default AccountDropDownMenu;
