import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import { StartupAccessManagerView } from '../../../../types/view/StartupAccessManagerView';
import DurationCell from '/src/components/table/cells/DurationCell';
import LastViewCell from '/src/components/table/cells/LastViewCell';
import InvestorTypeTagCell from '/src/components/table/cells/InvestorTypeTagCell';
import InvestorTypes from '../../../../constants/InvestorTypes';
import CellFirmAndRole from '/src/components/table/cells/InvestorCellFirmRole';
import { HiddenFitScore } from '/src/util/generateTagCloud';
import FitScoreTagCell from '/src/components/table/cells/FitScoreTagCell';
import { isRowBlurred } from '/src/components/table/BackendPaginatedTable';
import AccessManagerInvestorsNameCell from './Cells/AccessManagerInvestorsNameCell';

export interface StartupAccessManagerRow extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  userType: ReactNode;
  firm: ReactNode;
  fitScore: ReactNode;
  totalViewTime: ReactNode;
  totalViewCount: ReactNode;
  lastViewedOn: ReactNode;
  isClickable: boolean;
}

export class AccessManagerInvestorsMapper extends BaseMapper<
  StartupAccessManagerView,
  StartupAccessManagerRow
> {
  private _hasFitScoreAccess: boolean;
  private _parentPage: number;
  private _blurRowsAfterIndex?: number;
  constructor(
    hasFitScoreAccess: boolean,
    parentPage: number,
    blurRowsAfterIndex?: number,
  ) {
    super();
    this._hasFitScoreAccess = hasFitScoreAccess;
    this._parentPage = parentPage;
    this._blurRowsAfterIndex = blurRowsAfterIndex;
  }
  mapTo(accessManagerEntry: StartupAccessManagerView, index: number) {
    const isBlurred = isRowBlurred(
      this._parentPage,
      index,
      this._blurRowsAfterIndex,
    );
    const isClickable = true;
    return {
      id: accessManagerEntry.accountId,

      name: (
        <AccessManagerInvestorsNameCell
          accessManagerEntry={accessManagerEntry}
          isBlurred={isBlurred}
        />
      ),

      userType: (
        <InvestorTypeTagCell
          investorType={accessManagerEntry.userType as InvestorTypes}
        />
      ),
      firm: (
        <CellFirmAndRole
          firm={accessManagerEntry.firm}
          role={accessManagerEntry.role}
        />
      ),
      fitScore: this._hasFitScoreAccess ? (
        <FitScoreTagCell fitScore={accessManagerEntry.fitScore} />
      ) : (
        <HiddenFitScore />
      ),
      totalViewTime: (
        <DurationCell
          durationInMs={accessManagerEntry.totalMillisecondsSpentOnDeal}
        />
      ),
      totalViewCount: (
        <div className="app-table-row-text">
          {accessManagerEntry.totalViewCount}
        </div>
      ),

      lastViewedOn: (
        <LastViewCell lastViewedOn={accessManagerEntry.lastViewedOn} />
      ),
      isClickable,
    };
  }
}
