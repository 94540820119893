import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DropdownSelect from '/src/components/DropdownSelect';
import SearchBar from '/src/components/inputs/SearchBar';
import Alert from '/src/components/notifications/Alert';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { STARTUP_FLOWLINK_MANAGER_ROUTE } from '/src/constants/Routes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import EmptyState from '/src/components/notifications/EmptyState';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { CsvDownloadButton } from '../CsvDownloadButton';
import FieldFilterWrapper from '/src/components/FieldFilterWrapper';
import { StartupGetInvestorsEngagedCsv } from '../../../../../../libs/shared-types/src/constants/ApiRoutes';
import saveAs from 'file-saver';
import API from '/src/middleware/API';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import LocalStorageKeysConst from '/src/constants/LocalStorageKeys';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import AccessManagerInvestorsTable from './AccessManagerInvestorsTable';
import { StartupAccessManagerPaginatedCollectionResponse } from '../../../../types/view/StartupAccessManagerView';

interface AccessManagerInvestorsProps {
  apiResponse?: StartupAccessManagerPaginatedCollectionResponse;
  isLoading: boolean;
  isFirstLoading: boolean;
}

function AccessManagerInvestors({
  apiResponse,
  isLoading,
  isFirstLoading,
}: AccessManagerInvestorsProps) {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const { joyrideActivationTourState, setJoyrideActivationTourState } =
    useContext(SharedStateContext);
  const canAccessInvestors = hasFeatureAccess(
    StartupPaidFeatures.AccessManagerInvestors,
    subscriptionTier,
  );

  const canDownloadCsv = hasFeatureAccess(
    StartupPaidFeatures.ViewSessionCsvExport,
    subscriptionTier,
  );

  useEffect(() => {
    const isNotResumeTour =
      isLoading ||
      !localStorage.getItem(
        LocalStorageKeysConst.NEW_USER_FIRST_LOGIN_TOUR_IS_RUNNING,
      );

    if (isNotResumeTour) {
      return;
    }

    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });
  }, [isLoading]);

  if (!apiResponse || !apiResponse.results || isFirstLoading) {
    return (
      <PageLoadingSpinner message="Gathering your biggest supporters... 🙌" />
    );
  }

  async function handleGetInvestorsEngagedCsv() {
    if (!apiResponse) {
      return;
    }

    const blob = await API.getCsv(
      StartupGetInvestorsEngagedCsv.buildEndpoint(undefined, {
        fieldFilters: apiResponse.fieldFilters,
        filter: apiResponse.filter,
        sortKey: apiResponse.sortKey,
        sortOrder: apiResponse.sortOrder,
      }),
    );
    if (!blob) {
      return;
    }
    saveAs(blob, 'Engaged Investors.csv');
  }

  return (
    <>
      {apiResponse.totalCount === 0 && apiResponse.filter === '' && (
        <EmptyState
          title="No investors have access to your one-pager yet"
          subTitle="Investors with a Flowlie account who view your one-pager will be listed here."
          icon={<EyeSlashIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            <Link
              to={STARTUP_FLOWLINK_MANAGER_ROUTE}
              className="app-button--primary"
            >
              Share your deck & one-pager
            </Link>
          }
        />
      )}

      {(apiResponse.totalCount > 0 || apiResponse.filter !== '') && (
        <>
          <div className="mb-3 flex flex-row items-center justify-between">
            <span className="flex items-center">
              <SearchBar
                placeholder="Search Investors"
                initialValue={apiResponse.filter}
                isDisabled={!canAccessInvestors}
                isDebounce
                shouldUpdateUrlParams
              />
              {isLoading && <LoadingSpinner color="blue" />}
            </span>

            <div className="flex flex-row space-x-2">
              <FieldFilterWrapper>
                {(updateParams) => (
                  <DropdownSelect
                    onSelectionChange={(option) => {
                      updateParams({
                        roundStage: option,
                      });
                    }}
                    initialValue={apiResponse.fieldFilters?.roundStage ?? ''}
                    label="Round Stage"
                    items={apiResponse.startupRoundOptions}
                  />
                )}
              </FieldFilterWrapper>
              <CsvDownloadButton
                handleDownload={handleGetInvestorsEngagedCsv}
                tooltip="Export to CSV a list of all investors who engaged with your one-pager & deck"
                isDisabled={!canDownloadCsv}
              />
            </div>
          </div>

          <AccessManagerInvestorsTable
            blurRowsAfterIndex={canAccessInvestors ? undefined : 4}
            canAccessInvestors={canAccessInvestors}
            investorsEngaged={apiResponse.results}
            pageCount={apiResponse.totalPages}
            investorsEngagedCount={apiResponse.totalCount}
            requestParams={{
              page: apiResponse.page,
              perPage: apiResponse.perPage,
              sortOrder: apiResponse.sortOrder,
              sortKey: apiResponse.sortKey,
              filter: apiResponse.filter,
              fieldFilters: apiResponse.fieldFilters,
            }}
          />
        </>
      )}

      {apiResponse.totalCount > 0 && apiResponse.totalCount < 5 && (
        <div className="my-4">
          <Alert
            color="blue"
            alertType="Info"
            content={
              <p>
                <Link
                  className="hyperlink font-bold underline"
                  to={STARTUP_FLOWLINK_MANAGER_ROUTE}
                >
                  Send your FlowLink
                </Link>{' '}
                to investors to securely share your deck and company one-pager
              </p>
            }
            isShown={true}
            canDismiss={false}
          />
        </div>
      )}
    </>
  );
}

export default AccessManagerInvestors;
