import React, { Fragment, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  StarIcon,
} from '@heroicons/react/20/solid';
import { StarRating } from '/../libs/shared-types/src/constants/StarRating';

export function getStars(rating: number): JSX.Element {
  const stars = [];

  for (let i = 0; i < rating; i++) {
    stars.push(
      <StarIcon
        key={'star_' + i}
        className="h-4 w-4 text-amber-400"
        aria-hidden="true"
      />,
    );
  }

  return <>{stars}</>;
}

export const StarRatingSelectOptions = [
  { value: StarRating.None, label: StarRating.None },
  { value: StarRating.One, label: StarRating.One },
  { value: StarRating.Two, label: StarRating.Two },
  { value: StarRating.Three, label: StarRating.Three },
  { value: StarRating.Four, label: StarRating.Four },
  { value: StarRating.Five, label: StarRating.Five },
];

const STAR_RATING_OPTIONS: StarRating[] = [
  StarRating.None,
  StarRating.One,
  StarRating.Two,
  StarRating.Three,
  StarRating.Four,
  StarRating.Five,
];

interface StarRatingLabelProps {
  rating: number;
  isInput?: boolean;
}

export function StarRatingLabel({
  rating,
  isInput = false,
}: StarRatingLabelProps): JSX.Element {
  return (
    <div className="relative">
      <span className="ml-5 block truncate">
        {rating > 0 ? rating.toFixed(1) : isInput ? 'Add Rating' : 'No Rating'}
      </span>
      <span className="absolute -left-2 top-0 flex items-center pl-2">
        <StarIcon className="h-4 w-4 text-amber-400" aria-hidden="true" />
      </span>
    </div>
  );
}

interface StarRatingSelectProps {
  onSelect: (rating: StarRating) => void;
  value: StarRating;
  disabled?: boolean;
}

function StarRatingSelect({
  onSelect,
  value,
  disabled = false,
}: StarRatingSelectProps) {
  const [selected, setSelected] = useState(value);

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative cursor-pointer">
        <ListboxButton
          onClick={(e: any) => e.stopPropagation()}
          className="relative mr-5 w-full cursor-default rounded-md px-2.5 py-1.5 text-left text-xs tracking-wider hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300"
        >
          <StarRatingLabel rating={selected} isInput />
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </ListboxButton>
        <Transition
          as="div"
          transition
          className={`data-[closed]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in`}
        >
          <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-36 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {STAR_RATING_OPTIONS.map((option) => (
              <ListboxOption
                as="li"
                key={option}
                className={({ focus }) =>
                  `relative cursor-default select-none py-2 pl-8 pr-4 ${
                    focus ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                  }`
                }
                value={option}
                onClick={(e: any) => {
                  e.stopPropagation();
                  onSelect(option);
                }}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`flex flex-row space-x-0.5 truncate text-xs ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option > 0 ? getStars(option) : 'No rating'}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}

export default StarRatingSelect;
