import { $getRoot, createEditor } from 'lexical';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeNode, CodeHighlightNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ConnectionNoteView } from '../../../../libs/shared-types/src/types/view/ConnectionNoteView';
import { DEFAULT_NOTE_TITLE } from '/src/components/ConnectionNotes/ConnectionNoteEditor';

const initialConfig = {
  namespace: 'config-mock',
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
  ],
  onError: (error: Error) => {
    throw error;
  },
};

export const getLexicalContentLength = (stringifiedNode: string) => {
  const editor = createEditor(initialConfig);
  const editorState = editor.parseEditorState(stringifiedNode);
  editor.setEditorState(editorState);
  const editorStateTextString = editor.read(() => $getRoot().getTextContent());
  return editorStateTextString.length;
};

export const getEmptyConnectionNotesCount = (
  connectionNotes: ConnectionNoteView[] | undefined,
) => {
  // Count the number of notes that have no content AND no title or default title
  let notesWithoutContent = 0;
  connectionNotes?.forEach((connectionNote) => {
    const textLength = getLexicalContentLength(
      JSON.stringify(connectionNote.content),
    );
    if (
      !textLength &&
      (!connectionNote.title || connectionNote.title === DEFAULT_NOTE_TITLE)
    ) {
      notesWithoutContent += 1;
    }
  });

  return notesWithoutContent;
};
