import React, { useRef } from 'react';
import { Dialog, DialogTitle } from '@headlessui/react';
import { QRCodeSVG } from 'qrcode.react';
import ModalWrapper from './ModalWrapper';
import Logger from '/src/services/logger';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

function downloadStringAsFile(data: string, filename: string) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = data;
  a.click();
}

interface QRCodeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  linkText: string;
  allowDownload?: boolean;
  level?: 'L' | 'M' | 'Q' | 'H';
  imageSrc?: any;
}

function QRCodeDialog({
  isOpen,
  onClose,
  title,
  linkText,
  level = 'H',
  allowDownload = false,
  imageSrc,
}: QRCodeDialogProps): JSX.Element {
  const svgRef = useRef<any>(null);

  const imageSettings = imageSrc
    ? {
        src: imageSrc,
        x: undefined,
        y: undefined,
        height: 64,
        width: 64,
        excavate: true,
      }
    : undefined;

  function onSVGButtonClick() {
    const node = svgRef.current;
    if (node == null) {
      Logger.error('QRCodeDialog node is null');
      return;
    }

    const svgNode = node.children[0];
    if (svgNode == null) {
      Logger.error('QRCodeDialog svgNode is null');
      return;
    }

    // For SVG, we need to get the markup and turn it into XML.
    // Using XMLSerializer is the easiest way to ensure the markup
    // contains the xmlns. Then we make sure it gets the right DOCTYPE,
    // encode all of that to be safe to be encoded as a URI (which we
    // need to stuff into href).
    const serializer = new XMLSerializer();
    const fileURI =
      'data:image/svg+xml;charset=utf-8,' +
      encodeURIComponent(
        '<?xml version="1.0" standalone="no"?>' +
          serializer.serializeToString(svgNode),
      );

    downloadStringAsFile(fileURI, 'flowlink-qrcode.svg');
  }

  return (
    <ModalWrapper open={isOpen} onClose={onClose}>
      <div className="space-y-10 bg-white p-4 sm:max-w-2xl sm:p-7">
        <DialogTitle className="my-3 text-center text-xl font-medium leading-6 text-gray-900 sm:mt-0">
          {title}
        </DialogTitle>
        <div ref={svgRef} className="flex justify-center">
          <QRCodeSVG
            size={300}
            value={linkText}
            level={level}
            imageSettings={imageSettings}
          />
        </div>

        {allowDownload && (
          <div className="flex justify-end">
            <button
              className="inline-flex items-center rounded-full border border-transparent bg-blue-600 px-3.5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              type="button"
              onClick={onSVGButtonClick}
            >
              <ArrowDownTrayIcon className="mr-2 h-4 w-4" />
              Download
            </button>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

export default QRCodeDialog;
