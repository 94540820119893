import React from 'react';
import * as yup from 'yup';
import { Field, FieldArray, useFormikContext, FormikValues } from 'formik';
import FormField from '/src/interfaces/FormField';
import StartupFieldNames from '/src/enums/StartupFieldNames';
import FormikInput from '/src/components/inputs/FormikInput';
import CustomSelect from '/src/components/inputs/CustomSelect';
import {
  EDUCATION_LEVEL_OPTIONS,
  FOUNDER_COMMITMENT_OPTIONS,
  INVESTOR_LOCATION_OPTIONS,
  PRONOUNS_OPTIONS,
} from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { COMPANIES_FOUNDED_COUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/CompaniesFoundedCountOptions';
import { BACKING_AMOUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/BackingAmountOptions';
import { EXIT_AMOUNT_OPTIONS } from '/../libs/shared-types/src/constants/SelectOptions/ExitAmountOptions';
import UNIVERSITY_AFFILIATION_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/UniversityOptions';
import NATIONALITY_OPTIONS from '/../libs/shared-types/src/constants/SelectOptions/NationalityOptions';
import websiteSchema from '/src/constants/validation/websiteSchema';
import DebugRender from '/src/components/utility/DebugRender';
import {
  FOUNDERS_LENGTH_MIN,
  FOUNDERS_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/ObjectArrayLengthRanges';
import {
  EMAIL_LENGTH_MAX,
  FIRST_NAME_LENGTH_MAX,
  LAST_NAME_LENGTH_MAX,
  LONG_TEXTAREA_LENGTH_MAX,
  ROLE_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/TextLengthRanges';
import {
  YEARS_OF_INDUSTRY_EXPERIENCE_MAX,
  YEARS_OF_INDUSTRY_EXPERIENCE_MIN,
} from '/../libs/shared-types/src/constants/NumericRanges';
import {
  MAX_NATIONALITIES,
  MAX_UNIVERSITY_AFFILIATIONS,
} from '/../libs/shared-types/src/constants/MultiSelectLimits';
import FounderFieldNames from '/src/enums/FounderFieldNames';
import LimitedMenu from '/src/components/inputs/ReactSelectAdditions/LimitedMenu';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { createFilter } from 'react-select';
import OptimizedOption from '/src/components/inputs/ReactSelectAdditions/OptimizedOption';
import formatNationalityOptionLabel from '/src/components/inputs/ReactSelectAdditions/formatNationalityOptionLabel';
import ImageInput from '/src/components/inputs/ImageInput';
import { validateFileSize, validateFileType } from '/src/util/FileHelpers';
import { FileType } from '/src/constants/FileUploadTypes';
import formatGroupLabel from '../../inputs/ReactSelectAdditions/selectFormatGroupLabel';
import TextCharacterCounter from '../../TextCharacterCounter';
import YearsInput from '../../inputs/YearsInput';
import BooleanSelectInput from '../../inputs/BooleanSelectInput';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';

const validationSchema = yup.object().shape({
  [StartupFieldNames.Founders]: yup
    .array()
    .of(
      yup.object().shape({
        // these inner constraints take precedence
        [FounderFieldNames.FirstName]: yup
          .string()
          .max(
            FIRST_NAME_LENGTH_MAX,
            `Must be at most ${FIRST_NAME_LENGTH_MAX} characters`,
          )
          .required('Required'),
        [FounderFieldNames.LastName]: yup
          .string()
          .max(
            LAST_NAME_LENGTH_MAX,
            `Must be at most ${LAST_NAME_LENGTH_MAX} characters`,
          )
          .required('Required'),
        [FounderFieldNames.Email]: yup
          .string()
          .max(
            EMAIL_LENGTH_MAX,
            `Must be at most ${EMAIL_LENGTH_MAX} characters`,
          )
          .email('A valid email is required')
          .required('Required'),
        [FounderFieldNames.Role]: yup
          .string()
          .max(ROLE_LENGTH_MAX, `Must be at most ${ROLE_LENGTH_MAX} characters`)
          .required('Required'),
        [FounderFieldNames.IsTechnical]: yup.boolean().required('Required'),
        [FounderFieldNames.ProfilePic]: yup
          .mixed()
          .nullable()
          .when(`${FounderFieldNames.ProfilePic}MustValidate`, {
            is: (hasChanged: boolean) => hasChanged === true,
            then: (schema) =>
              schema
                .nullable()
                .test(
                  'fileFormat',
                  'Unsupported file format',
                  (value: any) =>
                    !value ||
                    (value && validateFileType(value, FileType.Image)),
                )
                .test(
                  'fileSize',
                  'Uploaded file is too big',
                  (value: any) =>
                    !value ||
                    (value && validateFileSize(value.size, FileType.Image)),
                ),
          }),
        [FounderFieldNames.About]: yup
          .string()
          .nullable()
          .max(
            LONG_TEXTAREA_LENGTH_MAX,
            `Must be at most ${LONG_TEXTAREA_LENGTH_MAX} characters`,
          ),
        [FounderFieldNames.Location]: yup.string().nullable(),
        [FounderFieldNames.Pronouns]: yup.string().nullable(),
        [FounderFieldNames.Commitment]: yup.string().nullable(),
        [FounderFieldNames.HighestEducationLevel]: yup.string().nullable(),
        [FounderFieldNames.Nationalities]: yup
          .array()
          .of(yup.string())
          .nullable()
          .max(
            MAX_NATIONALITIES,
            `At most ${MAX_NATIONALITIES} nationalities are allowed`,
          ),
        [FounderFieldNames.UniversityAffiliations]: yup
          .array()
          .of(yup.string())
          .nullable()
          .max(
            MAX_UNIVERSITY_AFFILIATIONS,
            `At most ${MAX_UNIVERSITY_AFFILIATIONS} university affiliations are allowed`,
          ),
        [FounderFieldNames.YearsOfIndustryExperience]: yup
          .number()
          .nullable()
          .min(
            YEARS_OF_INDUSTRY_EXPERIENCE_MIN,
            `Cannot have less than ${YEARS_OF_INDUSTRY_EXPERIENCE_MIN} years of experience`,
          )
          .max(
            YEARS_OF_INDUSTRY_EXPERIENCE_MAX,
            `Cannot have more than ${YEARS_OF_INDUSTRY_EXPERIENCE_MAX} years of experience`,
          ),
        [FounderFieldNames.CompaniesFoundedCount]: yup.string().nullable(),
        [FounderFieldNames.PreviousBackingAmount]: yup.string().nullable(),
        [FounderFieldNames.PreviousExitAmount]: yup.string().nullable(),
        [FounderFieldNames.LinkedIn]: websiteSchema(false),
        [FounderFieldNames.Twitter]: websiteSchema(false),
      }),
    )
    .required('Must have a founder') // these constraints are shown if and only if inner constraints are satisfied
    .min(
      FOUNDERS_LENGTH_MIN,
      `At least ${FOUNDERS_LENGTH_MIN} founder is required`,
    )
    .max(
      FOUNDERS_LENGTH_MAX,
      `At most ${FOUNDERS_LENGTH_MAX} founders are allowed`,
    ),
});

const emptyFounder = {
  [FounderFieldNames.About]: '',
  [FounderFieldNames.Commitment]: '',
  [FounderFieldNames.CompaniesFoundedCount]: '',
  [FounderFieldNames.Email]: '',
  [FounderFieldNames.FirstName]: '',
  [FounderFieldNames.HighestEducationLevel]: '',
  [FounderFieldNames.IsTechnical]: '',
  [FounderFieldNames.LastName]: '',
  [FounderFieldNames.LinkedIn]: '',
  [FounderFieldNames.Location]: '',
  [FounderFieldNames.Nationalities]: '',
  [FounderFieldNames.PreviousBackingAmount]: '',
  [FounderFieldNames.PreviousExitAmount]: '',
  [FounderFieldNames.ProfilePic]: '',
  [FounderFieldNames.Pronouns]: '',
  [FounderFieldNames.Role]: '',
  [FounderFieldNames.Twitter]: '',
  [FounderFieldNames.UniversityAffiliations]: '',
  [FounderFieldNames.YearsOfIndustryExperience]: '',
};

function FoundersFields(): JSX.Element {
  const { values, errors, touched } = useFormikContext<any>();
  return (
    <div>
      <FieldArray name={StartupFieldNames.Founders}>
        {({ remove, push }) => (
          <div>
            {values[StartupFieldNames.Founders].length > 0 &&
              values[StartupFieldNames.Founders].map(
                (founder: FormikValues, index: number) => (
                  <section
                    className="my-4 rounded-lg border bg-slate-50 p-4"
                    key={values[StartupFieldNames.Founders].indexOf(founder)}
                  >
                    <div className="flex flex-row space-x-4">
                      <div className="max-w-xs">
                        <Field
                          canRemoveFile
                          component={ImageInput}
                          label="Upload a photo"
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.ProfilePic}`}
                        />
                      </div>

                      <div className="w-full">
                        <Field
                          component={FormikInput}
                          label="First Name"
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.FirstName}`}
                          type="text"
                        />

                        <Field
                          component={FormikInput}
                          label="Last Name"
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.LastName}`}
                          type="text"
                        />

                        <Field
                          component={FormikInput}
                          label="Email"
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Email}`}
                          type="email"
                        />
                      </div>
                    </div>

                    <Field
                      component={FormikInput}
                      label="Role"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Role}`}
                      type="text"
                    />

                    <BooleanSelectInput
                      label="Is this founder technical?"
                      secondaryLabel="Investors want to see a mix of technical and non-technical founders."
                      tooltip="Technical founders are the people who write code, engineer devices, formulate substances, or do any other technical work."
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.IsTechnical}`}
                    />

                    <Field
                      className="custom-select"
                      closeMenuOnSelect
                      component={CustomSelect}
                      isClearable
                      label="What is their time commitment to this startup?"
                      secondaryLabel="Investors prefer full-time founders unless you are just getting started."
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Commitment}`}
                      options={FOUNDER_COMMITMENT_OPTIONS}
                      placeholder="Select time commitment..."
                    />

                    <Field
                      className="custom-select"
                      closeMenuOnSelect
                      component={CustomSelect}
                      groupLabelFormat={formatGroupLabel}
                      isClearable
                      label="Where does the founder live?"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Location}`}
                      options={INVESTOR_LOCATION_OPTIONS}
                      placeholder="Select location..."
                    />

                    <YearsInput
                      label="How many years of industry experience does the founder have?"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.YearsOfIndustryExperience}`}
                    />

                    <Field
                      className="custom-select"
                      closeMenuOnSelect
                      component={CustomSelect}
                      isClearable
                      label="Has the founder previously started and built another company?"
                      tooltip="Having started and built a company before means they were a founder, not an early employee."
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.CompaniesFoundedCount}`}
                      options={COMPANIES_FOUNDED_COUNT_OPTIONS}
                      placeholder="Select..."
                    />

                    {values[StartupFieldNames.Founders][index][
                      FounderFieldNames.CompaniesFoundedCount
                    ] ? (
                      <>
                        <Field
                          className="custom-select"
                          closeMenuOnSelect
                          component={CustomSelect}
                          isClearable
                          label="Has the founder successfully raised VC funding for a previous company?"
                          tooltip="Having raised VC funding means being in charge of fundraising for a company previously founded and receiving capital from VC firms, family offices, angel syndicates, or individual angel investors."
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.PreviousBackingAmount}`}
                          options={BACKING_AMOUNT_OPTIONS}
                          placeholder="Select..."
                        />

                        <Field
                          className="custom-select"
                          closeMenuOnSelect
                          component={CustomSelect}
                          isClearable
                          label="Has the founder successfully exited a previous company?"
                          tooltip="Having exited a company means selling a company via M&A or taking it public via IPO. Total value refers to the cumulative enterprise value of the exited companies at the moment of exit."
                          name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.PreviousExitAmount}`}
                          options={EXIT_AMOUNT_OPTIONS}
                          placeholder="Select..."
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    <Field
                      className="custom-select"
                      closeMenuOnSelect
                      component={CustomSelect}
                      isClearable
                      label="What is the highest level of education they attained?"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.HighestEducationLevel}`}
                      options={EDUCATION_LEVEL_OPTIONS}
                      placeholder="Select education level..."
                    />

                    <Field
                      className="custom-select"
                      classNamePrefix="optimized-select"
                      component={CustomSelect}
                      components={{
                        Option: OptimizedOption,
                        Menu: LimitedMenu,
                      }}
                      filterOption={createFilter({ ignoreAccents: false })}
                      isClearable
                      isMulti
                      label="Which of the following universities have they attended?"
                      maxCount={MAX_UNIVERSITY_AFFILIATIONS}
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.UniversityAffiliations}`}
                      options={UNIVERSITY_AFFILIATION_OPTIONS}
                      placeholder="Search universities..."
                    />

                    <Field
                      className="custom-select"
                      component={CustomSelect}
                      components={{ Menu: LimitedMenu }}
                      formatOptionLabel={formatNationalityOptionLabel}
                      isClearable
                      isMulti
                      label="What nationalities do they identify with?"
                      maxCount={MAX_NATIONALITIES}
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Nationalities}`}
                      options={NATIONALITY_OPTIONS}
                      placeholder="Search countries..."
                    />

                    <Field
                      className="custom-select"
                      closeMenuOnSelect
                      component={CustomSelect}
                      isClearable
                      label="Pronouns"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Pronouns}`}
                      options={PRONOUNS_OPTIONS}
                      placeholder="Select pronouns..."
                    />

                    <Field
                      component={FormikInput}
                      label="LinkedIn URL"
                      secondaryLabel="Highly recommended because investors always want to learn about founders' professional background"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.LinkedIn}`}
                      type="text"
                    />

                    <Field
                      component={FormikInput}
                      label="Twitter URL"
                      name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.Twitter}`}
                      type="text"
                    />

                    <>
                      <Field
                        component={FormikInput}
                        boxType="textarea"
                        customStyle="h-28 max-h-28"
                        name={`${StartupFieldNames.Founders}[${index}].${FounderFieldNames.About}`}
                        label="About"
                        secondaryLabel="Investors want to know more details about the founder’s background, unique value-add, and problem-founder fit."
                        tooltip="You can write about your years of work experience, industry, skills, or previous startups. People also talk about their achievements both as individuals and as collaborators. Focus on what makes you the perfect person to be solving this problem."
                        type="text"
                        placeholder="Ex: For the past X years, I have been involved in this field, first as an operator, then as a consultant, and most recently as an investor. Having experienced these problems from several angles, I came to the realization that there is a great business opportunity here for which my background at Company X and Company Y are a perfect fit."
                      />
                      <TextCharacterCounter
                        textLength={
                          values[StartupFieldNames.Founders][index][
                            FounderFieldNames.About
                          ]?.length ?? 0
                        }
                        maxLength={LONG_TEXTAREA_LENGTH_MAX}
                      />
                    </>

                    <button
                      type="button"
                      className="app-button--neutral mt-4 flex items-center space-x-2"
                      disabled={
                        values[StartupFieldNames.Founders].length <=
                        FOUNDERS_LENGTH_MIN
                      }
                      onClick={() => remove(index)}
                    >
                      <TrashIcon className="h-4 w-4" />
                      <span className="pt-0.5">Remove</span>
                    </button>
                  </section>
                ),
              )}
            {
              // Display error for outer validation
              typeof errors[StartupFieldNames.Founders] === 'string' && (
                <p className="app-error-message">
                  {errors[StartupFieldNames.Founders]?.toString()}
                </p>
              )
            }

            <button
              type="button"
              className="button mt-8"
              disabled={
                values[StartupFieldNames.Founders].length ===
                FOUNDERS_LENGTH_MAX
              }
              onClick={() => push(emptyFounder)}
            >
              <PlusIcon className="mr-1.5 inline-flex h-5 w-5" />
              Add Another Founder
            </button>
          </div>
        )}
      </FieldArray>

      <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
        <Disclosure>
          <DisclosureButton className="app-button--neutral">
            <pre>Founders Values:</pre>
          </DisclosureButton>
          <DisclosurePanel>
            <pre>{JSON.stringify(values.founders, null, 2)}</pre>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure>
          <DisclosureButton className="app-button--neutral">
            <pre>Founders Errors:</pre>
          </DisclosureButton>
          <DisclosurePanel>
            <pre>{JSON.stringify(errors.founders, null, 2)}</pre>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure>
          <DisclosureButton className="app-button--neutral">
            <pre>Founders Touched:</pre>
          </DisclosureButton>
          <DisclosurePanel>
            <pre>{JSON.stringify(touched.founders, null, 2)}</pre>
          </DisclosurePanel>
        </Disclosure>
      </DebugRender>
    </div>
  );
}

const FoundersFieldArray: FormField = {
  fieldComponent: <FoundersFields />,
  validation: validationSchema,
};

export default FoundersFieldArray;
