import AuthService from '../services/AuthService';
import Logger from '../services/logger';
import { getBaseApiUrl } from '../util/urls';
import { ErrorMessages } from '/../libs/shared-types/src/constants/ErrorCodes';
import { APIResponse } from '/../libs/shared-types/src/types/view/APIResponse';

export type ContentType = 'application/pdf' | 'text/csv' | 'text/plain';

const BASE_API_URL = getBaseApiUrl();

const BASIC_JSON_HEADERS = [
  ['Accept', 'application/json'],
  ['Content-Type', 'application/json'],
  ['Sec-Fetch-Mode', 'cors'],
  ['Sec-Fetch-Site', 'cross-site'],
];

class API {
  /**
   * Sends GET request to and endpoint
   * Returns promise with parsed response or an HTML element for the error
   * @param url API endpoint
   * @param authFlag Send Authorization Header
   */
  static get<T = any>(url: string, authFlag = true) {
    const options = { method: 'GET' };
    return API.fetch<T>(`${BASE_API_URL}${url}`, options, authFlag);
  }

  /**
   * Sends POST request to and endpoint
   * Returns promise with parsed response or an HTML element for the error
   * @param url URL for the API request.
   * @param data Any data you want to pass to the API.
   * @param authFlag Send Authorization Header
   */
  static post<T = any>(url: string, payload: any, authFlag = true) {
    const options = {
      method: 'POST',
      body: JSON.stringify({ data: payload }),
    };

    return API.fetch<T>(`${BASE_API_URL}${url}`, options, authFlag);
  }

  /**
   * Sends GET request to get a PDF
   * @param url The API endpoint
   */
  static getPdf(url: string) {
    return API.getFile(url, 'application/pdf', false);
  }

  static async getTxt(url: string) {
    return await API.getFile(url, 'text/plain', false);
  }

  /**
   * Sends GET request to get a CSV and download it
   * @param url The API endpoint
   */
  static async getCsv(url: string) {
    const response = await API.getFile(
      `${BASE_API_URL}${url}`,
      'text/csv',
      true,
    );
    if (!response.ok) {
      return;
    }
    return response.blob();
  }

  /**
   * Sends a request to an endpoint that is expected to return a file
   * @param url API endpoint
   * @param contentType The MIME type of file you are fetching
   * @param isUsingAuth If true, use the flowlie auth headers
   */
  static async getFile(
    url: string,
    contentType: ContentType,
    isUsingAuth: boolean,
  ) {
    const options = { method: 'GET', responseType: 'blob' };
    const headers: any = [
      ['Sec-Fetch-Mode', 'cors'],
      ['Sec-Fetch-Site', 'cross-site'],
      ['Accept', contentType],
      ['Content-Type', { fileType: contentType }],
    ];

    if (isUsingAuth) {
      headers.push([
        'x-flowlie-auth',
        `Bearer ${AuthService.getFlowlieToken()}`,
      ]);
      headers.push(['Authorization', `${await AuthService.getAWSToken()}`]);
    }

    return fetch(`${url}`, {
      headers,
      ...options,
    });
  }

  /**
   * Sends PUT request to and endpoint
   * Returns promise with parsed response or an HTML element for the error
   * @param url API endpoint
   * @param data Any data you want to pass to the API.
   * @param authFlag Send Authorization Header
   */
  static put<T = any>(url: string, payload: any, authFlag = true) {
    const options = {
      method: 'PUT',
      body: JSON.stringify({ data: payload }),
    };

    return API.fetch<T>(`${BASE_API_URL}${url}`, options, authFlag);
  }

  /**
   * Sends DELETE request to and endpoint
   * Returns promise with parsed response or an HTML element for the error
   * @param url API endpoint
   * @param authFlag Send Authorization Header
   */
  static delete<T = any>(url: string, authFlag = true) {
    const options = { method: 'DELETE' };

    return API.fetch<T>(`${BASE_API_URL}${url}`, options, authFlag);
  }

  static putFile(url: string, file: File): Promise<Response> {
    const options = {
      method: 'PUT',
      body: file,
    };
    const headers: any = [
      ['Sec-Fetch-Mode', 'cors'],
      ['Sec-Fetch-Site', 'cross-site'],
      ['Content-Type', file.type],
    ];
    return fetch(`${url}`, {
      headers,
      ...options,
    });
  }

  /**
   * @param url URL for the API request.
   * @param data Any data you want to pass to the API.
   * @param authFlag Send Authorization Header
   */
  static async postFile(
    url: string,
    payload: any,
    authFlag = true,
  ): Promise<any> {
    const options = {
      method: 'POST',
      body: payload,
    };
    if (!authFlag) {
      return fetch(`${BASE_API_URL}${url}`, options).then((response) => {
        if (!response.ok) {
          return response
            .json()
            .then((blob: APIResponse) => Promise.reject(blob.msg));
        }
        return response
          .json()
          .then((blob: APIResponse) => Promise.resolve(blob.payload));
      });
    }
    const headers: any = [
      ['Sec-Fetch-Mode', 'cors'],
      ['Sec-Fetch-Site', 'cross-site'],
      ['Authorization', `${await AuthService.getAWSToken()}`],
      ['x-flowlie-auth', `Bearer ${AuthService.getFlowlieToken()}`],
    ];

    return fetch(`${BASE_API_URL}${url}`, {
      headers,
      ...options,
    }).then((response) => {
      if (!response.ok) {
        return response
          .json()
          .then((blob: APIResponse) => Promise.reject(blob.msg));
      }
      return response
        .json()
        .then((blob: APIResponse) => Promise.resolve(blob.payload));
    });
  }

  /**
   * Converts all dates in string format to date objects within
   * a given object
   * @param data Object to convert string dates inside of
   * @returns Same input object but with string dates
   * converted to Date objects
   */
  static deserializeDates(data: any): Promise<any> {
    const dateFormat = /^-?\d+-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    function hydrator(key: string, value: any) {
      if (typeof value === 'string' && dateFormat.test(value)) {
        return new Date(value);
      }

      return value;
    }

    const dataAsString = JSON.stringify(data);
    const hydratedData = JSON.parse(dataAsString, hydrator);

    return Promise.resolve(hydratedData);
  }

  /**
   * Generic fetch method
   * Automatically validates responses, etc.
   * @param url API endpoint
   * @param options HTTP header options
   * @param authFlag Send Authorization header to API
   */
  static async fetch<T = any>(
    url: string,
    options: Record<string, unknown>,
    authFlag: boolean,
  ): Promise<T> {
    const headers: any = authFlag
      ? [
          ...BASIC_JSON_HEADERS,
          ['x-flowlie-auth', `Bearer ${AuthService.getFlowlieToken()}`],
          ['x-flowlie-session-auth', `Bearer ${AuthService.getSessionToken()}`],
          ['Authorization', `${await AuthService.getAWSToken()}`],
        ]
      : [...BASIC_JSON_HEADERS];

    const response = await fetch(url, {
      headers,
      ...options,
    });

    if (!response.ok) {
      const jsonData = (await response.json()) as APIResponse;
      throw Error(
        jsonData.errorCode ? ErrorMessages[jsonData.errorCode] : jsonData.msg,
      );
    }

    return response
      .json()
      .then((blob: APIResponse) => API.deserializeDates(blob.payload))
      .then((blob: any) => Promise.resolve(blob));
  }
}

export default API;
