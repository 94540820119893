import React, { FormEvent, useEffect, useRef, useState } from 'react';
import TextEditor, { TextEditorCallBackProps } from '../TextEditor/TextEditor';
import { ConnectionNoteView } from '../../types/view/ConnectionNoteView';
import { debounce } from '/src/util/debounce';
import {
  LIST_NAME_LENGTH_MAX,
  NOTE_CONTENT_LENGTH_MAX,
} from '../../../../libs/shared-types/src/constants/TextLengthRanges';
import { getLexicalContentLength } from '/src/services/lexical/getContentLength';
import Toast from '../notifications/Toast';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';

export const DEFAULT_NOTE_TITLE = 'Untitled Note';
const SAVE_NOTE_DEBOUNCE_DELAY_MS = 1000;

export interface ConnectionNoteEditorProps {
  connectionNote: ConnectionNoteView | null;
  isLoading: boolean;
  onChange: (props: TextEditorCallBackProps) => void;
  id?: string;
}
function ConnectionNoteEditor({
  connectionNote,
  isLoading,
  onChange,
}: ConnectionNoteEditorProps) {
  const inputTitleRef = useRef<HTMLInputElement>(null);
  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const handleChange = async ({
    id,
    content,
    isEmptyContent,
    childrenSize,
    title,
  }: TextEditorCallBackProps) => {
    const isValidContentLength =
      getLexicalContentLength(JSON.stringify(content.root)) <=
      NOTE_CONTENT_LENGTH_MAX;

    // show toast error message if content is longer than allowed
    if (!isValidContentLength) {
      setToastConfig({
        title: 'Cannot save note',
        isError: true,
        message: `The text content is longer than ${NOTE_CONTENT_LENGTH_MAX} characters. Please shorten it.`,
      });
      return;
    }

    // show toast error message if title is longer than allowed
    if (title.length > LIST_NAME_LENGTH_MAX) {
      setToastConfig({
        title: 'Cannot save note',
        isError: true,
        message: `The title is longer than ${LIST_NAME_LENGTH_MAX} characters. Please shorten it.`,
      });
      return;
    }

    // execute onChange callback only if it is a valid connection note
    if (onChange) {
      onChange({
        id,
        content,
        title,
        isEmptyContent,
        childrenSize,
      });
    }
  };

  const debouncedHandleConnectionNoteChange = debounce(
    handleChange,
    SAVE_NOTE_DEBOUNCE_DELAY_MS,
  );

  useEffect(() => {
    if (inputTitleRef.current) {
      inputTitleRef.current.value = connectionNote?.title || DEFAULT_NOTE_TITLE;
    }
  }, [connectionNote?._id]);

  return (
    <>
      <form
        className="relative flex h-max flex-1 flex-col"
        onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
      >
        <input
          type="text"
          ref={inputTitleRef}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
            // trigger autosave only if the note has content
            if (connectionNote?.content) {
              debouncedHandleConnectionNoteChange({
                id: connectionNote?._id,
                content: {
                  root: connectionNote.content,
                },
                title: event.target.value || DEFAULT_NOTE_TITLE,
              });
            }
          }}
          className="bold sticky top-0 z-10 my-1 w-full rounded-md border border-gray-200 text-lg outline-none hover:bg-gray-50 focus:border-gray-300 focus:ring-0 focus:ring-offset-0"
        />
        <TextEditor
          key={`${connectionNote?._id}-editor`}
          isLoading={isLoading}
          id={connectionNote?._id}
          initialContentState={connectionNote?.content}
          onChange={({ content, id, childrenSize, isEmptyContent }) => {
            debouncedHandleConnectionNoteChange({
              content,
              id,
              childrenSize,
              isEmptyContent,
              title: inputTitleRef.current?.value || DEFAULT_NOTE_TITLE,
            });
          }}
        />
      </form>
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? toastConfig.title ?? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </>
  );
}

export default ConnectionNoteEditor;
