import React from 'react';
import TagCell from './TagCell';
import InvestorTypes from '../../../../../libs/shared-types/src/constants/InvestorTypes';
import { investorTypeColorLookup } from '/src/util/colorLookup';

interface InvestorTypeTagCellProps {
  investorType: InvestorTypes;
}

function InvestorTypeTagCell({ investorType }: InvestorTypeTagCellProps) {
  const colors = investorTypeColorLookup[investorType];

  return (
    <TagCell
      tag={investorType}
      type="comparison"
      tagStyles={{
        [investorType]: `${colors.bgColor} ${colors.textColor}`,
      }}
    />
  );
}

export default InvestorTypeTagCell;
