import React, { useState } from 'react';
import { AnyObject, ObjectSchema } from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import { PROFILE_PUBLIC, PROFILE_SAVED } from '/src/constants/SuccessMessages';
import API from '/src/middleware/API';
import DebugRender from '/src/components/utility/DebugRender';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import InvestorFieldNames from '/src/enums/InvestorFieldNames';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import FormCard, { FormSection } from '../FormCard';
import INVESTOR_FIELD_MAPPING from './InvestorFormFieldMapping';
import Toast from '../notifications/Toast';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import emptyInvestor from './EmptyInvestor';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import { useNavigate } from 'react-router-dom';
import { cleanInvestorData } from './InvestorFormValuesCleaner';
import { updateInvestor } from '/src/services/UpdateInvestor';
import { PrivateInvestor } from '/../libs/shared-types/src/types/view/investor/PrivateInvestor';
import { InvestorGet } from '/../libs/shared-types/src/constants/ApiRoutes';
import { INVESTOR_DETAIL } from '/src/constants/Routes';
import { formatDatePickerInput } from '/src/util/formatting/dates';

const sections: FormSection[] = [
  {
    title: 'About',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ProfilePic],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Type],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Firm],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Role],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.FirmJoinedOn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvAuthority],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Location],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.TaxState],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IsAccredited],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpInvestingYears],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ProfessionalAccreditations],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.UniversityAffiliations],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.HighestEducationLevel],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Nationalities],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IntroBio],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Pronouns],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpFunctional],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.ExpIndustry],
    ],
  },
  {
    title: 'Investment Thesis',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvStages],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvStagesFollowOn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvProductStage],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.CheckRangeMin],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.CheckRangeMax],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvProductCategories],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvIndustries],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.AntiInvIndustries],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvBusinessModels],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvGeographies],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvTypes],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InvImpacts],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.LeadsRounds],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.TakesBoardSeats],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.HasInitialOwnershipTarget],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InitialOwnershipTargetMin],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.InitialOwnershipTargetMax],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.YearlyAvgInvCount],
    ],
  },
  {
    title: 'Contact Preferences & Social',
    fields: [
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.IsOpenToColdInbound],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.PreferenceContact],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.PreferenceFollowUp],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Email],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Website],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.LinkedIn],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Twitter],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.AngelList],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Medium],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Substack],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.OtherUrl],
      INVESTOR_FIELD_MAPPING[InvestorFieldNames.Phone],
    ],
  },
];

function InvestorEditProfileForm(): JSX.Element {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({});
  const [showSaveToast, setShowSaveToast] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [submitCount, setSubmitCount] = useState(0);

  const [validation, setValidation] =
    useState<ObjectSchema<any, AnyObject, any, any>>();

  const toggleShowSaveToast = () => setShowSaveToast(!showSaveToast);

  async function fetchInvestor() {
    setIsLoading(true);
    try {
      const data = await API.get<PrivateInvestor>(InvestorGet.buildEndpoint());
      const investor = {
        ...emptyInvestor,
        ...data,
        [InvestorFieldNames.FirmJoinedOn]: data.firmJoinedOn
          ? formatDatePickerInput(data.firmJoinedOn)
          : '',
        profilePicMustValidate: false,
      };
      setInitialValues(investor);
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onSubmit(
    values: any,
    { setSubmitting }: FormikHelpers<any>,
  ): Promise<void> {
    setSubmitting(true);
    // YOU MUST CHECK IF THE DATA BEING SUBMITTED IS VALID
    // For example, certain fields are mutually exclusive
    const cleanValues = cleanInvestorData(values);

    try {
      await updateInvestor(cleanValues);
      setShowSaveToast(true);
      setTimeout(() => setShowSaveToast(false), 3000);
    } catch (error) {
      Logger.error(error);
    } finally {
      // After submitting, refresh the form
      setSubmitCount(submitCount + 1);
      setSubmitting(false);
    }
  }

  React.useEffect(() => {
    // When the page is rendered we must set the initial values
    // we must fetch the Investor data
    fetchInvestor();
    setValidation(
      sections
        .map((section) => section.fields)
        .flat()
        .map((field) => field.validation)
        .reduce((merged, schema) => merged.concat(schema)),
    );
  }, [submitCount]);

  if (isLoading && submitCount === 0) {
    return <PageLoadingSpinner />;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      validationSchema={validation}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ values, errors, resetForm, isSubmitting, isValid, dirty }) => (
        <Form className="flex flex-col sm:max-w-3xl">
          <WarnBeforeExit isEnabled={dirty} />
          <header className="sticky top-0 z-20 bg-gray-100 pb-2 dark:bg-transparent dark:backdrop-blur">
            <div className="relative flex max-w-full items-center justify-between">
              <h3 className="page-title">Edit Profile</h3>
              <div className="space-x-3">
                <button
                  type="button"
                  onClick={() => navigate(`${INVESTOR_DETAIL}/${values._id}`)}
                  className="app-button--neutral"
                  disabled={dirty}
                  title={dirty ? 'Save or cancel your changes first' : ''}
                >
                  Preview Profile
                </button>
                <button
                  type="button"
                  onClick={() => resetForm()}
                  className="app-button--neutral"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--primary"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </header>

          <section className="space-y-4">
            {sections.map((section) => (
              <FormCard
                key={section.title}
                title={section.title}
                fields={section.fields}
              />
            ))}
          </section>

          <div className="z-20">
            <Toast
              isShown={showSaveToast}
              onClose={toggleShowSaveToast}
              title={PROFILE_SAVED}
              text={PROFILE_PUBLIC}
            />
          </div>

          {/* Debug Formik Values */}
          <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
            <Disclosure>
              <DisclosureButton className="app-button--neutral">
                <pre>Can Submit Form:</pre>
              </DisclosureButton>
              <DisclosurePanel>
                <pre>Cannot submit if any of the following is true:</pre>
                <pre>{`is not Valid: ${!isValid}`}</pre>
                <pre>{`isSubmitting: ${isSubmitting}`}</pre>
                <pre>{`not modified yet: ${!dirty}`}</pre>
              </DisclosurePanel>
            </Disclosure>

            <Disclosure>
              <DisclosureButton className="app-button--neutral">
                <pre>Formik Values:</pre>
              </DisclosureButton>
              <DisclosurePanel>
                <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
              </DisclosurePanel>
            </Disclosure>

            <Disclosure>
              <DisclosureButton className="app-button--neutral">
                <pre>Formik File Upload:</pre>
              </DisclosureButton>
              <DisclosurePanel>
                <pre className="mb-6">
                  {values.profilePic
                    ? JSON.stringify(
                        {
                          fileName: values.profilePic.name,
                          type: values.profilePic.type,
                          size: `${values.profilePic.size} bytes`,
                        },
                        null,
                        2,
                      )
                    : null}
                </pre>
              </DisclosurePanel>
            </Disclosure>

            <Disclosure>
              <DisclosureButton className="app-button--neutral">
                <pre>Formik Errors:</pre>
              </DisclosureButton>
              <DisclosurePanel>
                <pre className="mb-24">{JSON.stringify(errors, null, 2)}</pre>
              </DisclosurePanel>
            </Disclosure>
          </DebugRender>
        </Form>
      )}
    </Formik>
  );
}

export default InvestorEditProfileForm;
