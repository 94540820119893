import React, { Fragment, useEffect, useState } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { getPluralSuffix } from '/src/util/formatting/numbers';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/20/solid';
import { range } from '/src/util/range';
import CurrencyInput from 'react-currency-input-field';

export interface TablePaginationProps {
  resultCount: number;
  perPage: number;
  page: number;
  pageCount: number;
  onPageChange?: (newPage: number) => void;
  onPerPageChange?: (newPerPage: number) => void;
}

function TablePagination({
  resultCount,
  pageCount,
  perPage,
  page,
  onPerPageChange,
  onPageChange,
}: TablePaginationProps) {
  const [pageInputDisplayValue, setPageInputDisplayValue] = useState<
    number | null
  >(page);

  const handlePerPageChange = (newPerPage: number) => {
    if (newPerPage !== page && onPerPageChange) {
      onPerPageChange(newPerPage);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage !== page && onPageChange) {
      onPageChange(newPage);
    }
  };

  const nextPage = () => {
    const newPage =
      pageInputDisplayValue !== null ? pageInputDisplayValue + 1 : page + 1;
    handlePageChange(newPage);
  };

  const previousPage = () => {
    const newPage =
      pageInputDisplayValue !== null ? pageInputDisplayValue - 1 : page - 1;
    handlePageChange(newPage);
  };

  useEffect(() => {
    // Must refresh the page input when page or totalPages change
    setPageInputDisplayValue(page);
  }, [page, pageCount]);

  return (
    <nav
      className="flex items-center justify-between border-t border-gray-200 bg-gray-50 pb-1 pl-3 pr-2 pt-2 dark:border-gray-800 dark:bg-gray-900"
      aria-label="Pagination"
    >
      <div className="flex flex-1 items-center justify-start">
        <div className="text-xs dark:text-gray-400">
          <span className="font-medium">{resultCount}</span>
          &nbsp;result{getPluralSuffix(resultCount)},&nbsp;
        </div>

        <Listbox value={perPage} onChange={handlePerPageChange}>
          <div className="relative cursor-pointer">
            <ListboxButton className="relative mr-5 w-full cursor-default rounded-md px-2.5 py-1.5 text-left text-xs hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300">
              {perPage} per page
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              as="div"
              transition
              className={`data-[closed]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in`}
            >
              <ListboxOptions className="absolute top-[-13.9rem] z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {range(0, 50, 10)
                  .concat(100)
                  .reverse()
                  .map(
                    (perPageSize) =>
                      perPageSize !== 0 && (
                        <ListboxOption
                          key={perPageSize}
                          className={({ focus }) =>
                            `relative cursor-default select-none py-2 pl-8 pr-4 ${
                              focus
                                ? 'bg-blue-100 text-blue-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={perPageSize}
                        >
                          {perPageSize}
                        </ListboxOption>
                      ),
                  )}
              </ListboxOptions>
            </Transition>
          </div>
        </Listbox>
      </div>
      <div className="flex flex-1 items-center justify-end">
        <button
          onClick={() => handlePageChange(1)}
          type="button"
          disabled={page === 1}
          className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          title="First page"
        >
          <span className="sr-only">First</span>
          <ChevronDoubleLeftIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
        <button
          onClick={previousPage}
          type="button"
          disabled={page <= 1 || Number(pageInputDisplayValue) <= 1}
          className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          title="Previous page"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>

        <label className="mx-2 flex items-center text-xs">
          <span>Page&nbsp;</span>
          <CurrencyInput
            className="m-0 h-6 w-10 rounded border border-gray-300 py-0 pl-2 text-xs"
            value={pageInputDisplayValue !== null ? pageInputDisplayValue : ''}
            // Disabling step because the arrow buttons
            // allow to spam the change Event even after I tried debounce
            // step={1}
            allowNegativeValue={false}
            allowDecimals={false}
            disableAbbreviations
            onValueChange={(value) => {
              if (value === null || value === undefined || value === '') {
                setPageInputDisplayValue(null);
                return;
              }

              // The page value cannot be greater than TotalPages
              const numericalValue = Math.min(Number(value), pageCount);
              if (!isNaN(numericalValue) && numericalValue !== 0) {
                setPageInputDisplayValue(numericalValue);
                handlePageChange(numericalValue);
              }
            }}
          />
          <span>&nbsp;of {pageCount}</span>
        </label>
        <button
          onClick={nextPage}
          type="button"
          disabled={page >= pageCount}
          className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          title="Next page"
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
        <button
          onClick={() => handlePageChange(pageCount)}
          type="button"
          disabled={page === pageCount}
          className="relative mx-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          title="Last page"
        >
          <span className="sr-only">Last</span>
          <ChevronDoubleRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
}

export default TablePagination;
