import React, { useContext, useEffect, useState } from 'react';
import {
  ChatBubbleLeftRightIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { Sentiment } from '/../libs/shared-types/src/constants/Sentiment';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import EmptyState from '/src/components/notifications/EmptyState';
import DateString from '/src/components/utility/DateString';
import DropdownButton from '/src/components/DropdownButton';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem } from '@headlessui/react';
import { joinClassNames } from '/src/util/formatting/strings';
import { STARTUP_MEETING_DETAIL_ROUTE } from '/src/constants/Routes';
import { cn } from '/src/util/cn';
import AddOrUpdateInvestorMeetingDialog from '/src/routes/startup/InvestorMeeting/AddOrUpdateInvestorMeeting';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorMeetingView } from '/../libs/shared-types/src/types/view/InvestorMeetingView';
import { StartupInvestorMeetings } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { formatDate, howLongBetween } from '/src/util/formatting/dates';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { deleteInvestorMeetingById } from '/src/services/startupService';
import SentimentTag from '/src/routes/startup/MeetingDetail/MeetingSentimentTag';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';

export interface InvestorDetailMeetingsTabProps {
  investorId: string;
  investorDataType: InvestorDataType;
  investorName: string;
}

function InvestorDetailMeetingsTab({
  investorDataType,
  investorId,
  investorName,
}: InvestorDetailMeetingsTabProps) {
  const { subscriptionTier, id: startupId } = useContext(
    AccountMetadataContext,
  );
  const [meetings, setMeetings] = useState<InvestorMeetingView[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfig, setModalConfig] = useState<
    | {
        type: 'addInvestorMeeting' | '';
        isModalOpen: boolean;
        investorMeeting?: never;
      }
    | {
        type: 'editInvestorMeeting' | 'deleteInvestorMeeting';
        isModalOpen: boolean;
        investorMeeting: InvestorMeetingView;
      }
  >({
    type: '',
    isModalOpen: false,
  });

  const [refetch, setRefetch] = useState(false);

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
  }

  function refetchInvestorMeetings() {
    setRefetch((prev) => !prev);
  }

  const handleDeleteInvestorMeeting = async (investorMeetingId: string) => {
    if (investorMeetingId) {
      const { isSuccess } = await deleteInvestorMeetingById(investorMeetingId);

      if (isSuccess) {
        closeModal();
        refetchInvestorMeetings();
      }
    }
  };

  async function fetchData() {
    setIsLoading(true);

    try {
      const meetingsData = await API.get<InvestorMeetingView[]>(
        StartupInvestorMeetings.buildEndpoint('', {
          investorId,
          investorDataType,
        }),
      );
      setMeetings(meetingsData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [refetch]);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Track & analyze all investor meetings in one place
        </p>
        <ul className="mt-4 list-inside list-disc">
          <li>Keep track of all investor meetings and their notes</li>
          <li>
            Upload the meeting transcript to analyze it and extract important
            details
          </li>
          <li>
            Leverage the AI-generated next steps to ensure the right follow-ups
            each time
          </li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock full meeting tracking
          and AI transcript analysis
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_detail_meetings_tab"
            text="Track & analyze your meetings"
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <PageLoadingSpinner message="Loading investor meetings...🤝" />;
  }

  return (
    <>
      {meetings.length === 0 && (
        <EmptyState
          title="You have not yet tracked any meeting with this investor"
          subTitle="Add a meeting and its transcript to begin analyzing it and keep track of next steps"
          icon={
            <ChatBubbleLeftRightIcon className="mx-auto h-12 w-12 text-gray-400" />
          }
          actionButton={
            <button
              type="button"
              className="app-button--primary"
              onClick={() =>
                setModalConfig({
                  type: 'addInvestorMeeting',
                  isModalOpen: true,
                })
              }
            >
              Add Meeting
            </button>
          }
        />
      )}

      {meetings.length > 0 && (
        <>
          <div className="mb-3 flex flex-row items-center justify-end">
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                setModalConfig({
                  type: 'addInvestorMeeting',
                  isModalOpen: true,
                });
              }}
            >
              <PlusIcon className="mr-2 h-5 w-5" />
              New Meeting
            </button>
          </div>
          <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
            <ul className="flex flex-col divide-y divide-gray-200 px-0 py-1 dark:divide-gray-700">
              {meetings.length === 0 && (
                <div className="px-5">
                  <li className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <p className="text-gray-700">No lists found</p>
                      </div>
                    </div>
                  </li>
                </div>
              )}
              {meetings.map((meeting) => (
                <div key={meeting._id} className="hover:bg-gray-100">
                  <li className="flex items-center justify-between pr-2">
                    <Link
                      className="w-full px-5 py-5"
                      to={`${STARTUP_MEETING_DETAIL_ROUTE}/${meeting._id}`}
                    >
                      <div className="flex flex-wrap items-center gap-x-3">
                        <p className="line-clamp-1 max-w-80 font-semibold leading-6 text-gray-900">
                          {formatDate(meeting.meetingOccurredOn)} (
                          {howLongBetween(meeting.meetingOccurredOn)})
                        </p>

                        <span
                          className={cn(
                            'rounded-full px-2.5 py-0.5 text-sm font-medium md:inline-block',
                            'bg-gray-100 text-gray-800',
                          )}
                        >
                          {meeting.type}
                        </span>

                        {meeting.tasks && (
                          <span className="text-sm text-gray-800">
                            {meeting.tasks.length > 0
                              ? `Tasks (${meeting.tasks.filter((x) => x.completedOn.getTime() !== MIN_DATE.getTime()).length} / ${meeting.tasks.length} completed)`
                              : 'No Tasks'}
                          </span>
                        )}

                        {meeting.sentiment && (
                          <SentimentTag sentiment={meeting.sentiment} />
                        )}
                      </div>

                      <div className="mt-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created <DateString date={meeting.createdOn} />
                        </p>
                        {meeting.startupId !== '' && (
                          <>
                            <svg
                              viewBox="0 0 2 2"
                              className="h-0.5 w-0.5 fill-current"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="ml-2 whitespace-nowrap">
                              Updated <DateString date={meeting.updatedOn} />
                            </p>
                          </>
                        )}
                      </div>

                      {meeting.summary && (
                        <p className="mt-2 text-left text-xs text-gray-600">
                          {meeting.summary}
                        </p>
                      )}
                    </Link>

                    <DropdownButton
                      label={'Manage Investor'}
                      button={
                        <MenuButton
                          className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      }
                    >
                      <MenuItem>
                        {({ focus }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalConfig({
                                type: 'editInvestorMeeting',
                                isModalOpen: true,
                                investorMeeting: meeting,
                              });
                            }}
                            className={joinClassNames(
                              focus
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex w-full items-center px-4 py-2 text-sm',
                            )}
                            title="Edit List"
                          >
                            <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Edit
                          </button>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ focus }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalConfig({
                                type: 'deleteInvestorMeeting',
                                isModalOpen: true,
                                investorMeeting: meeting,
                              });
                            }}
                            className={joinClassNames(
                              focus
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex w-full items-center px-4 py-2 text-sm',
                            )}
                            title="Delete List"
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Delete
                          </button>
                        )}
                      </MenuItem>
                    </DropdownButton>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </>
      )}

      <ModalWrapper open={modalConfig.isModalOpen} onClose={closeModal}>
        {startupId &&
          (modalConfig.type === 'addInvestorMeeting' ||
            modalConfig.type === 'editInvestorMeeting') && (
            <AddOrUpdateInvestorMeetingDialog
              investorDataType={investorDataType}
              investorId={investorId}
              investorName={investorName}
              onCancel={closeModal}
              onSuccess={() => {
                closeModal();
                refetchInvestorMeetings();
              }}
              // only provided when updating an investor meeting
              investorMeeting={modalConfig.investorMeeting}
            />
          )}

        {modalConfig.type === 'deleteInvestorMeeting' && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() =>
              handleDeleteInvestorMeeting(modalConfig.investorMeeting._id)
            }
            title="Are you sure you want to delete this investor meeting?"
            text="This action cannot be undone."
            primaryAction="Delete Investor Meeting"
            color="red"
          />
        )}
      </ModalWrapper>
    </>
  );
}

export default InvestorDetailMeetingsTab;
