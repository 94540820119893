import React from 'react';
import cn from 'classnames';
import { Sentiment } from '/../libs/shared-types/src/constants/Sentiment';

interface SentimentTagProps {
  sentiment: Sentiment;
}

function SentimentTag({ sentiment }: SentimentTagProps): JSX.Element {
  return (
    <span
      className={cn(
        'rounded-full px-2.5 py-0.5 text-sm font-medium md:inline-block',
        sentiment === Sentiment.Positive
          ? 'bg-green-100 text-green-800'
          : sentiment === Sentiment.Negative
            ? 'bg-red-100 text-red-800'
            : 'bg-yellow-100 text-yellow-800',
      )}
    >
      {sentiment} sentiment
    </span>
  );
}

export default SentimentTag;
