import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import EmptyState from '/src/components/notifications/EmptyState';
import { FolderIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import Alert from '/src/components/notifications/Alert';
import { Formik, Form, Field, FormikHelpers, FormikValues } from 'formik';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import websiteSchema from '/src/constants/validation/websiteSchema';
import FormikInput from '/src/components/inputs/FormikInput';
import Toast from '/src/components/notifications/Toast';
import { DATA_ROOM_PASSWORD_LENGTH_MIN } from '/../libs/shared-types/src/constants/TextLengthRanges';
import { deleteDataRoomLink, updateDataRoomLink } from '/src/services/DataRoom';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import Logger from '/src/services/logger';
import { updateUrlPrefix } from '/src/util/forms';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { DataRoomView } from '/../libs/shared-types/src/types/view/APIResponse';
import { Link } from 'react-router-dom';
import {
  STARTUP_ACCESS_MANAGER_ROUTE,
  STARTUP_PROFILE_PREVIEW_ROUTE,
} from '/src/constants/Routes';

enum FieldNames {
  Url = 'url',
  Password = 'password',
  IsChangingPassword = 'isChangingPassword',
}

const emptyInitialValues = {
  [FieldNames.Url]: '',
  [FieldNames.Password]: '',
  [FieldNames.IsChangingPassword]: false,
};

const validationSchema = yup.object({
  [FieldNames.Url]: websiteSchema(true),
  [FieldNames.Password]: yup.string().when(FieldNames.IsChangingPassword, {
    is: true,
    then: (schema) =>
      schema
        .min(
          DATA_ROOM_PASSWORD_LENGTH_MIN,
          `Must be at least ${DATA_ROOM_PASSWORD_LENGTH_MIN} characters`,
        )
        .required('Required'),
  }),
});

interface DataRoomLinkFormProps {
  dataRoomView: DataRoomView;
  refreshDataRoom: () => Promise<void>;
}

function DataRoomLinkForm({
  dataRoomView,
  refreshDataRoom,
}: DataRoomLinkFormProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasDataRoomAccess = hasFeatureAccess(
    StartupPaidFeatures.DataRoom,
    subscriptionTier,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasLink, setHasLink] = useState(
    dataRoomView.dataRoom.link !== undefined,
  );
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  async function onSubmit(
    values: FormikValues,
    { setSubmitting, resetForm }: FormikHelpers<any>,
  ) {
    try {
      setSubmitting(true);
      const cleanUrl = updateUrlPrefix(values, [FieldNames.Url])[
        FieldNames.Url
      ];
      await updateDataRoomLink(cleanUrl, values[FieldNames.Password]);
      await refreshDataRoom();

      resetForm();
      setShowSaveAlert(true);
      setTimeout(() => setShowSaveAlert(false), 3000);
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  async function handleRemoveLink() {
    try {
      await deleteDataRoomLink();
      await refreshDataRoom();
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsModalOpen(false);
    }
  }

  useEffect(() => {
    setHasLink(dataRoomView.dataRoom.link !== undefined);
    setInitialValues({
      ...emptyInitialValues,
      [FieldNames.Url]: dataRoomView.dataRoom.link?.url ?? '',
    });
  }, [dataRoomView]);

  return (
    <section>
      {!hasLink && (
        <EmptyState
          title="You have not set a link to your data room"
          subTitle="You can add your data room link and secure it with a password to allow investors to access it through your Company One-Pager"
          icon={<FolderIcon className="mx-auto h-12 w-12 text-gray-400" />}
          actionButton={
            hasDataRoomAccess ? (
              <button
                type="button"
                className="app-button--primary"
                onClick={() => {
                  setInitialValues({
                    ...initialValues,
                    [FieldNames.IsChangingPassword]: true,
                  });
                  setHasLink(true);
                }}
              >
                <PlusIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5"
                  aria-hidden="true"
                />
                Add Data Room Link
              </button>
            ) : (
              <div className="mx-auto w-max">
                <SubscriptionCTAPill
                  id="cta_data_room_link"
                  text="Add a Data Room link to your company one-pager"
                  tooltipText="Upgrade to Pro to add a password-protected Data Room link to your company one-pager"
                />
              </div>
            )
          }
        />
      )}

      {hasLink && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ values, resetForm, isSubmitting, dirty, setFieldValue }) => (
            <Form>
              <div className="joyride-flowLinkManager mb-4 bg-white p-0.5 shadow sm:rounded-md 2xl:mb-0 2xl:min-w-[700px]">
                <header className="rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                  <div className="items-center justify-between sm:flex sm:flex-row">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-gray-900">
                        Your Password-protected Data Room Link
                      </h3>
                    </div>
                    <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                      {hasDataRoomAccess && (
                        <>
                          <button
                            type="button"
                            onClick={() => resetForm()}
                            className="app-button--neutral justify-center truncate"
                            disabled={isSubmitting}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="app-button--green justify-center truncate"
                            disabled={!dirty || isSubmitting}
                          >
                            Save
                            {isSubmitting && (
                              <div className="ml-2">
                                <LoadingSpinner color="white" />
                              </div>
                            )}
                          </button>
                        </>
                      )}

                      {!hasDataRoomAccess && (
                        <div className="">
                          <SubscriptionCTAPill
                            id="cta_data_room_link"
                            text="Unlock Data Room link"
                            tooltipText="Upgrade to Pro to manage your Data Room link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </header>

                <section className="p-5">
                  <div className="items-end sm:flex sm:flex-row">
                    <div className="flex flex-grow rounded-md">
                      <div className="relative flex w-full flex-grow items-stretch focus-within:z-10">
                        <Field
                          component={FormikInput}
                          label="Data Room Link"
                          secondaryLabel="Generate a sharing link in your data room provider and paste it here"
                          tooltip="You can host a data room for free on Notion, Google
                        Drive, Box, or Dropbox, or in paid secure VDRs such as
                        Datasite, Fordata, DealRoom, or FirmRoom"
                          name={FieldNames.Url}
                          type="text"
                          placeholder="https://www.example.com"
                          disabled={!hasDataRoomAccess}
                        />
                      </div>
                    </div>

                    <div className="my-4 flex flex-1 flex-col sm:my-4 sm:ml-4 sm:shrink-0 sm:items-end">
                      <button
                        onClick={() => setIsModalOpen(true)}
                        type="button"
                        className="app-button--red justify-center truncate"
                        disabled={
                          isSubmitting || !dataRoomView.dataRoom?.link?.url
                        }
                      >
                        Remove Link
                      </button>
                    </div>
                  </div>

                  {values[FieldNames.IsChangingPassword] ? (
                    <div className="sm:max-w-[320px]">
                      <Field
                        component={FormikInput}
                        label="Data Room Password"
                        secondaryLabel="Do not use your Flowlie account password"
                        tooltip="Investors will be asked this password to access your data room link"
                        name={FieldNames.Password}
                        type="password"
                        disabled={!hasDataRoomAccess}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col sm:flex-row">
                      <button
                        className="app-button--primary mt-2 justify-center truncate"
                        onClick={() =>
                          setFieldValue(FieldNames.IsChangingPassword, true)
                        }
                        disabled={!hasDataRoomAccess}
                      >
                        Change Password
                      </button>
                    </div>
                  )}
                </section>

                <div className="max-w-full px-5 pb-5 text-base text-gray-500">
                  <div className="flex flex-col space-y-2">
                    <Alert
                      alertType="Info"
                      canDismiss={false}
                      color="blue"
                      content={
                        <p>
                          Your data room link will be embedded in your{' '}
                          <Link
                            to={STARTUP_PROFILE_PREVIEW_ROUTE}
                            className="hyperlink underline"
                          >
                            Company One-Pager
                          </Link>{' '}
                          and will require investors to enter the password to
                          access it. You will then track who has accessed it in
                          the{' '}
                          <Link
                            to={STARTUP_ACCESS_MANAGER_ROUTE}
                            className="hyperlink underline"
                          >
                            Track Views
                          </Link>{' '}
                          tab.
                        </p>
                      }
                      isShown
                      onClose={() => false}
                    />
                  </div>
                </div>
              </div>

              <div className="z-20">
                <Toast
                  isShown={showSaveAlert}
                  onClose={() => setShowSaveAlert(false)}
                  title={'Data Room Link Updated'}
                  text={'Your data room link has been updated successfully'}
                />
              </div>
              <ModalWrapper
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              >
                <SimpleDialog
                  onCancel={() => setIsModalOpen(false)}
                  onPrimaryAction={() => handleRemoveLink()}
                  title="Do you want to remove the Data Room Link from your Company One-Pager?"
                  text="If any investors have already accessed your data room and saved the link, they may still be able to view it. You should check your Data Room provider to disable access to the link."
                  primaryAction="Confirm"
                  color="red"
                />
              </ModalWrapper>
            </Form>
          )}
        </Formik>
      )}
    </section>
  );
}

export default DataRoomLinkForm;
