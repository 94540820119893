import { Transition } from '@headlessui/react';
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

import { copyToClipboard } from '../util/CopyToClipboard';

interface CopyTextButtonProps {
  text: string;
  className?: string;
  hasTimeout?: boolean;
  timeout?: number;
  icon?: JSX.Element;
  iconAfterCopy?: JSX.Element;
}

function CopyTextButton({
  text,
  className = '',
  hasTimeout = true,
  timeout = 3000,
  icon = <ClipboardDocumentIcon className="h-5 w-5" />,
  iconAfterCopy = <ClipboardDocumentCheckIcon className="h-5 w-5" />,
}: CopyTextButtonProps): JSX.Element {
  const [hasCopied, setHasCopied] = React.useState(false);

  async function copyLink(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    event.preventDefault();
    copyToClipboard(text);
    setHasCopied(true);

    if (!hasTimeout) {
      return;
    }

    window.setTimeout(() => setHasCopied(false), timeout);
  }

  return (
    <button
      title="Copy to clipboard"
      type="button"
      className={className}
      onClick={copyLink}
    >
      <div className="flex items-center">
        {!hasCopied ? icon : iconAfterCopy}
        <Transition
          as="div"
          show={hasCopied}
          transition
          className={`transition ease-in-out data-[closed]:translate-y-6 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-100`}
        >
          <span className="ml-1 rounded bg-white px-1 py-0.5 text-xs text-gray-700 shadow">
            Copied!
          </span>
        </Transition>
      </div>
    </button>
  );
}

export default CopyTextButton;
