import React from 'react';
import * as yup from 'yup';
import { Field, useFormikContext } from 'formik';

import RoundFieldNames from '/src/enums/RoundFieldNames';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormField from '/src/interfaces/FormField';
import isInputShown from '../InputShown';
import { enumToSelectOptions } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { RoundOpenToInvestorType } from '/../libs/shared-types/src/constants/InvestorTypes';
import { getHelperTextOpenToInvestorTypes, roundHelper } from '../RoundHelpers';

function FieldWrapper(): JSX.Element {
  const { values } = useFormikContext<any>();

  const helper = roundHelper.find(
    (x) =>
      x.roundStage === values[RoundFieldNames.RoundStage] &&
      x.isBridge === (values[RoundFieldNames.IsBridge] === true),
  );
  const secondaryLabel = helper ? getHelperTextOpenToInvestorTypes(helper) : '';

  return isInputShown(RoundFieldNames.OpenToInvestorTypes, values) ? (
    <Field
      className="custom-select"
      isDisabled={values[RoundFieldNames.IsEditable] === 'false'}
      component={CustomSelect}
      isClearable
      isMulti
      label="What types of investors are you aiming to get in this round?"
      name={RoundFieldNames.OpenToInvestorTypes}
      options={enumToSelectOptions(RoundOpenToInvestorType)}
      placeholder="Select..."
      tooltip="When you think of your ideal round composition, what types of investors would you like to participate in this round of financing?"
      secondaryLabel={secondaryLabel}
    />
  ) : (
    <></>
  );
}

const OpenToInvestorTypesField: FormField = {
  fieldComponent: <FieldWrapper />,
  validation: yup.object({
    [RoundFieldNames.OpenToInvestorTypes]: yup
      .array()
      .of(yup.string())
      .nullable(),
  }),
};

export default OpenToInvestorTypesField;
