import * as React from 'react';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeNode, CodeHighlightNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import DebugRender from '../utility/DebugRender';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import TextEditorTheme from './themes/TextEditorTheme';
import { EditorState, SerializedEditorState } from 'lexical/LexicalEditorState';
import Logger from '/src/services/logger';
import { cn } from '/src/util/cn';
import { debounce } from '/src/util/debounce';
import {
  $getRoot,
  $isElementNode,
  LexicalEditor,
  SerializedLexicalNode,
} from 'lexical';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { MaxLengthPlugin } from './plugins/MaxLength';
import ShortcutsPlugin from './plugins/ShortcutsPlugin/ShortcutsPlugin';

export const extractPlainTextFromContent = (serializedContent: any): string => {
  const traverse = (node: any): string => {
    if (node.type === 'text') {
      return node.text;
    }

    if (node.children) {
      return node.children.map((child: any) => traverse(child)).join(' ');
    }

    return '';
  };

  return traverse(serializedContent.root);
};

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

export interface TextEditorCallBackProps {
  id?: TextEditorProps['id'];
  content: {
    root: SerializedEditorState<SerializedLexicalNode>;
  };
  title: string;
  isEmptyContent: boolean;
  childrenSize: number;
}
export interface TextEditorProps {
  isLoading?: boolean;
  containerClassName?: string;
  editorContainerClassName?: string;
  submitButtonClassName?: string;
  debounceDelayMs?: number;
  initialContentState?: object;
  id?: string;
  enableSubmit?: boolean;
  maxLength?: number;
  onSubmit?: (props: TextEditorCallBackProps) => void;
  onChange?: (props: TextEditorCallBackProps) => void;
}

function TextEditor({
  containerClassName,
  editorContainerClassName,
  submitButtonClassName,
  id,
  isLoading,
  initialContentState,
  onSubmit,
  onChange,
  debounceDelayMs,
  maxLength,
  enableSubmit = false,
}: TextEditorProps) {
  const editorStateRef = React.useRef<EditorState | undefined>();
  const [editorState, setEditorState] = React.useState<object | undefined>();

  const editorConfig: InitialConfigType = {
    namespace: 'Flowlie Text Editor',
    editorState: JSON.stringify(initialContentState),
    // The editor theme
    // theme: ExampleTheme,
    theme: TextEditorTheme,
    // Handling of errors during update
    onError(error: Error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      AutoLinkNode,
      LinkNode,
    ],
  };

  const handleSubmit = () => {
    if (editorStateRef.current) {
      const editorStateTextString = editorStateRef.current.read(() =>
        $getRoot().getAllTextNodes(),
      );
      const editorChildrenSize = editorStateRef.current.read(() =>
        $getRoot().getChildrenSize(),
      );
      const data = editorStateRef.current.toJSON();
      const title = editorStateTextString[0].__text;
      setEditorState(data);
      if (onSubmit) {
        onSubmit({
          id,
          content: { root: data },
          title,
          isEmptyContent: !editorStateTextString.length,
          childrenSize: editorChildrenSize,
        });
      }
    }
  };

  const handleChange = (editorState: EditorState, editor: LexicalEditor) => {
    let childrenSize = 0;
    editorStateRef.current = editorState;
    const editorStateTextString = editorStateRef.current.read(() =>
      $getRoot().getAllTextNodes(),
    );

    // first child of root will contain all the connection note content
    const firstChild = editorStateRef.current.read(() =>
      $getRoot().getFirstChild(),
    );

    // if the size of the first child is zero, this means that it has no text in it, nor an empty list
    if ($isElementNode(firstChild)) {
      childrenSize = firstChild.__size;
    }

    const title = editorStateTextString[0]?.__text || '';
    const data = editorStateRef.current.toJSON();

    setEditorState(data);
    if (onChange) {
      onChange({
        id,
        content: { root: data },
        title,
        isEmptyContent: !editorStateTextString.length,
        childrenSize,
      });
    }
  };

  const debouncedHandleChange = debounceDelayMs
    ? debounce(handleChange, debounceDelayMs)
    : handleChange;

  return (
    <>
      <LexicalComposer initialConfig={editorConfig}>
        <div
          className={cn(
            'relative mx-auto my-5 flex w-full flex-1 flex-col rounded-sm rounded-t-[10px] text-left font-normal leading-5 text-black',
            containerClassName,
          )}
        >
          <ToolbarPlugin />
          <div
            className={cn('relative flex-1 bg-white', editorContainerClassName)}
          >
            <RichTextPlugin
              contentEditable={
                <ContentEditable className="editor-input h-full border-x border-b" />
              }
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin
              onChange={debouncedHandleChange}
              ignoreSelectionChange
            />
            {enableSubmit && (
              <button
                className={cn(
                  'app-button--green disabled:bg-transparent disabled:text-slate-300 disabled:hover:bg-transparent disabled:hover:text-slate-300',
                  submitButtonClassName,
                )}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                SAVE
              </button>
            )}
            <HistoryPlugin />
            <DebugRender>
              <TreeViewPlugin />
            </DebugRender>
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ClickableLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <TabIndentationPlugin />
            <CheckListPlugin />
            <ShortcutsPlugin />
            {maxLength && <MaxLengthPlugin maxLength={maxLength} />}
          </div>
        </div>
      </LexicalComposer>
      <DebugRender>
        <pre>{JSON.stringify(editorState, null, 2)}</pre>
      </DebugRender>
    </>
  );
}

export default TextEditor;
