/* eslint-disable no-nested-ternary */
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import { dayDiff } from './formatting/dates';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import {
  ConnectionRequestAccountData,
  InvestorConnectionView,
} from '/../libs/shared-types/src/types/view/APIResponse';
import {
  ActiveDealSummaryView,
  AggregatedActiveDeal,
  AggregatedDeal,
  DealSummaryView,
  PassedDealSummaryView,
  PortfolioDealSummaryView,
  ScreeningDealSummaryView,
} from '/../libs/shared-types/src/types/view/AggregatedDeals';
import {
  ComparableConnectionRequestAccountData,
  ComparableInvestorConnectionView,
} from '/../libs/shared-types/src/types/view/ComparableInvestorConnectionView';
import { InvestmentView } from '/../libs/shared-types/src/types/view/InvestmentView';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import {
  ComparableActiveDealSummaryView,
  ComparableAggregatedActiveDeal,
  ComparableAggregatedDeal,
  ComparableDealSummaryView,
  ComparableInvestment,
  ComparablePassedDealSummaryView,
  ComparablePortfolioDealSummaryView,
  ComparableScreeningDealSummaryView,
} from '/src/interfaces/Comparable';
import {
  ComparablePipelineInvestorItemView,
  PipelineInvestorItemView,
} from '../types/view/InvestorPipelineView';
import { HowOftenType } from '/../libs/shared-types/src/constants/HowOftenType';

export const buildComparableAggregatedDeal = (
  x: AggregatedDeal,
): ComparableAggregatedDeal => ({
  name: x.startup.name,
  fitScore: x.fitScore.score,
  stage: enumToList(RoundStages).indexOf(x.startup.currentStage),
  round: x.isRoundOpen
    ? dayDiff(x.currentRound?.estimatedCloseDate) <= 0
      ? 1 + Math.abs(dayDiff(x.currentRound?.estimatedCloseDate))
      : 1_000_000_000 + dayDiff(x.currentRound?.estimatedCloseDate)
    : 0,
  sharedByDate: x.shareHistory[0].sharedOn.getTime(),
  industries: x.startup.industries.join(', '),
  geography: x.startup.geography,
});

const getRoundSortValue = (round?: RoundView) => {
  if (!round || round.closedOn.getTime() !== MIN_DATE.getTime()) {
    return 0;
  }

  if (dayDiff(round.estimatedCloseDate) <= 0) {
    return 1 + Math.abs(dayDiff(round.estimatedCloseDate));
  }

  return 1_000_000_000 + dayDiff(round.estimatedCloseDate);
};

export const buildComparableDealSummaryView = (
  x: DealSummaryView,
): ComparableDealSummaryView => ({
  name: x.startupName,
  stage: enumToList(RoundStages).indexOf(x.currentStage),
  round: getRoundSortValue(x.currentRound),
  industries: x.industries.join(', '),
  geography: x.geography,
});

export const buildComparableScreeningDealSummaryView = (
  x: ScreeningDealSummaryView,
): ComparableScreeningDealSummaryView => ({
  ...buildComparableDealSummaryView(x),
  message: x.message ?? '',
  sharedByName: x.sharedByName,
  sharedByDate: x.sharedByDate.getTime(),
  fitScore: x.fitScore.score,
  raising: x.currentRound?.raiseTarget ?? 0,
});

export const buildComparableActiveDealSummaryView = (
  x: ActiveDealSummaryView,
): ComparableActiveDealSummaryView => ({
  ...buildComparableDealSummaryView(x),
  raising: x.currentRound?.raiseTarget ?? 0,
  cap: x.currentRound?.valuationCap ?? 0,
  rating: x.rating?.rating ?? -1,
  priority: x.priority?.priority ?? -1,
});

export const buildComparablePassedDealSummaryView = (
  x: PassedDealSummaryView,
): ComparablePassedDealSummaryView => ({
  ...buildComparableDealSummaryView(x),
  currentRoundLabel: x.currentRoundLabel,
  passedReasons: x.passedReasons.join(' '),
  passedStage: x.passedStage,
});

export const buildComparablePortfolioDealSummaryView = (
  x: PortfolioDealSummaryView,
): ComparablePortfolioDealSummaryView => ({
  ...buildComparableDealSummaryView(x),
  moic: x.investments[0].moic ?? 0,
  investorSince: x.investments[x.investments.length - 1].investedOn.getTime(),
  firstInvestedAt: enumToList(RoundStages).indexOf(
    x.investments[x.investments.length - 1].roundStage,
  ),
  totalInvested: x.investments
    .map((investment) => investment.amount)
    .reduce((a, b) => a + b),
  estimatedValue:
    x.investments
      .map((investment) => investment.estimatedValue)
      .reduce((a, b) => (a ?? 0) + (b ?? 0)) ?? 0,
});

export const buildComparableInvestment = (x: {
  deal: PortfolioDealSummaryView;
  investment: InvestmentView;
}): ComparableInvestment => ({
  ...buildComparablePortfolioDealSummaryView(x.deal),
  investmentStage: enumToList(RoundStages).indexOf(x.investment.roundStage),
  amount: x.investment.amount,
  investedOn: x.investment.investedOn.getTime(),
  investmentType: x.investment.roundType,
  investmentValuation: x.investment.estimatedPostCap,
  moic: x.investment.moic ?? 0,
  pricePerShare: x.investment.pricePerShare ?? 0,
});

export const buildComparableAggregatedActiveDeal = (
  x: AggregatedActiveDeal,
): ComparableAggregatedActiveDeal => ({
  ...buildComparableAggregatedDeal(x),
  raising: x.currentRound?.raiseTarget ?? 0,
  cap: x.currentRound?.valuationCap ?? 0,
  rating: x.rating?.rating ?? -1,
  priority: x.priority?.priority ?? -1,
});

/**
 * This is a duplicate of the function defined in the backend.
 * The duplication is needed because it is referenced by the ConnectionRequests table.
 * We will eventually move all comparable logic to the backend
 * @param x
 * @returns
 */
export const buildComparableInvestorConnectionView = (
  x: InvestorConnectionView,
): ComparableInvestorConnectionView => ({
  name: `${x.firstName} ${x.lastName}`,
  type: enumToList(InvestorTypes).indexOf(x.type),
  firm: x.firm ?? 'z',
  earliestStage: enumToList(RoundStages).indexOf(x.invStages[0]),
  checkRangeMin: x.checkRangeMin,
  leadsRounds: enumToList(HowOftenType).indexOf(x.leadsRounds),
});

export const buildComparableConnectionRequestAccountData = (
  x: ConnectionRequestAccountData,
): ComparableConnectionRequestAccountData => ({
  ...buildComparableInvestorConnectionView(x),
  createdOn: x.createdOn.getTime(),
});

export const buildComparablePipelineInvestorItemView = (
  x: PipelineInvestorItemView,
): ComparablePipelineInvestorItemView => ({
  commitment: x.commitment?.amount ?? -1,
  firm: x.firm ?? 'z',
  fitScore: x.fitScore?.score ?? -1,
  lastContactedOn: (x.relationship?.lastContactedOn ?? MIN_DATE).getTime(),
  meetingTasksCompletedCount:
    x.meetingTasks.length > 0
      ? x.meetingTasks.filter(
          (task) => task.completedOn.getTime() !== MIN_DATE.getTime(),
        ).length
      : -1,
  name: x.name,
  notesCount: x.notesCount,
  priority: x.priority?.priority ?? -2,
  totalViewCount: x.totalViewCount,
  type: enumToList(InvestorTypes).indexOf(x.type),
});
