import React, { useContext, useEffect, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { QueueListIcon } from '@heroicons/react/24/outline';
import EmptyState from '/src/components/notifications/EmptyState';
import { InvestorList } from '../../../types/model/InvestorList';
import DateString from '/src/components/utility/DateString';
import EditInvestorListDialog from './EditInvestorListDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { STARTUP_INVESTOR_LIST_DETAIL_ROUTE } from '/src/constants/Routes';
import { Link, useNavigate } from 'react-router-dom';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import SearchBar from '/src/components/inputs/SearchBar';
import {
  ArrowTopRightOnSquareIcon,
  EllipsisVerticalIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/20/solid';
import { Menu, MenuButton, MenuItem } from '@headlessui/react';
import DropdownButton from '/src/components/DropdownButton';
import { joinClassNames } from '/src/util/formatting/strings';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { StartupDeleteInvestorList } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import ShareInvestorListDialog, {
  buildInvestorListShareLink,
} from './ShareInvestorListDialog';
import CopyTextButton from '/src/components/CopyTextButton';
import { isMinDate } from '/src/util/time';

interface InvestorDatabaseListsProps {
  apiResponse?: InvestorList[];
  fetchInvestorLists: () => Promise<void>;
}

function InvestorDatabaseLists({
  apiResponse,
  fetchInvestorLists,
}: InvestorDatabaseListsProps): JSX.Element {
  const [investorLists, setInvestorLists] = useState<InvestorList[]>(
    apiResponse ?? [],
  );
  const [filter, setFilter] = useState('');
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [modalConfig, setModalConfig] = useState<{
    type: '' | 'edit' | 'delete' | 'share';
    isModalOpen: boolean;
    investorList: undefined | InvestorList;
  }>({
    type: '',
    isModalOpen: false,
    investorList: undefined,
  });
  const [errorMessage, setErrorMessage] = useState('');

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false, investorList: undefined });
    setErrorMessage('');
  }

  function handleOnInvestorListUpdated(updatedList: InvestorList) {
    closeModal();
    setInvestorLists([updatedList, ...investorLists]);
    fetchInvestorLists();
  }

  async function deleteInvestorList(list: InvestorList) {
    try {
      await API.delete<InvestorList>(
        StartupDeleteInvestorList.buildEndpoint(list._id),
      );
      const remainingLists = investorLists.filter((x) => x._id !== list._id);
      closeModal();
      setInvestorLists(remainingLists);
      fetchInvestorLists();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  }

  function handleFilter(query: string) {
    setFilter(query);
    if (query === '') {
      setInvestorLists(apiResponse ?? []);
    } else {
      const filteredLists = apiResponse?.filter((list) =>
        list.name.toLowerCase().includes(query.toLowerCase()),
      );
      setInvestorLists(filteredLists ?? []);
    }
  }

  useEffect(() => {
    setInvestorLists(apiResponse ?? []);
  }, [apiResponse]);

  if (subscriptionTier === SubscriptionTiers.StartupFree) {
    return (
      <div className="mt-6 w-full rounded-md bg-white p-8 shadow">
        <p className="font-medium">
          Organize and plan your investor outreach with Target Lists
        </p>
        <a
          href="https://www.loom.com/share/8a138a798aa1469f98d03ed9f18e9dfa?sid=542d8ad5-dbc5-47f9-a661-04037a4a815e"
          target="_blank"
          rel="noopener noreferrer"
          className="hyperlink flex items-center space-x-2"
        >
          <span>Check out how target Lists and Investor Import work</span>
          <ArrowTopRightOnSquareIcon className="size-5" />
        </a>
        <ul className="mt-4 list-inside list-disc">
          <li>
            Create target lists for your fundraising rounds and networking
          </li>
          <li>
            Add investors directly from Flowlie&apos;s database or import your
            own
          </li>
          <li>Track each investor&apos;s priority for a strategic outreach</li>
        </ul>
        <p className="my-6">
          Try Flowlie Pro for free for 14 days to unlock the full investor
          database, target lists, and the ability to manage your investor
          pipeline.
        </p>
        <div className="w-max">
          <SubscriptionCTAPill
            id="cta_investor_database_target_lists"
            text="Create your first target list"
          />
        </div>
      </div>
    );
  }

  if (!investorLists) {
    return <></>;
  }

  return (
    <>
      {apiResponse?.length === 0 &&
        investorLists.length === 0 &&
        filter === '' && (
          <EmptyState
            title="You have not created any investor target lists yet"
            subTitle="Create lists of investors to organize and plan your outreach for your fundraising round"
            icon={<QueueListIcon className="mx-auto h-12 w-12 text-gray-400" />}
            actionButton={
              <button
                type="button"
                className="app-button--primary"
                onClick={() =>
                  setModalConfig({
                    type: 'edit',
                    isModalOpen: true,
                    investorList: undefined,
                  })
                }
              >
                Create New List
              </button>
            }
          />
        )}

      {(investorLists.length > 0 || filter !== '') && (
        <>
          <div className="mb-3 flex flex-row items-center justify-between">
            <span className="flex items-center">
              <SearchBar
                isDebounce
                placeholder="Search Lists"
                onQueryChange={handleFilter}
                initialValue={filter}
                isDisabled={false}
              />
              {/* {isSearchLoading && <LoadingSpinner color="blue" />} */}
            </span>
            <span>&nbsp;</span>
            <button
              type="button"
              className="app-button--primary"
              onClick={() => {
                handleFilter('');
                setModalConfig({
                  type: 'edit',
                  isModalOpen: true,
                  investorList: undefined,
                });
              }}
            >
              <PlusIcon className="mr-2 h-5 w-5" />
              New List
            </button>
          </div>
          <div className="border-b border-gray-200 bg-gray-50 p-0.5 shadow dark:border-gray-800 dark:bg-gray-900 dark:shadow-gray-800 sm:rounded-lg">
            <ul className="flex flex-col divide-y divide-gray-200 px-0 py-1 dark:divide-gray-700">
              {investorLists.length === 0 && (
                <div className="px-5">
                  <li className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <p className="text-gray-700">No lists found</p>
                      </div>
                    </div>
                  </li>
                </div>
              )}
              {investorLists.map((list) => (
                <div key={list._id} className="hover:bg-gray-100">
                  <li className="flex items-center justify-between pr-2">
                    <Link
                      className="w-full px-5 py-5"
                      to={`${STARTUP_INVESTOR_LIST_DETAIL_ROUTE}/${list._id}`}
                    >
                      <div className="flex items-center gap-x-3">
                        <p className="font-semibold leading-6 text-gray-900">
                          {list.name}
                        </p>
                        <span className="ml-3 rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 md:inline-block">
                          {list.investors.length} Investors
                        </span>
                      </div>

                      <div className="mt-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created <DateString date={list.createdOn} />
                        </p>
                        {list.startupId !== '' && (
                          <>
                            <svg
                              viewBox="0 0 2 2"
                              className="h-0.5 w-0.5 fill-current"
                            >
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                            <p className="ml-2 whitespace-nowrap">
                              Updated <DateString date={list.updatedOn} />
                            </p>
                          </>
                        )}
                      </div>

                      {list.description && (
                        <p className="mt-2 text-left text-xs text-gray-600">
                          {list.description}
                        </p>
                      )}
                    </Link>

                    <DropdownButton
                      label={'Manage Investor'}
                      button={
                        <MenuButton
                          className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <EllipsisVerticalIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      }
                    >
                      <MenuItem>
                        {({ focus }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalConfig({
                                type: 'edit',
                                isModalOpen: true,
                                investorList: list,
                              });
                            }}
                            className={joinClassNames(
                              focus
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex w-full items-center px-4 py-2 text-sm',
                            )}
                            title="Edit List"
                          >
                            <PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Edit
                          </button>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ focus }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalConfig({
                                type: 'delete',
                                isModalOpen: true,
                                investorList: list,
                              });
                            }}
                            className={joinClassNames(
                              focus
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex w-full items-center px-4 py-2 text-sm',
                            )}
                            title="Delete List"
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Delete
                          </button>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ focus }) => (
                          <div
                            className={joinClassNames(
                              focus
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'group flex w-full items-center',
                            )}
                          >
                            <button
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalConfig({
                                  type: 'share',
                                  isModalOpen: true,
                                  investorList: list,
                                });
                              }}
                              className={joinClassNames(
                                focus
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'flex w-full items-center px-4 py-2 text-sm',
                              )}
                              title="Share List"
                            >
                              <PaperAirplaneIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                              Share
                            </button>
                            {isMinDate(list.shareSetting.disabledOn) && (
                              <CopyTextButton
                                text={buildInvestorListShareLink(
                                  list.shareSetting.slug,
                                )}
                                className="ml-auto mr-2 inline-block"
                              />
                            )}
                          </div>
                        )}
                      </MenuItem>
                    </DropdownButton>
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </>
      )}
      <ModalWrapper open={modalConfig.isModalOpen} onClose={() => closeModal()}>
        {modalConfig.type === 'edit' && (
          <EditInvestorListDialog
            investorList={modalConfig.investorList}
            onCancel={() => closeModal()}
            onSave={handleOnInvestorListUpdated}
          />
        )}
        {modalConfig.type === 'delete' && modalConfig.investorList && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() =>
              deleteInvestorList(modalConfig.investorList!)
            }
            title="Are you sure you want to delete this list?"
            text="If you have shared it with others, they will no longer be able to access it."
            primaryAction="Delete List"
            color="red"
            errorMessage={errorMessage}
          />
        )}
        {modalConfig.type === 'share' && modalConfig.investorList && (
          <ShareInvestorListDialog
            investorList={modalConfig.investorList}
            onShareSettingUpdated={(updatedList) => {
              setInvestorLists(
                investorLists.map((list) =>
                  list._id === updatedList._id ? updatedList : list,
                ),
              );
            }}
          />
        )}
      </ModalWrapper>
    </>
  );
}

export default InvestorDatabaseLists;
