import React, { Fragment, useState, useEffect } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  Bars2Icon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { Priority } from '/../libs/shared-types/src/constants/Priority';

interface PriorityOption {
  priority: Priority;
  label: string;
  icon: JSX.Element;
}

export const PRIORITY_OPTIONS: PriorityOption[] = [
  {
    priority: Priority.None,
    label: Priority[Priority.None],
    icon: (
      <XMarkIcon
        key={Priority.None}
        className="size-5 text-gray-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Highest,
    label: Priority[Priority.Highest],
    icon: (
      <ChevronDoubleUpIcon
        key={Priority.Highest}
        className="size-5 text-red-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.High,
    label: Priority[Priority.High],
    icon: (
      <ChevronUpIcon
        key={Priority.High}
        className="size-5 text-red-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Medium,
    label: Priority[Priority.Medium],
    icon: (
      <Bars2Icon
        key={Priority.Medium}
        className="size-5 text-orange-600"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Low,
    label: Priority[Priority.Low],
    icon: (
      <ChevronDownIcon
        key={Priority.Low}
        className="size-5 text-blue-700"
        aria-hidden="true"
      />
    ),
  },
  {
    priority: Priority.Lowest,
    label: Priority[Priority.Lowest],
    icon: (
      <ChevronDoubleDownIcon
        key={Priority.Lowest}
        className="size-5 text-blue-700"
        aria-hidden="true"
      />
    ),
  },
];

function getPriorityOption(value: Priority) {
  const priority = PRIORITY_OPTIONS.find((x) => x.priority === value);
  if (!priority) {
    throw Error('Priority not found');
  }
  return priority;
}

interface PriorityLabelProps {
  priority: Priority;
  isInput?: boolean;
}

export function PriorityLabel({
  priority,
  isInput = false,
}: PriorityLabelProps) {
  const priorityOption = getPriorityOption(priority);
  return (
    <div className="flex items-center space-x-1">
      {priorityOption.priority > 0 ? (
        <>
          <span>{priorityOption.icon}</span>
          <span className="block truncate">{priorityOption.label}</span>
        </>
      ) : (
        <span className="block truncate">
          {isInput ? 'Add Priority' : 'No Priority'}
        </span>
      )}
    </div>
  );
}

interface PrioritySelectProps {
  onSelect: (priority: Priority) => void;
  value: Priority;
  disabled?: boolean;
}

function PrioritySelect({
  onSelect,
  value,
  disabled = false,
}: PrioritySelectProps) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative">
        <ListboxButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            e.stopPropagation()
          }
          className="flex cursor-default items-center space-x-1 rounded-md px-2.5 py-1.5 text-left text-xs tracking-wider hover:bg-gray-200 focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300"
        >
          <PriorityLabel priority={selected} isInput />
          <ChevronUpDownIcon
            className="size-4 text-gray-400"
            aria-hidden="true"
          />
        </ListboxButton>
        <Transition
          as="div"
          transition
          className={`data-[closed]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in`}
        >
          <ListboxOptions className="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {PRIORITY_OPTIONS.map((option, index) => (
              <ListboxOption
                as="li"
                key={index}
                className={({ focus }) =>
                  `flex cursor-default select-none items-center space-x-1.5 p-2 pr-4 ${
                    focus ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                  }`
                }
                value={option.priority}
                onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  e.stopPropagation();
                  onSelect(option.priority);
                }}
              >
                {({ selected }) => (
                  <>
                    <span>{option.icon}</span>
                    <span
                      className={`block truncate text-xs ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {option.label}
                    </span>
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
    </Listbox>
  );
}

export default PrioritySelect;
