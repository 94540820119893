import { Strength } from '/../libs/shared-types/src/constants/Strength';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';
import {
  ElementColor,
  ElementBorderColor,
} from '/../libs/shared-types/src/types/Utility/Color';

const dealStatusColorLookup: Record<string, ElementColor> = {
  Active: { bgColor: 'bg-blue-100', textColor: 'text-blue-800' },
  Passed: { bgColor: 'bg-red-100', textColor: 'text-red-800' },
  Portfolio: { bgColor: 'bg-green-100', textColor: 'text-green-800' },
  Screening: { bgColor: 'bg-slate-100', textColor: 'text-slate-800' },
};

const roundStatusColorLookup: Record<string, ElementColor> = {
  Open: { bgColor: 'bg-green-100', textColor: 'text-green-800' },
  'Open New': { bgColor: 'bg-green-100', textColor: 'text-green-800' },
  Closed: { bgColor: 'bg-red-100', textColor: 'text-red-800' },
};

export const investorTypeColorLookup: Record<InvestorTypes, ElementColor> = {
  [InvestorTypes.Angel]: { bgColor: 'bg-teal-100', textColor: 'text-teal-800' },
  [InvestorTypes.FamilyOffice]: {
    bgColor: 'bg-cyan-100',
    textColor: 'text-cyan-800',
  },
  [InvestorTypes.Syndicator]: {
    bgColor: 'bg-orange-100',
    textColor: 'text-orange-800',
  },
  [InvestorTypes.VC]: { bgColor: 'bg-blue-100', textColor: 'text-blue-800' },
  [InvestorTypes.CorporateVC]: {
    bgColor: 'bg-indigo-100',
    textColor: 'text-indigo-800',
  },
  [InvestorTypes.GovernmentalVC]: {
    bgColor: 'bg-sky-100',
    textColor: 'text-sky-800',
  },
  [InvestorTypes.Foundation]: {
    bgColor: 'bg-lime-100',
    textColor: 'text-lime-800',
  },
  [InvestorTypes.Accelerator]: {
    bgColor: 'bg-pink-100',
    textColor: 'text-pink-800',
  },
};

const strengthColorLookup: Record<Strength, ElementColor> = {
  [Strength.VeryStrong]: {
    bgColor: 'bg-green-100',
    textColor: 'text-green-800',
  },
  [Strength.Strong]: { bgColor: 'bg-lime-100', textColor: 'text-lime-800' },
  [Strength.Medium]: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800' },
  [Strength.Weak]: { bgColor: 'bg-orange-100', textColor: 'text-orange-800' },
  [Strength.VeryWeak]: { bgColor: 'bg-red-100', textColor: 'text-red-800' },
  [Strength.None]: { bgColor: 'bg-slate-100', textColor: 'text-slate-800' },
};

const accreditationColorLookup: Record<string, ElementColor> = {
  Accredited: { bgColor: 'bg-green-100', textColor: 'text-green-800' },
  'Not Accredited': { bgColor: 'bg-orange-100', textColor: 'text-orange-800' },
};

const booleanColorLookup: Record<string, ElementColor> = {
  Yes: { bgColor: 'bg-green-100', textColor: 'text-green-800' },
  No: { bgColor: 'bg-red-100', textColor: 'text-red-800' },
};

export function getColor(itemToLookup: string): ElementColor {
  if (itemToLookup in roundStatusColorLookup) {
    return roundStatusColorLookup[itemToLookup];
  }
  if (itemToLookup in dealStatusColorLookup) {
    return dealStatusColorLookup[itemToLookup];
  }
  if (itemToLookup in investorTypeColorLookup) {
    return investorTypeColorLookup[itemToLookup as InvestorTypes];
  }
  if (itemToLookup in accreditationColorLookup) {
    return accreditationColorLookup[itemToLookup];
  }
  if (itemToLookup in booleanColorLookup) {
    return booleanColorLookup[itemToLookup];
  }
  if (itemToLookup in strengthColorLookup) {
    return strengthColorLookup[itemToLookup as unknown as Strength];
  }

  return { bgColor: 'bg-slate-100', textColor: 'text-slate-800' };
}

export function percentToColor(percent: number): ElementColor {
  if (percent < 25) {
    return { bgColor: 'bg-red-500', textColor: 'text-red-500' };
  }
  if (percent < 50) {
    return { bgColor: 'bg-orange-500', textColor: 'text-orange-500' };
  }
  if (percent < 75) {
    return { bgColor: 'bg-yellow-500', textColor: 'text-yellow-500' };
  }
  return { bgColor: 'bg-green-500', textColor: 'text-green-500' };
}

export const getColorByIndex = (index: number, stagesLength: number) => {
  let colors: ElementBorderColor = {
    bgColor: 'bg-blue-300',
    textColor: 'text-blue-700',
    borderColor: 'border-blue-400',
  };

  if (index === 0) {
    colors = {
      bgColor: 'bg-slate-300',
      textColor: 'text-slate-700',
      borderColor: 'border-slate-400',
    };
  }

  if (index === stagesLength - 1) {
    colors = {
      bgColor: 'bg-green-300',
      textColor: 'text-green-700',
      borderColor: 'border-green-400',
    };
  }

  return {
    ...colors,
    allColors: Object.values(colors).join(' '),
  };
};
