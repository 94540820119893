import React, { useContext, useEffect, useState } from 'react';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { ClockIcon, EyeIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  StartupGet,
  StartupGetDashboard,
  StartupGetUserActivationTasks,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { StartupDashboardView } from '/../libs/shared-types/src/types/view/StartupDashboardView';
import { Task } from '/../libs/shared-types/src/types/model/Task';
import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import { taskToView } from '/src/constants/ActivationTaskMaps/ActivationTasksMap';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid';
import {
  dayDiff,
  formatMilliSecondsAsTimeDuration,
} from '/src/util/formatting/dates';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { BILLING_ROUTE } from '/src/constants/Routes';
import {
  SlideDown,
  SlideLeft,
  SlideUp,
} from '/src/components/animations/Slide';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { PrivateStartup } from '../../../types/view/startup/PrivateStartup';
import { DealActionModals, ModalConfig } from '/src/interfaces/ModalConfig';
import ShareProfileDialogContent from '../ProfilePreview/ShareProfileDialogContent';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { StartupActivationTasks } from '/../libs/shared-types/src/constants/UserActivation/StartupActivationTasks';
import { DAY_MS } from '/../libs/shared-types/src/constants/time';
import { MARK_SALES_CALL } from '../../../constants/Marketing/CalendlyLinks';
import Banner from '/src/components/Banner';

function StartupDashboardRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const { subscriptionTier, firstName } = useContext(AccountMetadataContext);
  const [startup, setStartup] = useState<PrivateStartup>();
  const [tasks, setTasks] = useState<ActivationTaskView[]>([]);
  const [dashboardView, setDashboardView] =
    React.useState<StartupDashboardView>();
  const { flowliePartner } = useContext(AccountMetadataContext);

  const stats = dashboardView
    ? [
        {
          icon: NewspaperIcon,
          name: 'One-pager Views',
          stat: dashboardView?.last14DaysOnePagerViews,
        },
        {
          icon: EyeIcon,
          name: 'Deck Views',
          stat: dashboardView?.last14DaysDeckViews,
        },
        {
          icon: ClockIcon,
          name: 'Total View Time',
          stat: formatMilliSecondsAsTimeDuration(
            dashboardView?.last14DaysTotalViewTimeMilliseconds,
          ),
        },
      ]
    : [];

  const [modalConfig, setModalConfig] = useState<ModalConfig<DealActionModals>>(
    {
      isOpen: false,
    },
  );
  const openModal = (dialog: DealActionModals) =>
    setModalConfig({ isOpen: true, dialog });

  function ModalContent(): JSX.Element {
    switch (modalConfig.dialog) {
      case DealActionModals.Share:
        if (startup) {
          return (
            <ShareProfileDialogContent startup={startup} showActivationBonus />
          );
        }
        return <></>;
      default:
        return <></>;
    }
  }

  async function fetchActivationTasks() {
    try {
      const taskList = await API.get<Task[]>(
        StartupGetUserActivationTasks.buildEndpoint(),
      );

      setTasks(taskList.map((task: Task) => taskToView(task)));
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchDashboardStats() {
    const data = await API.get<StartupDashboardView>(
      StartupGetDashboard.buildEndpoint(),
    );
    setDashboardView(data);
  }

  async function fetchStartup() {
    try {
      const data = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      setStartup(data);
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    const initialFetch = async () => {
      setIsLoading(true);
      await fetchActivationTasks();
      await fetchDashboardStats();
      await fetchStartup();
      setIsLoading(false);
    };

    initialFetch();
  }, []);

  const isFreePlan = subscriptionTier === SubscriptionTiers.StartupFree;
  const hasFiveUniqueDealViewSessions = tasks.some(
    (x) =>
      x._id === StartupActivationTasks.FiveUniqueDealViewSessions &&
      x.isCompleted,
  );

  const hasRecentlyOnboarded =
    (Date.now() - (startup?.createdOn.getTime() ?? Date.now())) / DAY_MS < 5;
  const bonusEndDate = startup
    ? new Date(startup.createdOn.getTime() + 4 * DAY_MS)
    : new Date(Date.now() + 4 * DAY_MS);
  const bonusEndsOn =
    dayDiff(bonusEndDate) > 0
      ? `${dayDiff(bonusEndDate)} days left`
      : `Ends today`;
  const isShowingWelcomeBonus =
    false && (!hasFiveUniqueDealViewSessions || hasRecentlyOnboarded);

  if (isLoading) {
    return <PageLoadingSpinner message="Loading your Fundraising Hub... 🚀" />;
  }

  return (
    <main>
      {subscriptionTier !== SubscriptionTiers.StartupPremium &&
        !flowliePartner && (
          <SlideDown>
            <Banner
              strongText="Spend 50% less time on your next VC round"
              text="You focus on pitching and closing investors. We do the rest."
              callToAction={{
                text: 'Check if you qualify',
                link: MARK_SALES_CALL,
              }}
            />
          </SlideDown>
        )}
      {flowliePartner && (
        <SlideDown>
          <Banner
            strongText={flowliePartner.bannerStrongText}
            text={flowliePartner.bannerText}
            callToAction={{
              text: flowliePartner.website,
              link: flowliePartner.website,
            }}
          />
        </SlideDown>
      )}

      <SlideLeft>
        <header className="my-8">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
            Welcome back,{' '}
            <span className="underline decoration-blue-600 decoration-4">
              {firstName}
            </span>
          </h2>
          <p className="text-xl">
            Here&apos;s a snapshot of your recent deck and one-pager activity
          </p>
        </header>
      </SlideLeft>

      <SlideUp>
        <section>
          <h3 className="text-xl font-semibold leading-6 text-gray-900">
            Last 14 days
          </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-4">
            {stats.map((item) => (
              <div
                key={item.name}
                className="flex flex-row items-center rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <div className="rounded-md bg-blue-500 p-3">
                  <item.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <dd className="ml-4 flex flex-col items-baseline">
                  <p className="truncate text-sm font-medium text-gray-500">
                    {item.name}
                  </p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </p>
                </dd>
              </div>
            ))}
            <div className="relative rounded-lg bg-blue-500 px-4 py-5 text-white shadow sm:p-6">
              <div>
                <dt className="truncate text-sm font-medium">Current Plan</dt>
                <dd className="mt-1 text-2xl font-semibold tracking-tight">
                  {subscriptionTier}
                  {!isFreePlan && ' 🚀'}
                </dd>
                {isFreePlan && (
                  <p className="text-xs">Limited analytics & features</p>
                )}
              </div>
              <div className="absolute right-3 top-4">
                {isFreePlan && (
                  <Link
                    className="rounded-lg bg-white/20 px-3 py-2 transition-all hover:bg-white/30"
                    to={BILLING_ROUTE}
                  >
                    Upgrade
                  </Link>
                )}
              </div>
            </div>
          </dl>
        </section>
        {isShowingWelcomeBonus && (
          <section>
            <div className="mt-8 rounded-lg bg-white px-6 py-2.5 shadow sm:px-3.5">
              <p className="p-2 text-sm leading-6 text-gray-900">
                <strong className="font-semibold">
                  WELCOME BONUS -&nbsp;
                  {!hasRecentlyOnboarded && 'LIMITED TIME'}
                  {hasRecentlyOnboarded && (
                    <>
                      <ClockIcon className="mr-0.5 inline-flex h-4 w-4" />
                      {bonusEndsOn.toUpperCase()}!
                    </>
                  )}
                </strong>
                <svg
                  viewBox="0 0 2 2"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                  aria-hidden="true"
                >
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <span className="mr-4">
                  Congratulations on creating your company one-pager and
                  uploading your first deck! Share it with our co-founders (who
                  are ex-VCs) to get FREE fundraising feedback. Availability is
                  limited and on a first come first serve basis, so we encourage
                  you to claim your welcome bonus ASAP
                </span>
                <button
                  className="rounded-full bg-blue-600 px-3.5 py-0 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => openModal(DealActionModals.Share)}
                >
                  {'Claim "Free Feedback" welcome bonus'}
                </button>
              </p>
            </div>
          </section>
        )}

        <section className="my-8">
          <h3 className="text-xl font-semibold leading-6 text-gray-900">
            Get started on Flowlie in {tasks.length} easy steps
          </h3>
          <div className="my-5 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow lg:grid lg:grid-cols-2 lg:gap-px lg:divide-y-0">
            {tasks.map((task, actionIdx) => (
              <div
                key={task.name}
                className={joinClassNames(
                  actionIdx === 0
                    ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                    : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  tasks.length % 2 === 0 && actionIdx === tasks.length - 2
                    ? 'sm:rounded-bl-lg'
                    : '',
                  actionIdx === tasks.length - 1
                    ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
                    : '',
                  tasks.length % 2 !== 0 && actionIdx === tasks.length - 1
                    ? 'col-span-full'
                    : '',
                  task.isCompleted ? 'hover:bg-green-50' : 'hover:bg-blue-50',
                  'group relative flex bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500',
                )}
              >
                <div>
                  <span
                    className={joinClassNames(
                      task.isCompleted ? 'text-green-700' : 'text-blue-700',
                      task.isCompleted ? 'bg-green-50' : 'bg-blue-50',
                      'inline-flex rounded-lg p-3',
                    )}
                  >
                    {task.isCompleted ? (
                      <CheckIcon className="h-6 w-6" />
                    ) : (
                      <span className="h-6 w-6 text-center">
                        {actionIdx + 1}
                      </span>
                    )}
                  </span>
                </div>
                <div className="mx-4">
                  <h3 className="text-base font-semibold leading-6 text-gray-900">
                    <Link to={task.redirectLink} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {task.name}
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">{task.subtitle}</p>
                </div>
                <span
                  className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </section>
      </SlideUp>
      <ModalWrapper
        open={modalConfig.isOpen}
        onClose={() => setModalConfig({ isOpen: false })}
      >
        <ModalContent />
      </ModalWrapper>
    </main>
  );
}

export default StartupDashboardRoute;
