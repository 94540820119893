import React from 'react';
import { InvestorPipelineSystemStages } from '../../../../../../../libs/shared-types/src/constants/InvestorPipelineSystemStages';
import {
  InvestorPipelineStageStatusChange,
  StageStatus,
} from '../../../../../types/model/Stage';
import { TimelineItem } from './Timeline';
import {
  PlusCircleIcon,
  ArrowRightCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { cn } from '/src/util/cn';
import { BaseMapper } from '/src/util/mapper';

interface GetLabelProps {
  stage: StageStatus;
  isFirstChangeInRound: boolean;
  roundName: string;
}

const isFinalStage = (stage: StageStatus) => {
  const isFinalStage = [
    InvestorPipelineSystemStages.Wired,
    InvestorPipelineSystemStages.NotInvested,
  ].includes(stage.name as InvestorPipelineSystemStages);

  return isFinalStage;
};

function getLabel({ stage, isFirstChangeInRound, roundName }: GetLabelProps) {
  if (isFirstChangeInRound) {
    return `Added to ${roundName} pipeline`;
  }
  if (isFinalStage(stage)) {
    return `Marked as ${stage.name.toLowerCase()}`;
  }
  return `Moved to ${stage.name.toLowerCase()}`;
}

function GetTimelineIcon(stage: StageStatus, isFirst?: boolean): JSX.Element {
  if (isFirst) {
    return (
      <div>
        <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
          <PlusCircleIcon className="h-5 w-8 text-white" />
        </span>
      </div>
    );
  }
  if (isFinalStage(stage)) {
    const isWired = stage.name === InvestorPipelineSystemStages.Wired;
    return (
      <div>
        <span
          className={cn(
            'flex h-8 w-8 items-center justify-center rounded-full bg-red-500 ring-8 ring-white',
            isWired ? 'bg-green-500' : '',
          )}
        >
          {isWired ? (
            <CheckCircleIcon className="h-5 w-8 text-white" />
          ) : (
            <XCircleIcon className="h-5 w-8 text-white" />
          )}
        </span>
      </div>
    );
  }
  return (
    <div>
      <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500 ring-8 ring-white">
        <ArrowRightCircleIcon className="h-5 w-8 text-white" />
      </span>
    </div>
  );
}

export class StatusChangeToTimelineMapper extends BaseMapper<
  InvestorPipelineStageStatusChange,
  TimelineItem
> {
  private _roundName: string;
  constructor(roundName: string) {
    super();
    this._roundName = roundName;
  }
  mapTo(
    statusChange: InvestorPipelineStageStatusChange,
    index: number,
    statusChangeList: InvestorPipelineStageStatusChange[],
  ) {
    const isFirstChangeInRound = statusChangeList.length - 1 === index;
    return {
      id: statusChange._id,

      icon: GetTimelineIcon(statusChange.stageStatus, isFirstChangeInRound),
      label: getLabel({
        stage: statusChange.stageStatus,
        isFirstChangeInRound,
        roundName: this._roundName,
      }),
      sortDate: statusChange.createdOn,
    };
  }
}
