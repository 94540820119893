import saveAs from 'file-saver';
import API from '../middleware/API';
import Logger from './logger';

export async function downloadPdf(
  fileUrl: string,
  fileName: string,
): Promise<void> {
  try {
    const res = await API.getPdf(fileUrl);
    const data = await res.blob();
    saveAs(data, fileName);
  } catch (error) {
    Logger.error(error);
  }
}

export async function downloadTxt(
  fileUrl: string,
  fileName: string,
): Promise<void> {
  try {
    const res = await API.getTxt(fileUrl);
    const data = await res.blob();
    saveAs(data, fileName);
  } catch (error) {
    Logger.error(error);
  }
}
