import React from 'react';

import {
  DAY_S,
  HOUR_S,
  MINUTE_S,
} from '/../libs/shared-types/src/constants/time';
import { formatDate } from '/src/util/formatting/dates';
import { isMinDate } from '/src/util/time';
import { getPluralSuffix } from '/src/util/formatting/numbers';

function timeFromNow(date: Date | undefined) {
  if (date === undefined || isMinDate(date)) {
    return 0;
  }

  const now = new Date();
  const seconds = Math.floor((date.getTime() - now.getTime()) / 1000);
  const isFuture = seconds > 0;
  const absSeconds = Math.abs(seconds);

  let interval = Math.floor(absSeconds / DAY_S);

  // For dates more than a week away in either direction, return formatted date
  if (interval > 7) {
    return formatDate(date);
  }

  // Days
  if (interval > 1) {
    const value = Math.floor(interval);
    return isFuture
      ? `in ${value} day${getPluralSuffix(value)}`
      : `${value} day${getPluralSuffix(value)} ago`;
  }

  // Hours
  interval = absSeconds / HOUR_S;
  if (interval > 1) {
    const value = Math.floor(interval);
    return isFuture
      ? `in ${value} hour${getPluralSuffix(value)}`
      : `${value} hour${getPluralSuffix(value)} ago`;
  }

  // Minutes
  interval = absSeconds / MINUTE_S;
  if (interval > 1) {
    const value = Math.floor(interval);
    return isFuture
      ? `in ${value} minute${getPluralSuffix(value)}`
      : `${value} minute${getPluralSuffix(value)} ago`;
  }

  // Just now / Very soon
  if (absSeconds < 10) {
    return isFuture ? 'Very soon' : 'Just now';
  }

  // Seconds
  return isFuture
    ? `in ${Math.floor(absSeconds)} seconds`
    : `${Math.floor(absSeconds)} seconds ago`;
}

interface DateProps {
  date: Date | undefined | null;
  tooltipPosition?: 'top' | 'bottom' | 'right';
}

function getMarginSide(position: string) {
  switch (position) {
    case 'right':
      return 'mr-2';
    default:
      return '';
  }
}

function DateString({
  date,
  tooltipPosition = 'right',
}: DateProps): JSX.Element {
  if (date === undefined || date === null || isMinDate(date)) {
    return <span>-</span>;
  }

  return (
    <span className="tooltip-wrapper">
      <span className={`tooltip tooltip-${tooltipPosition} w-max`}>
        {new Intl.DateTimeFormat('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        }).format(date)}
      </span>
      <span className={getMarginSide(tooltipPosition)}>
        {timeFromNow(date)}
      </span>
    </span>
  );
}

export default DateString;
