import React, { useContext, useEffect, useState } from 'react';
import { AccountMetadataContext } from '../contexts/AccountMetadataContext';
import { StartupConnectionNotes } from '../../../libs/shared-types/src/constants/ApiRoutes';
import Logger from '../services/logger';
import API from '../middleware/API';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { ConnectionNoteView } from '../types/view/ConnectionNoteView';
import { ConnectionDataType } from '../constants/ConnectionDataType';

export interface UseGetConnectionNotesProps {
  connectionId?: string;
  connectionDataType: ConnectionDataType;
}

const useGetConnectionNotes = ({
  connectionId,
  connectionDataType,
}: UseGetConnectionNotesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [response, setResponse] = useState<ConnectionNoteView[]>();
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [refetch, setRefetch] = useState(false);

  const handleRefetch = () => {
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    async function fetchConnectionNotes(): Promise<void> {
      if (
        !hasFeatureAccess(StartupPaidFeatures.ConnectionNotes, subscriptionTier)
      ) {
        setIsFirstLoading(false);
        return;
      }

      setIsLoading(true);

      try {
        const connectionNotesResponse = await API.get<ConnectionNoteView[]>(
          StartupConnectionNotes.buildEndpoint('', {
            connectionDataType,
            connectionId,
          }),
        );

        setResponse(connectionNotesResponse);
      } catch (error: any) {
        Logger.error(error);
      } finally {
        setIsLoading(false);
        setIsFirstLoading(false);
      }
    }

    fetchConnectionNotes();
  }, [subscriptionTier, refetch]);

  return {
    isLoading,
    isFirstLoading,
    data: response,
    totalCount: response?.length ?? 0,
    response,
    refetch: handleRefetch,
  };
};

export default useGetConnectionNotes;
