import React from 'react';

interface MeetingDetailCardProps {
  title: string;
  titleAside?: JSX.Element;
  content: string | string[] | undefined;
}

function MeetingDetailCard({
  title,
  titleAside,
  content,
}: MeetingDetailCardProps): JSX.Element {
  if (!content || (Array.isArray(content) && content.length === 0)) {
    return <></>;
  }

  return (
    <section>
      <div className="rounded-lg bg-white shadow">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
          {titleAside && titleAside}
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 xl:grid-cols-2">
            <div className="sm:col-span-2">
              <dd className="mt-1 text-sm text-gray-900">
                {typeof content === 'string' && content}
                {Array.isArray(content) && (
                  <ol className="list-inside list-decimal">
                    {content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ol>
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
}

export default MeetingDetailCard;
