import React, { ReactNode, useState } from 'react';
import DateString from '/src/components/utility/DateString';
import { SortOrder } from '../../../../../../../libs/shared-types/src/constants/SortOrder';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

export interface TimelineItem {
  id: string;
  icon: ReactNode;
  label: ReactNode;
  sortDate: Date;
  priority?: number;
}

interface TimelineItemProps {
  icon: TimelineItem['icon'];
  label: TimelineItem['label'];
  sortDate: TimelineItem['sortDate'];
  isLast: boolean;
}

function TimelineItem({ icon, label, sortDate, isLast }: TimelineItemProps) {
  return (
    <li>
      <div className="relative pb-8">
        {!isLast && (
          <span
            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        )}
        <div className="relative flex space-x-3">
          {icon}
          <div className="flex min-w-0 flex-1 items-center justify-between space-x-4">
            <div>
              <p className="text-base font-medium text-gray-900">{label}</p>
            </div>
            <div className="whitespace-nowrap text-right text-sm text-gray-500">
              <DateString date={sortDate} tooltipPosition="top" />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

interface SortTimelineItemsProps {
  items: TimelineItem[];
  sortOrder: SortOrder;
}

/**
 * Sorts a list of timeline items based on a specified sorting order and their sort values.
 * The sorting prioritizes the `sortDate` property. When two items have the same `sortDate`, the `priority` property is used
 * to determine the final order.
 *
 * @param {SortOrder} params.sortOrder - The order in which to sort the items (either `SortOrder.Desc` for descending or `SortOrder.Asc` for ascending).
 * @param {TimelineItem[]} params.items - The array of timeline items to be sorted.
 * @returns {TimelineItem[]} - The sorted array of timeline items.
 *
 */
function sortTimelineItems({
  sortOrder,
  items,
}: SortTimelineItemsProps): TimelineItem[] {
  return items.toSorted(
    (
      { sortDate: sortDateA, priority: priorityA = 0 },
      { sortDate: sortDateB, priority: priorityB = 0 },
    ) => {
      // Sort by sortDate, then by priority if both are equal
      let sortDateComparison;
      if (sortOrder === SortOrder.Desc) {
        sortDateComparison =
          sortDateB !== sortDateA
            ? sortDateB.getTime() - sortDateA.getTime()
            : priorityB - priorityA;
      } else {
        sortDateComparison =
          sortDateB !== sortDateA
            ? sortDateA.getTime() - sortDateB.getTime()
            : priorityA - priorityB;
      }
      return sortDateComparison;
    },
  );
}

interface TimelineProps {
  initialDisplayItemsCount?: number;
  sortOrder?: SortOrder;
  timelineItems: TimelineItem[];
}

function Timeline({
  initialDisplayItemsCount = 10,
  sortOrder = SortOrder.Desc,
  timelineItems,
}: TimelineProps): JSX.Element {
  const [displayItemsCount, setDisplayItemsCount] = useState(
    initialDisplayItemsCount,
  );
  const sortedList = sortTimelineItems({
    items: timelineItems,
    sortOrder,
  }).slice(0, displayItemsCount);
  return (
    <div className="rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="timeline-title"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          Timeline
        </h2>
      </div>

      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div className="mt-2 flow-root">
          <ul className="-mb-8">
            {sortedList.map(({ id, label, sortDate, icon }, index) => (
              <TimelineItem
                sortDate={sortDate}
                isLast={index === sortedList.length - 1}
                icon={icon}
                key={id}
                label={label}
              />
            ))}
          </ul>
          {displayItemsCount < timelineItems.length ? (
            <button
              className="mt-5 flex w-full items-center justify-center rounded py-1 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-600"
              onClick={() => setDisplayItemsCount(timelineItems.length)}
            >
              <ChevronDownIcon className="mr-2 h-5 w-5" />
              Show {timelineItems.length - displayItemsCount} More
            </button>
          ) : timelineItems.length > initialDisplayItemsCount ? (
            <button
              className="mt-5 flex w-full items-center justify-center rounded py-1 text-sm text-gray-500 hover:bg-gray-100 hover:text-gray-600"
              onClick={() => setDisplayItemsCount(initialDisplayItemsCount)}
            >
              <ChevronUpIcon className="mr-2 h-5 w-5" />
              Show Less
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
