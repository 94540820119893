import { pdfjs } from 'react-pdf';
import Logger from '../services/logger';
import {
  CognitoGetSignedUrl,
  SharedUpdatePublic,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import {
  FileType,
  MAX_DECK_SIZE,
  MAX_IMAGE_SIZE,
} from '/src/constants/FileUploadTypes';
import API from '/src/middleware/API';

interface UploadResult {
  key: string;
}

interface PdfUploadResult extends UploadResult {
  pageCount: number;
}

export async function getPdfPageCount(file: File): Promise<number> {
  const fileUrl = URL.createObjectURL(file);
  let pageCount = 0;

  const loadingTask = pdfjs.getDocument(fileUrl);
  try {
    const pdf = await loadingTask.promise;
    pageCount = pdf.numPages;
  } catch (error: any) {
    Logger.error('Error loading Pdf file', error);
  } finally {
    return pageCount;
  }
}

export async function fetchAndCreateImageFile(url: string, fileName: string) {
  try {
    const result = await fetch(url);
    const blob = await result.blob();

    const file = new File([blob], fileName, { type: 'image/jpeg' });
    return file;
  } catch (error) {
    Logger.error(error);
  }
}

/**
 * @deprecated Use uploadGenericFile instead
 * Send file to the server which then PUTs into the S3 bucket
 * @param file The file to upload.
 * @param currentFileKey The key of the file that is already on S3.
 * @returns S3 key if the upload was succesful, undefined otherwise.
 */
export async function updateFile(
  file: File | undefined,
  currentFileKey: string,
): Promise<string | undefined> {
  const formData = new FormData();
  const sendAuthHeader = true;
  if (file) {
    formData.append('upl', file, file.name);
    const postFileResult = await API.postFile(
      SharedUpdatePublic.buildEndpoint(currentFileKey),
      formData,
      sendAuthHeader,
    );
    if (postFileResult?.newKey) {
      return postFileResult.newKey;
    } else {
      Logger.error(
        'Tried to update existing file but failed',
        file.name,
        'postFileResult',
        postFileResult,
      );
    }
  }

  // We do not have a key, so return 'undefined'
  return undefined;
}

/**
 * @deprecated Use uploadGenericFile instead
 * Send file to the server which then PUTs into the S3 bucket
 * @param file The file to upload.
 * @param route The endpoint of the route.
 * @returns S3 key if the upload was succesful, undefined otherwise.
 */
export async function uploadFile(
  file: File | undefined,
  route: string,
): Promise<string | undefined> {
  const formData = new FormData();
  const sendAuthHeader = true;
  if (file instanceof File) {
    formData.append('upl', file, file.name);
    const postFileResult = await API.postFile(route, formData, sendAuthHeader);
    if (postFileResult?.key) {
      return postFileResult.key;
    } else {
      Logger.error(
        'Tried to upload new file but failed',
        file.name,
        'postFileResult',
        postFileResult,
      );
    }
  }

  // We do not have a key, so return 'undefined'
  return undefined;
}

export async function uploadGenericFile(
  file: File | undefined,
): Promise<UploadResult | undefined> {
  const fileName = file?.name;
  const fileType = file?.type;
  if (!fileName || !fileType) {
    return undefined;
  }

  try {
    const signedUrlResponse = await API.post<{ url: string; key: string }>(
      CognitoGetSignedUrl.buildEndpoint(),
      { fileName, fileType },
    );
    Logger.warn('fetched signed url');
    const { url, key } = signedUrlResponse;
    const formData = new FormData();
    if (file) {
      formData.append('file', file, fileName);
      Logger.warn('before putting file', new Date());
      Logger.warn(`File Type: ${fileType}`);
      Logger.warn('putFile url', url);

      const response = await API.putFile(url, file);
      if (!response.ok) {
        throw new Error(`Upload failed: ${response.statusText}`);
      }

      Logger.warn('put file successfully', new Date());

      return { key };
    }
  } catch (error) {
    Logger.warn(error);
    return undefined;
  }

  return undefined;
}

/**
 * Uploads deck to S3 bucket
 * @param file The file to upload.
 * @returns S3 key if the upload was succesful, undefined otherwise.
 */
export async function uploadPdfDeck(
  file: File | undefined,
): Promise<PdfUploadResult | undefined> {
  const uploadResult = await uploadGenericFile(file);
  if (!file || !uploadResult) {
    return undefined;
  }

  const pageCount = await getPdfPageCount(file);
  return { key: uploadResult.key, pageCount };
}

export function validateFileSize(
  sizeInBytes: number,
  fileType: FileType,
): boolean {
  const sizeInMegaBytes = sizeInBytes / 1024 / 1024;
  if (fileType === FileType.Image) {
    return sizeInMegaBytes <= MAX_IMAGE_SIZE;
  }
  if (fileType === FileType.Pdf) {
    return sizeInMegaBytes <= MAX_DECK_SIZE;
  }
  return false;
}

/**
 * Returns true if file extension is allowed by FileType.
 * @param fileType the file type to validate against
 * @param fileToValidate the File that has to be checked
 * @returns A number representing the max file size in MB.
 */
export function validateFileType(
  fileToValidate: File,
  fileType: FileType,
): boolean {
  // Get list of extensions from FileType enum
  const allowedExtensions = fileType.split(', ');
  // Build file extension from File object
  const fileExtension = `.${fileToValidate.type?.split('/')[1]}`;
  return allowedExtensions.includes(fileExtension);
}

/**
 * Returns the max file size in MB allowed for the provided file type.
 * @param fileType
 * @returns A number representing the max file size in MB.
 */
export function getMaxFileSize(fileType: FileType): number {
  if (fileType === FileType.Image) {
    return MAX_IMAGE_SIZE;
  }
  if (fileType === FileType.Pdf) {
    return MAX_DECK_SIZE;
  }
  throw Error('File type not supported');
}

export const bytesToMegaBytes = (sizeInBytes: number) =>
  sizeInBytes / 1024 / 1024;
