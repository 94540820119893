import React from 'react';
import { InvestorMeetingView } from '../../../../../types/view/InvestorMeetingView';
import { TimelineItem } from './Timeline';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { BaseMapper } from '/src/util/mapper';

export class InvestorMeetingToTimelineMapper extends BaseMapper<
  InvestorMeetingView,
  TimelineItem
> {
  mapTo(investorMeeting: InvestorMeetingView) {
    return {
      id: investorMeeting._id,
      icon: (
        <div>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-slate-500 ring-8 ring-white">
            <ChatBubbleLeftRightIcon className="h-5 w-8 text-white" />
          </span>
        </div>
      ),
      label: `${investorMeeting.type} Meeting`,
      sortDate: investorMeeting.meetingOccurredOn,
    };
  }
}
