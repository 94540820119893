import React from 'react';
import { Formik, Form, Field, FormikValues } from 'formik';
import * as yup from 'yup';

import { DealStatusTypes } from '/../libs/shared-types/src/constants/DealStatusTypes';
import { MAX_PASSED_REASONS } from '/../libs/shared-types/src/constants/MultiSelectLimits';
import {
  REASONS_FOR_PASSING_ON_DEAL,
  UNSOLICITED_SHARE_REASON,
} from '/../libs/shared-types/src/constants/SelectOptions/ReasonsForPassingOptions';
import { LONG_TEXTAREA_LENGTH_MAX } from '/../libs/shared-types/src/constants/TextLengthRanges';
import API from '/src/middleware/API';
import CustomSelect from '/src/components/inputs/CustomSelect';
import FormikInput from '/src/components/inputs/FormikInput';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import LimitedMenu from '/src/components/inputs/ReactSelectAdditions/LimitedMenu';
import Logger from '/src/services/logger';
import { DealSummaryView } from '/../libs/shared-types/src/types/view/AggregatedDeals';
import TextCharacterCounter from '/src/components/TextCharacterCounter';
import StarRatingSelectInput from '/src/components/inputs/StarRatingSelectInput';
import { InvestorUpdateDealStatus } from '/../libs/shared-types/src/constants/ApiRoutes';

enum PassFieldNames {
  DealRating = 'dealRating',
  Feedback = 'feedback',
  InvestorNote = 'investorNote',
  Reasons = 'reasons',
}

const validationSchema = yup.object({
  [PassFieldNames.DealRating]: yup.number(),
  [PassFieldNames.Feedback]: yup
    .string()
    .max(LONG_TEXTAREA_LENGTH_MAX, 'Feedback for foudner is too long'),
  [PassFieldNames.InvestorNote]: yup
    .string()
    .max(LONG_TEXTAREA_LENGTH_MAX, 'Personal note is too long'),
  [PassFieldNames.Reasons]: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one is required')
    .max(
      MAX_PASSED_REASONS,
      `At most ${MAX_PASSED_REASONS} pass reasons are allowed`,
    )
    .required('Required'),
});

const initialValues = {
  [PassFieldNames.DealRating]: 0,
  [PassFieldNames.Feedback]: '',
  [PassFieldNames.InvestorNote]: '',
  [PassFieldNames.Reasons]: '',
};

interface PassDialogProps {
  onClose: () => void;
  onSuccess: () => void;
  deal: DealSummaryView;
}

function PassDialog({
  onClose,
  onSuccess,
  deal,
}: PassDialogProps): JSX.Element {
  const selectOptions = deal.canBeUnsolicitedShare
    ? [UNSOLICITED_SHARE_REASON].concat(REASONS_FOR_PASSING_ON_DEAL)
    : REASONS_FOR_PASSING_ON_DEAL;

  // Used to detect "Enter" key and ignore its input in text area
  function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const passDeal = async (values: FormikValues) => {
    return API.put(InvestorUpdateDealStatus.buildEndpoint(), {
      startupId: deal.startupId,
      status: DealStatusTypes.Passed,
      reasons: values[PassFieldNames.Reasons],
      investorNote: values[PassFieldNames.InvestorNote],
      feedback: values[PassFieldNames.Feedback],
      dealRating: values[PassFieldNames.DealRating],
    })
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch((error: any) => {
        onClose();
        Logger.error(error);
      });
  };

  return (
    <main className="bg-white p-4 sm:w-screen sm:max-w-3xl sm:p-7">
      <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
        <h3
          className="text-lg font-medium leading-6 text-gray-900"
          id="modal-headline"
        >
          Pass on {deal.startupName}
        </h3>

        <Formik
          initialValues={{
            ...initialValues,
            [PassFieldNames.DealRating]: deal.rating.rating,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await passDeal(values);
            setSubmitting(false);
          }}
        >
          {({ values, isSubmitting, isValid, dirty }) => (
            <Form>
              <Field
                className="custom-select"
                component={CustomSelect}
                components={{ Menu: LimitedMenu }}
                isMulti
                label="Reasons for passing"
                secondaryLabel="This will only be visible to you"
                maxCount={MAX_PASSED_REASONS}
                name={PassFieldNames.Reasons}
                options={selectOptions}
                placeholder="Select pass reasons..."
                menuPortalTarget={document.querySelector('body')}
              />

              <StarRatingSelectInput
                label="Private deal rating (Optional)"
                menuPortalTarget={document.querySelector('body')}
                name={PassFieldNames.DealRating}
                secondaryLabel="This rating will only be visible to you"
              />

              <Field
                component={FormikInput}
                boxType="textarea"
                name={PassFieldNames.InvestorNote}
                customStyle="h-28"
                label="Private personal note (Optional)"
                type="text"
                onKeyDown={handleKeyDown}
                secondaryLabel="This note will only be visible to you"
              />
              <TextCharacterCounter
                textLength={values.investorNote.length}
                maxLength={LONG_TEXTAREA_LENGTH_MAX}
              />

              <Field
                component={FormikInput}
                boxType="textarea"
                name={PassFieldNames.Feedback}
                customStyle="h-28"
                label="Feedback response to founder (Optional)"
                type="text"
                onKeyDown={handleKeyDown}
                secondaryLabel="This will be shared with the founder"
              />
              <TextCharacterCounter
                textLength={values.feedback.length}
                maxLength={LONG_TEXTAREA_LENGTH_MAX}
              />

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="app-button--primary"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Pass
                  {isSubmitting && (
                    <div className="mr-2 w-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
                <button
                  onClick={onClose}
                  disabled={isSubmitting}
                  type="button"
                  className="app-button--neutral mr-2"
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </main>
  );
}

export default PassDialog;
