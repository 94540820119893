import { useLocation } from 'react-router-dom';

const useActiveTab = (tabPaths: string[], baseRoutePath: string) => {
  const location = useLocation();
  const path = location.pathname.split('/').pop() || '';

  return path === '' || baseRoutePath.includes(path) ? tabPaths[0] : path;
};

export default useActiveTab;
