import React, { useContext, useEffect, useState } from 'react';
import {
  ComparableInvestorListItemView,
  PublicInvestorListItemsPaginatedCollectionResponse,
} from '/../libs/shared-types/src/types/view/InvestorListView';
import { Helmet } from 'react-helmet-async';
import { FlowlieBadge } from '/src/components/FlowlieIcons';
import { useParams, useNavigate } from 'react-router-dom';
import { PublicGetPublicInvestorList } from '/../libs/shared-types/src/constants/ApiRoutes';
import { PaginatedRequestParams } from '/../libs/shared-types/src/types/view/APIResponse';
import { defaultSortKey } from '../../startup/InvestorListDetail/InvestorListDetailRoute';
import SearchBar from '/src/components/inputs/SearchBar';
import DateString from '/src/components/utility/DateString';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { SharedStateContext } from '/src/contexts/SharedStateContext';
import usePaginatedUrlsParams from '/src/hooks/usePaginatedUrlParams';
import { SortOrder } from '/src/interfaces/Sortable';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import {
  PUBLIC_NOT_FOUND_ROUTE,
  SHARE_LINK_DATA_ACCESS_ROUTE,
} from '/src/constants/Routes';
import SquaredLogo from '/src/components/SquaredLogo';
import { formatImageAddress } from '/src/util/formatting/strings';
import { TargetInvestorMapper } from '../../startup/InvestorListDetail/TargetInvestorMapper';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import DropdownMultiCheckBoxes from '/src/components/DropdownMultiCheckBoxes';
import { RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS } from '/../libs/shared-types/src/constants/Strength';
import {
  BOOLEAN_WITH_UNSET_OPTIONS,
  NOT_SET_OPTION,
} from '/../libs/shared-types/src/constants/SelectOptions/SelectOptions';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import InvestorTypes from '/../libs/shared-types/src/constants/InvestorTypes';

function PublicInvestorList() {
  const { linkShareToken } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponse, setApiResponse] =
    useState<PublicInvestorListItemsPaginatedCollectionResponse>();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const { setToastConfiguration } = useContext(SharedStateContext);

  const { page, perPage, sortOrder, sortKey, filter, fieldFilters } =
    usePaginatedUrlsParams();

  async function fetchList(
    args: Partial<PaginatedRequestParams<ComparableInvestorListItemView>>,
  ) {
    if (!linkShareToken) {
      navigate(PUBLIC_NOT_FOUND_ROUTE);
      return;
    }
    try {
      setIsSearchLoading(true);
      const requestParams = {
        sortKey: defaultSortKey,
        ...apiResponse,
        ...args,
      };
      const listData =
        await API.get<PublicInvestorListItemsPaginatedCollectionResponse>(
          PublicGetPublicInvestorList.buildEndpoint(
            linkShareToken,
            requestParams,
          ),
        );

      if (!listData) {
        setToastConfiguration({
          isError: true,
          message: 'The link is not valid',
        });
        navigate(SHARE_LINK_DATA_ACCESS_ROUTE);
        return;
      }

      setApiResponse(listData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsSearchLoading(false);
    }
  }

  async function handleFilter(filter: string) {
    try {
      await fetchList({ filter, page: 1 });
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function initialFetch() {
    try {
      setIsLoading(true);
      await fetchList({
        page,
        perPage,
        sortKey: (sortKey ??
          defaultSortKey) as keyof ComparableInvestorListItemView,
        sortOrder: sortOrder ? Number(sortOrder) : SortOrder.Desc,
        filter,
        fieldFilters,
      });
    } catch (error: any) {
      Logger.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    initialFetch();
  }, []);

  if (isLoading) {
    return <PageLoadingSpinner message="Loading investor target list... 🎯" />;
  }

  if (!apiResponse) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          <h3>
            Oops! This investor target list may not exist or the link may have
            expired.
          </h3>
        </main>
      </div>
    );
  }

  const onSortChange = async ({
    headerKey,
    sort,
  }: {
    headerKey: string;
    label: string;
    headerIndex: number;
    sort: SortOrder | null;
  }) => {
    const sortKey = headerKey as keyof ComparableInvestorListItemView;
    const params = {
      sortOrder: (sort ?? 1) * -1,
      sortKey: sortKey,
      page: headerKey !== apiResponse.sortKey ? 1 : undefined,
    };
    fetchList(params);
  };

  const onPageChange = async (newPage: number) => {
    try {
      await fetchList({ page: newPage });
    } catch (error: any) {
      Logger.error(error.message);
    }
  };

  const onPerPageChange = async (newPerPage: number) => {
    try {
      await fetchList({ perPage: newPerPage });
    } catch (error: any) {
      Logger.error(error.message);
    }
  };

  const handleRelationshipChange = async (
    checkboxStates: Record<string, boolean>,
  ) => {
    const relationshipStrenghts = Object.keys(checkboxStates)
      .filter((option) => checkboxStates[option])
      .map((selectedOption) =>
        selectedOption === NOT_SET_OPTION.label
          ? NOT_SET_OPTION.value
          : Number(
              RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.find(
                (relationshipOption) =>
                  relationshipOption.label === selectedOption,
              )?.value,
            ),
      );

    await fetchList({
      fieldFilters: {
        ...apiResponse.fieldFilters,
        relationshipStrenghts,
      },
    });
  };

  const handleInvestorTypeChange = async (
    checkboxStates: Record<string, boolean>,
  ) => {
    const investorTypes = Object.keys(checkboxStates)
      .filter((option) => checkboxStates[option])
      .map((selectedOption) => selectedOption);

    await fetchList({
      fieldFilters: {
        ...apiResponse.fieldFilters,
        investorTypes,
      },
    });
  };

  const handleIntroNeededChange = (checkboxStates: Record<string, boolean>) => {
    const isIntroNeededOptions = Object.keys(checkboxStates)
      .filter((key) => checkboxStates[key])
      .map((selectedRelationships) =>
        selectedRelationships === 'Yes'
          ? true
          : selectedRelationships === 'No'
            ? false
            : NOT_SET_OPTION.value,
      );
    fetchList({
      fieldFilters: {
        ...apiResponse.fieldFilters,
        isIntroNeededOptions,
      },
    });
  };

  const rows = new TargetInvestorMapper(
    apiResponse.investorList._id,
    undefined,
    true,
  ).mapAllTo(apiResponse.results);

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>{apiResponse.investorList.name}</title>
      </Helmet>
      <div className="mx-auto min-h-screen w-full px-6">
        <div className="flex min-h-screen flex-col bg-gray-100">
          <main className="flex-grow pb-8">
            <header className="pb-1 pt-3 lg:py-3">
              <section className="mb-6 flex items-center space-x-5">
                <div className="size-10 shrink-0">
                  <SquaredLogo
                    containerClassName="size-10"
                    src={formatImageAddress(apiResponse.startup.logoKey)}
                    alt={`${apiResponse.startup.name} logo`}
                  />
                </div>

                <p className="text-gray-600">
                  {apiResponse.startup.accountFirstName}{' '}
                  {apiResponse.startup.accountLastName}, founder of{' '}
                  {apiResponse.startup.name} (
                  <span className="hyperlink">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={apiResponse.startup.website}
                    >
                      {apiResponse.startup.website.split('//').length > 0
                        ? apiResponse.startup.website.split('//')[1]
                        : apiResponse.startup.website}
                    </a>
                  </span>
                  ), is sharing a list of investors
                </p>
              </section>
              <section className="2xl:flex 2xl:items-center 2xl:justify-between">
                <div className="flex items-center">
                  <h2 className="flex items-center gap-x-3 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    <span className="text-blue-600">
                      {apiResponse.investorList.name}
                    </span>
                    <span className="ml-3 rounded-full bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
                      {apiResponse.totalCount} Investors
                    </span>
                  </h2>
                </div>

                <div className="flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                  <p className="whitespace-nowrap">
                    Created{' '}
                    <DateString
                      date={apiResponse.investorList.createdOn}
                      tooltipPosition="bottom"
                    />
                  </p>
                  <>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="whitespace-nowrap">
                      Updated{' '}
                      <DateString
                        date={apiResponse.investorList.updatedOn}
                        tooltipPosition="bottom"
                      />
                    </p>
                  </>
                </div>
              </section>
              {apiResponse.investorList.description && (
                <p className="mt-2 text-sm text-gray-600">
                  {apiResponse.investorList.description}
                </p>
              )}
            </header>
            <section className="my-4">
              <div className="mb-3 flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
                <span className="relative flex items-center">
                  <SearchBar
                    isDebounce
                    placeholder="Search Investors"
                    onQueryChange={handleFilter}
                    initialValue={apiResponse.filter}
                    isDisabled={false}
                  />
                  {isSearchLoading && (
                    <LoadingSpinner
                      color="blue"
                      className="absolute right-[-2rem]"
                    />
                  )}
                </span>

                <div className="flex flex-wrap items-center justify-between gap-x-3 gap-y-3">
                  <div className="flex flex-row items-center space-x-2">
                    <span className="text-sm text-gray-600">Filters:</span>
                    <DropdownMultiCheckBoxes
                      key={apiResponse.fieldFilters?.investorTypes}
                      label="Type"
                      checkboxes={enumToList(InvestorTypes).map((x) => ({
                        label: x,
                        value:
                          apiResponse.fieldFilters.investorTypes?.includes(x) ??
                          false,
                      }))}
                      onCheckboxesChange={handleInvestorTypeChange}
                    />

                    <DropdownMultiCheckBoxes
                      label="Relationship"
                      checkboxes={RELATIONSHIP_STRENGTH_WITH_UNSET_OPTIONS.map(
                        (relationship) => ({
                          label: relationship.label,
                          value:
                            apiResponse.fieldFilters.relationshipStrenghts?.includes(
                              Number(relationship.value),
                            ) ?? false,
                        }),
                      )}
                      onCheckboxesChange={handleRelationshipChange}
                    />

                    <DropdownMultiCheckBoxes
                      label="Intro Needed"
                      checkboxes={BOOLEAN_WITH_UNSET_OPTIONS.map((option) => ({
                        label: option.label,
                        value:
                          apiResponse.fieldFilters.isIntroNeededOptions?.includes(
                            option.value,
                          ) ?? false,
                      }))}
                      onCheckboxesChange={handleIntroNeededChange}
                    />
                  </div>
                </div>
              </div>

              <BasePaginatedTable
                tableName="target-list-detail"
                onSortChange={onSortChange}
                enableSort
                headerStructure={[
                  {
                    headerKey: 'name',
                    label: 'Name',
                  },
                  {
                    headerKey: 'type',
                    label: 'Type',
                  },
                  {
                    headerKey: 'firm',
                    label: 'Firm & Role',
                  },
                  {
                    headerKey: 'fitScore',
                    label: 'Fit',
                    tooltip:
                      "Represents how well the company fits in the investor's thesis",
                  },
                  {
                    headerKey: 'checkRangeMin',
                    label: 'Check Size',
                  },
                  {
                    headerKey: 'leadsRounds',
                    label: 'Leads Rounds',
                  },
                  {
                    headerKey: 'priority',
                    label: 'Priority',
                  },
                  {
                    headerKey: 'relationshipStrength',
                    label: 'Relationship',
                  },
                  {
                    headerKey: 'isIntroNeeded',
                    label: 'Intro needed',
                  },
                ]}
                rows={rows.map((row) => ({
                  rowKey: row.id,
                  data: row,
                }))}
                resultCount={apiResponse.totalCount}
                perPage={apiResponse.perPage}
                page={apiResponse.page}
                pageCount={apiResponse.totalPages}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
                requestParams={{
                  page: apiResponse.page,
                  perPage: apiResponse.perPage,
                  sortOrder: apiResponse.sortOrder,
                  sortKey: apiResponse.sortKey,
                  filter: apiResponse.filter,
                  fieldFilters: apiResponse.fieldFilters,
                }}
              />
            </section>
          </main>
          <footer>
            <FlowlieBadge />
            <p className="block px-4 py-3 text-center text-2xs text-gray-600">
              This list has been compiled by the company founders. Flowlie
              Technologies, Inc. has not verified the source, information, or
              data in this document and does not warrant its accuracy. Flowlie
              Technologies, Inc. is not registered as a securities broker-dealer
              or an investment adviser with the U.S. Securities and Exchange
              Commission, the Financial Industry Regulatory Authority (“FINRA”),
              or any state securities regulatory authority.
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default PublicInvestorList;
