import React from 'react';
import DebugRender from '../../utility/DebugRender';
import TimeDurationString from '../../utility/TimeDurationString';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { VersionNumber } from '../../../types/model/Deck';

export interface DeckViewedCellProps {
  deckViewed: {
    version: VersionNumber;
    isLatest: boolean;
    isViewed: boolean;
    isDownloaded: boolean;
  };
  totalMillisecondsSpentOnDeck: number;
  totalDeckDownloadCount: number;
}

export function DeckViewedCell({
  deckViewed,
  totalMillisecondsSpentOnDeck,
  totalDeckDownloadCount,
}: DeckViewedCellProps) {
  return (
    <>
      <DebugRender>
        <span className="flex items-center text-2xs text-gray-500">
          <TimeDurationString timeMs={totalMillisecondsSpentOnDeck} />
          <ArrowDownTrayIcon className="ml-2 mr-0.5 h-3 w-3" />
          <span>{totalDeckDownloadCount}</span>
        </span>
      </DebugRender>
      {!deckViewed.isViewed && !deckViewed.isDownloaded && (
        <span
          title="The viewer has not viewed nor downloaded your deck during this session"
          className="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
        >
          Not Viewed
        </span>
      )}
      {deckViewed.isViewed && !deckViewed.isDownloaded && (
        <span
          title="The viewer has opened your deck during this session"
          className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
        >
          Viewed
        </span>
      )}
      {!deckViewed.isViewed && deckViewed.isDownloaded && (
        <span
          title="The viewer has downloaded your deck during this session"
          className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
        >
          Downloaded
        </span>
      )}
      {deckViewed.isViewed && deckViewed.isDownloaded && (
        <span
          title="The viewer has viewed and downloaded your deck during this session"
          className="rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
        >
          Viewed & Downloaded
        </span>
      )}
      <span
        className="app-table-row-text--light ml-2 mt-0 flex"
        title={`Deck version ${deckViewed.version.major}.${deckViewed.version.minor}`}
      >
        V {deckViewed.version.major}.{deckViewed.version.minor}
        {deckViewed.isLatest && <div className="ml-1">(Latest)</div>}
      </span>
    </>
  );
}
