import React, { useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Alert from '/src/components/notifications/Alert';
import CustomSelect from '/src/components/inputs/CustomSelect';
import DebugRender from '/src/components/utility/DebugRender';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { InvestorMeetingView } from '/../libs/shared-types/src/types/view/InvestorMeetingView';
import {
  bytesToMegaBytes,
  getPdfPageCount,
  uploadGenericFile,
  uploadPdfDeck,
  validateFileType,
} from '/src/util/FileHelpers';
import {
  FileType,
  MAX_TRANSCRIPT_PAGES,
  MAX_TRANSCRIPT_SIZE,
} from '/src/constants/FileUploadTypes';
import PdfInput from '/src/components/inputs/PdfInput';
import FormikInput from '/src/components/inputs/FormikInput';
import { InvestorDataType } from '../../../constants/InvestorDataType';
import API from '/src/middleware/API';
import { StartupInvestorMeetings } from '../../../../../libs/shared-types/src/constants/ApiRoutes';
import {
  convertDatePickerInputToUtc,
  formatDatePickerInput,
} from '/src/util/formatting/dates';
import { enumToSelectOptions } from '../../../../../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { MeetingType } from '../../../../../libs/shared-types/src/constants/MeetingType';

enum FieldNames {
  MeetingOccurredOn = 'meetingOccurredOn',
  Type = 'type',
  TranscriptFile = 'transcriptFile',
  TranscriptFileMustValidate = 'transcriptFileMustValidate',
}

const emptyInvestorMeeting = {
  [FieldNames.MeetingOccurredOn]: '',
  [FieldNames.Type]: '',
  [FieldNames.TranscriptFile]: '',
  [FieldNames.TranscriptFileMustValidate]: false,
};

const validationSchema = yup.object({
  [FieldNames.MeetingOccurredOn]: yup.date().required('Required'),
  [FieldNames.Type]: yup.string().required('Required'),
  [FieldNames.TranscriptFile]: yup
    .mixed()
    .when(FieldNames.TranscriptFileMustValidate, {
      is: (hasChanged: boolean) => hasChanged === true,
      then: (schema) =>
        schema
          .required('Required')
          .test(
            'fileFormat',
            'Unsupported file format',
            (value: any) =>
              (value && validateFileType(value, FileType.Pdf)) ||
              (value && validateFileType(value, FileType.TxtPlain)),
          )
          .test(
            'fileSize',
            'Uploaded file is too big',
            (value: any) =>
              value && bytesToMegaBytes(value.size) <= MAX_TRANSCRIPT_SIZE,
          )
          .test(
            'filePages',
            `The file must have at most ${MAX_TRANSCRIPT_PAGES} pages`,
            async (value: any) => {
              if (value && validateFileType(value, FileType.Pdf)) {
                const pageCount = await getPdfPageCount(value);
                return value && pageCount <= MAX_TRANSCRIPT_PAGES;
              }
              return true;
            },
          ),
    }),
});

interface AddOrUpdateInvestorMeetingProps {
  investorId: string;
  investorDataType: InvestorDataType;
  investorName: string;
  onCancel: () => void;
  onSuccess: (investorMeeting: InvestorMeetingView) => void;
  investorMeeting?: InvestorMeetingView;
}

type AddOrUpdateInvestorMeetingForm = yup.InferType<typeof validationSchema> & {
  TranscriptFile: {
    name: string;
  };
};

/**
 * AddOrUpdateInvestorMeetingDialog component
 *
 * This component provides a form for either adding or updating an investor meeting document.
 * When updating an investor meeting, investorMeeting._id is provided. Otherwise, the form
 * will create an investor meeting.
 *
 */
function AddOrUpdateInvestorMeetingDialog({
  investorId,
  investorDataType,
  investorName,
  onCancel,
  onSuccess,
  investorMeeting,
}: AddOrUpdateInvestorMeetingProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    ...emptyInvestorMeeting,
    ...investorMeeting,
    [FieldNames.MeetingOccurredOn]: investorMeeting?.meetingOccurredOn
      ? formatDatePickerInput(investorMeeting?.meetingOccurredOn)
      : emptyInvestorMeeting[FieldNames.MeetingOccurredOn],
    [FieldNames.TranscriptFile]: investorMeeting?.transcriptKey
      ? { name: investorMeeting?.transcriptKey }
      : emptyInvestorMeeting[FieldNames.TranscriptFile],
  });

  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmit(
    values: AddOrUpdateInvestorMeetingForm,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    setSubmitting(true);
    let transcriptKey;
    try {
      // When adding a new investor meeting: values.TranscriptFile will always be an instance of File
      // When updating an investor meeting: values.TranscriptFile will be an instance of File only after the user selects a new file
      // If the user did not select a new file, values.TranscriptFile will just be an object with a 'name' property
      if (values[FieldNames.TranscriptFile] instanceof File) {
        const uploadResult = await uploadGenericFile(
          values[FieldNames.TranscriptFile],
        );
        if (uploadResult === undefined) {
          throw Error('Error: Transcript upload failed');
        }
        transcriptKey = uploadResult.key;
      }

      const body = {
        investorMeetingId: investorMeeting?._id,
        meetingOccurredOn: values[FieldNames.MeetingOccurredOn]
          ? convertDatePickerInputToUtc(
              new Date(values[FieldNames.MeetingOccurredOn]),
            )
          : null,
        transcriptKey,
        type: values[FieldNames.Type],
        investorId,
        investorDataType,
      };

      const investorMeetingResponse = await API.post<InvestorMeetingView>(
        StartupInvestorMeetings.buildEndpoint(),
        body,
      );
      onSuccess(investorMeetingResponse);
    } catch (error: any) {
      setErrorMessage(error?.message || 'An unexpected error occurred');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-2xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, dirty, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 items-center justify-between sm:flex sm:flex-row">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {investorMeeting?._id
                  ? `Edit meeting with ${investorName}`
                  : `Create meeting with ${investorName}`}
                <p className="text-sm font-normal text-gray-600">
                  Track and analyze investor meetings
                </p>
              </h3>

              <div className="my-4 flex flex-col space-y-3 sm:my-0 sm:ml-4 sm:flex-row sm:space-x-3 sm:space-y-0">
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral justify-center truncate"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green justify-center truncate"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section className="">
              <Field
                component={FormikInput}
                label="Meeting Date"
                secondaryLabel="When was the meeting or when will it be?"
                name={FieldNames.MeetingOccurredOn}
                placeholder="mm/dd/yyyy"
                type="date"
              />
              <Field
                className="custom-select"
                component={CustomSelect}
                label={'What type of meeting is it?'}
                closeMenuOnSelect
                name={FieldNames.Type}
                options={enumToSelectOptions(MeetingType)}
              />

              <Field
                component={PdfInput}
                label="Upload the transcript of the meeting to analyze it"
                secondaryLabel="Download the transcript as TXT or PDF from any recorder like Fathom, Fireflies, or Otter AI"
                name={FieldNames.TranscriptFile}
                maxFileSizeToUpload="1"
                fileTypeToUpload=".txt,.pdf"
                cleanPreviousFormValue
              />
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddOrUpdateInvestorMeetingDialog;
