import React from 'react';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { cn } from '../util/cn';

type DropdownButtonProps = {
  label: string;
  children: JSX.Element | JSX.Element[];
  button?: JSX.Element;
};

function DropdownButton({
  label,
  children,
  button,
}: DropdownButtonProps): JSX.Element {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {button ? (
        button
      ) : (
        <MenuButton className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {label}
          <ChevronUpDownIcon
            className="-mr-1 ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </MenuButton>
      )}

      <MenuItems
        anchor="bottom end"
        transition
        className={cn(
          'absolute right-0 z-50 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none',
          'transition duration-100 ease-in',
          'data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0',
        )}
      >
        <div className="py-1">{children}</div>
      </MenuItems>
    </Menu>
  );
}

export default DropdownButton;
