import React, { useEffect, useState, useContext, Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';

import {
  Bars3Icon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  BoltIcon,
  BanknotesIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/outline';
import { buildLinkFromToken } from '/src/util/flowlink';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import AuthService from '/src/services/AuthService';
import Logger from '/src/services/logger';
import flowlieLogo from '/src/res/flowlie_f.svg';
import InvestorActivationTasks from './InvestorActivationTasks';
import {
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import {
  InvestorRequestsContext,
  NOTIFICATIONS_ACCESS_REQUESTS,
  NOTIFICATIONS_INVESTOR_CONTACTS,
} from '/src/contexts/InvestorRequestsContext';
import {
  fetchConnectionRequests,
  fetchReceivedDealShareRequests,
} from '/src/services/InvestorRequests';
import { Task } from '/../libs/shared-types/src/types/model/Task';
import { ActivationTaskView } from '/../libs/shared-types/src/types/view/ActivationTaskView';
import { BaseUrls } from '/../libs/shared-types/src/constants/BaseUrls';
import API from '/src/middleware/API';
import { taskToView } from '/src/constants/ActivationTaskMaps/ActivationTasksMap';
import { localStorageGetAccountEmail } from '/src/middleware/LocalStorage';
import AccountDropDownMenu from './AccountDropDownMenu';
import {
  BILLING_ROUTE,
  REFERRALS_ROUTE,
  STARTUP_FUNDRAISING_WORKSHOP,
  START_ACTIVATION_TOUR_ROUTE,
} from '/src/constants/Routes';
import StartupNavigationLinks from './StartupNavigationLinks';
import InvestorNavigationLinks from './InvestorNavigationLinks';
import { SubscriptionTierPill } from '../../SubscriptionTierPill';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { isPaidTier } from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { getFeedbackFormUrl } from '/src/util/urls';
import {
  InvestorUserActivationTasks,
  StartupGetUserActivationTasks,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import StartupActivationTasks from './StartupActivationTasks';
import { ZendeskClient } from '/src/middleware/Zendesk';
import SquaredLogo from '../../SquaredLogo';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import { cn } from '/src/util/cn';

interface HelpPopOverProps {
  accountType: AccountTypes;
  includeButtonText: boolean;
}

export function HelpPopOver({
  accountType,
  includeButtonText,
}: HelpPopOverProps) {
  const zendeskWidgetId = 'showWidget';
  const repeatActivationTourId = 'repeatActivationTour';
  const navigate = useNavigate();

  const helpLinks = [
    {
      name: 'Give me a tour',
      id: repeatActivationTourId,
      onClick: () => navigate(START_ACTIVATION_TOUR_ROUTE),
    },
    {
      name: "What's new?",
      href: `${BaseUrls.PRODUCTION_SLEEKPLAN}/changelog/`,
    },
    { name: 'Suggest a feature', href: BaseUrls.PRODUCTION_SLEEKPLAN },
    { name: 'Give feedback', href: getFeedbackFormUrl(accountType) },
    {
      name: 'Support chat',
      href: '#',
      id: zendeskWidgetId,
      onClick: () => {
        const client = new ZendeskClient();
        client.activate();
      },
    },
  ];
  return (
    <Popover className="">
      <PopoverButton className="inline-flex w-full items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span className="flex w-full items-center space-x-2 rounded-md px-2 py-2 font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          <QuestionMarkCircleIcon className="app-nav-icon" />
          {includeButtonText && <span>Help</span>}
        </span>
      </PopoverButton>

      <Transition
        as="div"
        transition
        className={`data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
      >
        <PopoverPanel
          anchor="right end"
          className="z-10 mt-2 flex w-screen max-w-min px-2"
        >
          <div className="w-56 shrink rounded-md bg-white p-3 shadow-lg ring-1 ring-gray-900/5">
            {helpLinks.map((item) =>
              item.id === zendeskWidgetId ? (
                <button
                  id={item.id}
                  onClick={item.onClick}
                  key={item.name}
                  className="block w-full p-2 text-left text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </button>
              ) : item.id === repeatActivationTourId ? (
                <button
                  id={item.id}
                  onClick={item.onClick}
                  key={item.name}
                  className="block w-full p-2 text-left text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </button>
              ) : (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block p-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  {item.name}
                </a>
              ),
            )}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}

function Navigation({ children }: any): JSX.Element {
  const [showMenu, setShowMenu] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const openNav = () => setShowMenu(true);
  const closeNav = () => setShowMenu(false);
  const location = useLocation();

  const [tasks, setTasks] = React.useState<ActivationTaskView[]>([]);

  const {
    accountType,
    linkShareToken,
    subscriptionTier,
    hasUsedTrial,
    flowliePartner,
  } = useContext(AccountMetadataContext);

  const flowlink = buildLinkFromToken(linkShareToken ?? '');

  const {
    connectionRequests,
    setConnectionRequests,
    accessRequests,
    setAccessRequests,
  } = useContext(InvestorRequestsContext);

  const notificationsMap = new Map<string, number>();

  if (connectionRequests) {
    notificationsMap.set(
      NOTIFICATIONS_INVESTOR_CONTACTS,
      connectionRequests.length,
    );
  }

  if (accessRequests) {
    notificationsMap.set(NOTIFICATIONS_ACCESS_REQUESTS, accessRequests.length);
  }

  function handleResize() {
    if (window.innerWidth > 767) {
      setShowMenu(false);
    }
  }

  async function fetchAndSetInvestorRequests() {
    if (
      !AuthService.isLoggedIn() ||
      accountType !== AccountTypes.Investor ||
      connectionRequests !== undefined ||
      accessRequests !== undefined
    ) {
      return;
    }
    const dealshareRequests = await fetchReceivedDealShareRequests();
    const fetchedConnectionRequests = await fetchConnectionRequests();
    setAccessRequests(dealshareRequests);
    setConnectionRequests(fetchedConnectionRequests);
  }

  useEffect(() => {
    fetchAndSetInvestorRequests();

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchAndSetInvestorRequests();
  }, [location.pathname]);

  async function fetchActivationTasks() {
    if (!accountType) {
      return;
    }

    let endpoint = '';
    if (accountType === AccountTypes.Investor) {
      endpoint = InvestorUserActivationTasks.buildEndpoint();
    }
    if (accountType === AccountTypes.Startup) {
      endpoint = StartupGetUserActivationTasks.buildEndpoint();
    }

    try {
      const taskList = await API.get<Task[]>(endpoint);

      setTasks(taskList.map((task: Task) => taskToView(task)));
    } catch (error) {
      Logger.error(error);
    }
  }

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchActivationTasks();
    }
  }, [accountType]);

  const getSharedLinks = (isMenuExpanded: boolean) => (
    <>
      <nav className="flex-0 mt-3 space-y-1 border-t border-gray-200 px-2 pt-3 dark:border-gray-800">
        <div className="relative">
          {!isPaidTier(subscriptionTier) && (
            <NavLink
              onClick={closeNav}
              to={BILLING_ROUTE}
              className={({ isActive }) =>
                joinClassNames(
                  isActive
                    ? 'bg-purple-100 text-purple-900 before:absolute before:left-[0rem] before:h-4 before:w-1 before:rounded before:bg-purple-500 hover:bg-purple-100 hover:text-purple-900 dark:bg-purple-700 dark:text-purple-100 dark:hover:bg-purple-600 dark:hover:text-white'
                    : 'text-purple-600 hover:bg-purple-50 hover:text-purple-900 dark:text-purple-100 dark:hover:bg-purple-600 dark:hover:text-white',
                  'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
                )
              }
            >
              <BoltIcon className="app-nav-icon group-hover:text-purple-500" />
              {isMenuExpanded && (
                <>
                  <span>Upgrade</span>
                  {!hasUsedTrial && (
                    <span className="ml-1.5 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20">
                      14 Day Free Trial
                    </span>
                  )}
                </>
              )}
            </NavLink>
          )}
          {isPaidTier(subscriptionTier) && (
            <NavLink
              onClick={closeNav}
              to={BILLING_ROUTE}
              className={({ isActive }) =>
                joinClassNames(
                  isActive
                    ? 'bg-gray-100 text-blue-600 before:absolute before:left-[0rem] before:h-4 before:w-1 before:rounded before:bg-blue-500 hover:bg-gray-100 hover:text-blue-600 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
                  'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
                )
              }
            >
              <BoltIcon className="app-nav-icon" />
              {isMenuExpanded && <span>Subscription</span>}
            </NavLink>
          )}

          <NavLink
            onClick={closeNav}
            to={REFERRALS_ROUTE}
            className={({ isActive }) =>
              joinClassNames(
                isActive
                  ? 'bg-gray-100 text-blue-600 before:absolute before:left-[0rem] before:h-4 before:w-1 before:rounded before:bg-blue-500 hover:bg-gray-100 hover:text-blue-600 dark:bg-gray-700 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
                'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
              )
            }
          >
            <BanknotesIcon className="app-nav-icon" />
            {isMenuExpanded && <span>Refer & Earn</span>}
          </NavLink>
        </div>

        {accountType === AccountTypes.Startup && (
          <a
            id="cta_fundraising_workshop_at_side_menu"
            href={STARTUP_FUNDRAISING_WORKSHOP}
            target="_blank"
            rel="noopener noreferrer"
            className={joinClassNames(
              'text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
              'flex items-center space-x-2 rounded-md px-2 py-2 text-sm font-medium',
            )}
          >
            <AcademicCapIcon className="app-nav-icon" />
            {isMenuExpanded && <span>Masterclass</span>}
          </a>
        )}

        {accountType && (
          <HelpPopOver
            accountType={accountType}
            includeButtonText={isMenuExpanded}
          />
        )}
      </nav>
      {isMenuExpanded && accountType === AccountTypes.Investor && (
        <InvestorActivationTasks tasks={tasks} />
      )}
      {isMenuExpanded && accountType === AccountTypes.Startup && (
        <StartupActivationTasks tasks={tasks} />
      )}
    </>
  );

  if (accountType === undefined || subscriptionTier === undefined) {
    return <div className="sr-only">Loading menu</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100 dark:bg-gradient-to-b dark:from-black dark:to-slate-900">
      {/* Off-canvas menu for mobile, show/hide based on off-canvas menu state. */}
      <div
        className="md:hidden"
        style={{ display: showMenu ? 'block' : 'none' }}
      >
        <div className="fixed inset-0 z-40 flex">
          <Transition
            as="div"
            show={showMenu}
            transition
            className={`data-[closed]:opacity-0 data-[enter]:transition-opacity data-[leave]:transition-opacity data-[enter]:duration-300 data-[leave]:duration-300 data-[enter]:ease-linear data-[leave]:ease-linear`}
          >
            <div className="fixed inset-0">
              <div className="absolute inset-0 bg-gray-600 opacity-75" />
            </div>
          </Transition>

          <Transition
            as="div"
            show={showMenu}
            transition
            className={`data-[closed]:-translate-x-full data-[enter]:transform data-[leave]:transform data-[enter]:transition data-[leave]:transition data-[enter]:duration-300 data-[leave]:duration-300 data-[enter]:ease-in-out data-[leave]:ease-in-out`}
          >
            <div className="relative flex h-screen w-full max-w-sm flex-1 flex-col bg-white dark:bg-gray-900">
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  onClick={closeNav}
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon className="app-nav-icon" />
                </button>
              </div>

              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                <div className="flex shrink-0 items-center px-4 dark:text-white">
                  {flowliePartner ? (
                    <SquaredLogo
                      containerClassName="h-[52.8px] w-[64px]"
                      alt={flowliePartner.name}
                      src={formatImageAddress(flowliePartner.logoKey)}
                    />
                  ) : (
                    <img
                      className="h-10 w-auto"
                      src={flowlieLogo}
                      alt="flowlie"
                    />
                  )}
                  <div className="ml-2 flex flex-col">
                    <h1 className="-mb-2 text-xl font-bold tracking-wide">
                      flowlie
                    </h1>
                    <span className="-mb-1 -ml-1">
                      <SubscriptionTierPill />
                    </span>
                  </div>
                </div>

                {accountType === AccountTypes.Investor && (
                  <InvestorNavigationLinks
                    flowlink={flowlink}
                    closeNav={closeNav}
                    notifications={notificationsMap}
                    isMenuExpanded
                  />
                )}
                {accountType === AccountTypes.Startup && (
                  <StartupNavigationLinks
                    flowlink={flowlink}
                    closeNav={closeNav}
                    isMenuExpanded
                  />
                )}
                {getSharedLinks(true)}
              </div>
              <div className="group flex shrink-0 border-t border-gray-200 p-2 dark:border-gray-800">
                <AccountDropDownMenu
                  email={localStorageGetAccountEmail()}
                  showFullName
                />
              </div>
            </div>
          </Transition>

          {/* Force sidebar to shrink to fit close icon */}
          <div className="w-2 shrink-0" />
        </div>
      </div>

      {/* Static sidebar for desktop */}
      <div className="group relative hidden md:flex md:shrink-0">
        <div
          className={cn(
            isMenuExpanded ? 'w-[15.3rem]' : 'w-14',
            'flex flex-col',
          )}
        >
          <div className="flex h-0 flex-1 flex-col border-r border-gray-200 bg-white dark:border-gray-800 dark:bg-gray-900">
            <div
              className={cn(
                isMenuExpanded ? '' : 'items-center',
                'flex flex-1 flex-col overflow-y-auto pb-4 pt-2',
              )}
            >
              <div
                className={cn(
                  isMenuExpanded ? 'px-4' : 'px-0',
                  'flex shrink-0 items-center dark:text-white',
                )}
              >
                {flowliePartner ? (
                  <SquaredLogo
                    containerClassName={cn(
                      isMenuExpanded ? 'h-[66px] w-[160px]' : 'w-full',
                    )}
                    alt={flowliePartner.name}
                    src={formatImageAddress(flowliePartner.logoKey)}
                  />
                ) : (
                  <img
                    className="h-12 w-auto"
                    src={flowlieLogo}
                    alt="flowlie"
                  />
                )}
                {isMenuExpanded && (
                  <div className="ml-2 flex flex-col">
                    <h1 className="-mb-2.5 text-2xl font-bold tracking-wide">
                      flowlie
                    </h1>
                    <span className="-mb-1 -ml-1">
                      <SubscriptionTierPill />
                    </span>
                  </div>
                )}
              </div>

              {accountType === AccountTypes.Investor && (
                <InvestorNavigationLinks
                  flowlink={flowlink}
                  closeNav={closeNav}
                  notifications={notificationsMap}
                  isMenuExpanded={isMenuExpanded}
                />
              )}

              {accountType === AccountTypes.Startup && (
                <StartupNavigationLinks
                  flowlink={flowlink}
                  closeNav={closeNav}
                  isMenuExpanded={isMenuExpanded}
                />
              )}

              {getSharedLinks(isMenuExpanded)}
            </div>
            <div className="group flex shrink-0 border-t border-gray-200 p-2 dark:border-gray-800">
              <AccountDropDownMenu
                email={localStorageGetAccountEmail()}
                showFullName={isMenuExpanded}
              />
            </div>
          </div>
        </div>

        {/* Hover button to toggle isMenuExpanded */}
        <button
          onClick={() => setIsMenuExpanded((prev) => !prev)}
          className={cn(
            isMenuExpanded ? 'top-[4.5rem]' : 'top-11',
            'absolute -right-3 z-30 -translate-y-1/2 transform rounded-full border-2 bg-white p-0.5 opacity-0 transition-opacity hover:bg-blue-500 hover:text-white group-hover:opacity-100',
          )}
          title={isMenuExpanded ? 'Collapse menu' : 'Expand menu'}
        >
          {isMenuExpanded ? (
            <ChevronDoubleLeftIcon className="size-[1rem]" />
          ) : (
            <ChevronDoubleRightIcon className="size-[1rem]" />
          )}
        </button>
      </div>

      {/* Page content and open sidebar button */}
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            onClick={openNav}
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="app-nav-icon" />
          </button>
        </div>
        {/* Route content here */}
        {children}
      </div>
    </div>
  );
}

export default Navigation;
