import { Transition } from '@headlessui/react';
import React from 'react';

type SlideProps = {
  appear?: boolean;
  as?: any;
  show?: boolean;
  key?: any;
  children: React.ReactNode;
};

export function SlideUp({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      transition
      className={`data-[closed]:translate-y-24 data-[enter]:transform data-[leave]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-1000 data-[leave]:duration-1000 data-[enter]:ease-in-out data-[leave]:ease-in-out`}
    >
      {children}
    </Transition>
  );
}

export function SlideDown({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      transition
      className={`data-[closed]:-translate-y-24 data-[enter]:transform data-[leave]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-1000 data-[leave]:duration-1000 data-[enter]:ease-in-out data-[leave]:ease-in-out`}
    >
      {children}
    </Transition>
  );
}

export function SlideLeft({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      transition
      className={`data-[closed]:translate-x-24 data-[enter]:transform data-[leave]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-1000 data-[leave]:duration-1000 data-[enter]:ease-in-out data-[leave]:ease-in-out`}
    >
      {children}
    </Transition>
  );
}

export function SlideRight({
  appear = true,
  as = 'div',
  show = true,
  children,
  key,
}: SlideProps): JSX.Element {
  return (
    <Transition
      as={as}
      key={key}
      appear={appear}
      show={show}
      transition
      className={`data-[closed]:-translate-x-24 data-[enter]:transform data-[leave]:transform data-[closed]:opacity-0 data-[enter]:transition data-[leave]:transition data-[enter]:duration-1000 data-[leave]:duration-1000 data-[enter]:ease-in-out data-[leave]:ease-in-out`}
    >
      {children}
    </Transition>
  );
}
