import React, { useContext, useState } from 'react';
import {
  Route,
  useParams,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { localStorageGetAuthenticatedAccountType } from '/src/middleware/LocalStorage';
import { InvestorDetailView } from '/../libs/shared-types/src/types/view/InvestorDetailView';
import { toDashCase } from '/src/util/formatting/strings';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import TabsHeader from '/src/components/tabs/TabsHeader';
import PageLoadingSpinner from '/src/components/utility/PageLoadingSpinner';
import { NOT_FOUND_ROUTE } from '/src/constants/Routes';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import {
  hasFeatureAccess,
  StartupPaidFeatures,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import {
  SharedGetUnverifiedInvestorById,
  StartupAddInvestorToLists,
  StartupGetInvestorLists,
  StartupInvestorAccessActivity,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import InvestorDetailHeader from '../../investor/InvestorDetail/InvestorDetailHeader';
import InvestorDetailAboutCard from '../../investor/InvestorDetail/cards/InvestorDetailAboutCard';
import InvestorDetailContactCard from '../../investor/InvestorDetail/cards/InvestorDetailContactCard';
import InvestorDetailThesisCard from '../../investor/InvestorDetail/cards/InvestorDetailThesisCard';
import InvestorDetailValueAddCard from '../../investor/InvestorDetail/cards/InvestorDetailValueAddCard';
import {
  QueueListIcon,
  ArrowLongUpIcon,
  CurrencyDollarIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/20/solid';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import AddInvestorToListsDialog from '../../investor/ActiveDealflow/dialogs/AddInvestorToListsDialog';
import ModalWrapper from '/src/components/notifications/ModalWrapper';
import { useInvestorDatabaseContext } from '../../startup/InvestorDatabase/InvestorDatabaseContext';
import InvestorDetailFitScoreCard from '../../investor/InvestorDetail/cards/InvestorDetailFitScoreCard';
import InvestorActivityTab from '../../investor/InvestorDetail/tabs/InvestorActivityTab';
import { InvestorActivityTabView } from '../../../types/view/InvestorActivityTabView';
import { INVESTOR_LIST_BY_ID_KEY } from '/src/hooks/useGetInvestorListById';
import { useQueryClient } from '@tanstack/react-query';
import AddOrUpdateIntroPathDialog from '../../startup/MyNetwork/AddOrUpdateIntroPathDialog';
import { ToastConfiguration } from '/src/interfaces/ToastConfiguration';
import Toast from '/src/components/notifications/Toast';
import { ERROR, SUCCESS } from '/src/constants/SuccessMessages';
import ConnectionDetailIntroPathsTab from '../../startup/ConnectionDetail/tabs/ConnectionDetailIntroPathsTab';
import useGetViewSessionByConnection from '/src/hooks/useGetViewSessionByConnection';
import ViewSessionsByConnectionTab from '../../investor/InvestorDetail/tabs/ViewSessionsByConnectionTab';
import useGetInvestorIntroPathsByConnection from '../../../hooks/useGetInvestorIntroPathsByConnection';
import useGetConnectionNotes from '/src/hooks/useGetConnectionNotes';
import { TextEditorCallBackProps } from '/src/components/TextEditor/TextEditor';
import {
  AddOrUpdateConnectionNote,
  deleteConnectionNoteById,
} from '/src/services/startupService';
import ConnectionNoteTab from '/src/components/ConnectionNotes/ConnectionNoteTab';
import SimpleDialog from '/src/components/notifications/SimpleDialog';
import { cn } from '/src/util/cn';
import InvestorDetailMeetingsTab from '../../investor/InvestorDetail/tabs/InvestorDetailMeetingsTab';

function InvestorDetailRoute(): JSX.Element {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [investor, setInvestor] = useState<InvestorDetailView>();
  const { addInvestorToPipeline } = useInvestorDatabaseContext();
  const { subscriptionTier } = useContext(AccountMetadataContext);

  const [aggregateInvestorActivity, setAggregateInvestorActivity] =
    useState<InvestorActivityTabView>();
  const [isFirstActivityLoading, setIsFirstActivityLoading] = useState(true);
  const { pathname } = useLocation();
  const connectionDataType = InvestorDataType.Unverified;

  const {
    isLoading: isLoadingInvestorIntroPaths,
    introPathsTowardConnection,
    introPathsFacilitatedByConnection,
    totalCount: totalIntroPathCount,
    introPathsFacilitatedByConnectionCount,
    introPathsTowardConnectionCount,
    refetch: refetchInvestorIntroPaths,
  } = useGetInvestorIntroPathsByConnection({
    connectionDataType: InvestorDataType.Unverified,
    connectionId: id,
  });

  const {
    isFirstLoading: isViewSessionByConnectionFirstLoading,
    response: viewSessionByConnectionData,
    totalCount: viewSessionCount,
  } = useGetViewSessionByConnection({
    connectionDataType: InvestorDataType.Unverified,
    connectionId: id,
  });

  const {
    isFirstLoading: isConnectionNotesFirstLoading,
    isLoading: isConnectionNotesLoading,
    response: connectionNotesData,
    totalCount: connectionNotesCount,
    refetch: refetchConnectionNotes,
  } = useGetConnectionNotes({
    connectionDataType,
    connectionId: id,
  });

  const tabs = [
    { name: 'Profile' },
    { name: 'Activity' },
    { name: 'Views', stat: viewSessionCount },
    { name: 'Intro Paths', stat: totalIntroPathCount },
    { name: 'Notes', stat: connectionNotesCount },
    { name: 'Meetings' },
  ];

  const isOnConnectionNotesPage =
    pathname.split('/').at(-1) === tabs[4].name.toLowerCase();

  const canAccessFitScore = hasFeatureAccess(
    StartupPaidFeatures.InvestorFitScore,
    subscriptionTier,
  );

  const [investorLists, setInvestorLists] = useState<InvestorList[]>([]);
  const [modalConfig, setModalConfig] = useState<
    | {
        type: '' | 'lists' | 'introPath';
        isModalOpen: boolean;
      }
    | {
        type: 'deleteConnectionNote';
        isModalOpen: boolean;
        connectionNoteId: string;
      }
  >({
    type: '',
    isModalOpen: false,
  });

  const [toastConfig, setToastConfig] = useState<ToastConfiguration>();

  const queryClient = useQueryClient();

  async function fetchInvestorLists() {
    try {
      const data = await API.get<InvestorList[]>(
        StartupGetInvestorLists.buildEndpoint(),
      );
      setInvestorLists(data);
    } catch (error: any) {
      Logger.error(error.message);
    }
  }

  async function updateListsWithInvestor(
    investorId: string,
    investorListIds: string[],
  ) {
    try {
      await API.put(StartupAddInvestorToLists.buildEndpoint(), {
        investorId: investorId,
        investorDataType: InvestorDataType.Unverified,
        investorListIds: investorListIds,
      });
      closeModal();
      fetchInvestorLists();
      invalidateInvestorListDetailsData(investorListIds);
    } catch (error) {
      Logger.error(error);
    }
  }

  async function fetchData(isRefreshing = false) {
    if (id === undefined) {
      return;
    }

    setIsLoading(!isRefreshing);

    try {
      const investorData = await API.get<InvestorDetailView>(
        SharedGetUnverifiedInvestorById.buildEndpoint(id),
      );
      setInvestor(investorData);
      fetchInvestorLists();
      const investorActivityData: InvestorActivityTabView = await API.get(
        StartupInvestorAccessActivity.buildEndpoint(id, {
          investorType: investorData?.investorDataType,
        }),
      );
      setAggregateInvestorActivity(investorActivityData);
    } catch (error: any) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
      setIsFirstActivityLoading(false);
    }
  }

  const invalidateInvestorListDetailsData = (investorListIds: string[]) => {
    for (const investorListId of investorListIds) {
      queryClient.invalidateQueries({
        queryKey: [INVESTOR_LIST_BY_ID_KEY, investorListId],
      });
    }
  };

  async function handleUpdateOrSaveConnectionNote({
    id: connectionNoteId,
    content,
    title,
  }: TextEditorCallBackProps) {
    // update or create a note
    if (!isConnectionNotesLoading && id) {
      const { isSuccess, data: responseData } = await AddOrUpdateConnectionNote(
        {
          _id: connectionNoteId,
          content,
          title,
          connectionId: id,
          connectionDataType,
        },
      );

      if (isSuccess && responseData) {
        refetchConnectionNotes();
        return responseData;
      }
    }
  }

  async function handleDeleteConnectionNote(connectionNoteId: string) {
    if (!isConnectionNotesLoading) {
      const { isSuccess } = await deleteConnectionNoteById([connectionNoteId]);

      if (isSuccess) {
        refetchConnectionNotes();
        return true;
      }
    }
    return false;
  }

  // we listen for url changes and re-fetch data
  // if we get a new id
  React.useEffect(() => {
    fetchData();
  }, [id]);

  if (
    isFirstActivityLoading ||
    isViewSessionByConnectionFirstLoading ||
    isConnectionNotesFirstLoading
  ) {
    return <PageLoadingSpinner message="Loading a valued investor 🤝" />;
  }

  if (!investor) {
    return (
      <div className="min-h-screen bg-gray-100">
        <main className="py-10">
          {localStorageGetAuthenticatedAccountType() ===
            AccountTypes.Investor && (
            <h3>
              Oops! The investor either doesn&apos;t exist or you&apos;re not
              connected with them.
            </h3>
          )}
          {localStorageGetAuthenticatedAccountType() ===
            AccountTypes.Startup && (
            <h3>Oops! The investor you are looking for doesn&apos;t exist.</h3>
          )}
        </main>
      </div>
    );
  }

  function closeModal() {
    setModalConfig({ type: '', isModalOpen: false });
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <main
        className={cn(
          'pb-8',
          isOnConnectionNotesPage ? 'flex h-screen flex-col' : '',
        )}
      >
        <header className="sticky top-0 z-40 bg-gray-100 sm:pt-6">
          <div className="relative mx-auto max-w-full lg:flex lg:items-center lg:justify-between lg:space-x-5">
            <InvestorDetailHeader
              hasFitScoreAccess={canAccessFitScore}
              investor={investor}
              fetchInvestor={fetchData}
            />

            <div className="flex space-x-3">
              <button
                className="app-button--neutral mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'lists', isModalOpen: true })
                }
              >
                <div className="relative mr-2">
                  <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                  <ArrowLongUpIcon
                    className="absolute -bottom-0.5 -right-1 h-3.5 w-3.5 bg-white"
                    aria-hidden="true"
                  />
                </div>
                Add to Target Lists
              </button>
              {investor.pipelineCurrentStage === undefined && (
                <button
                  className="app-button--neutral mt-3 lg:mt-0"
                  onClick={async () => {
                    await addInvestorToPipeline(
                      investor._id,
                      InvestorDataType.Unverified,
                      undefined,
                    );
                    await fetchData(true);
                  }}
                >
                  <div className="relative mr-2">
                    <CurrencyDollarIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  Add to Pipeline
                </button>
              )}

              <button
                className="app-button--primary mt-3 lg:mt-0"
                onClick={() =>
                  setModalConfig({ type: 'introPath', isModalOpen: true })
                }
                disabled={
                  !hasFeatureAccess(
                    StartupPaidFeatures.IntroPath,
                    subscriptionTier,
                  )
                }
              >
                <div className="relative mr-2">
                  <ArrowsRightLeftIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                Add Intro Path
              </button>
            </div>
          </div>

          <TabsHeader tabs={tabs} />
        </header>
        <div className={cn('px-1', isOnConnectionNotesPage ? 'flex grow' : '')}>
          <>
            <Routes>
              {['', tabs[0].name].map((path) => (
                <Route
                  key={path}
                  path={toDashCase(path)}
                  element={
                    <main className="grid grid-cols-1 gap-4 lg:w-full lg:grid-cols-2">
                      <div className="flex flex-1 flex-col">
                        <div className="joyride-investorDetailThesis mt-4">
                          <InvestorDetailThesisCard investor={investor} />
                        </div>
                        <div className="mt-4">
                          <InvestorDetailValueAddCard investor={investor} />
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col space-y-4 pt-4">
                        <InvestorDetailFitScoreCard investor={investor} />
                        <InvestorDetailAboutCard investor={investor} />
                        <InvestorDetailContactCard
                          investor={investor}
                          isConnected={true}
                        />
                      </div>
                    </main>
                  }
                />
              ))}
              {aggregateInvestorActivity && investor?._id && (
                <Route
                  path={`${toDashCase(tabs[1].name)}/*`}
                  element={
                    <InvestorActivityTab
                      aggregateInvestorActivity={aggregateInvestorActivity}
                      investorId={investor._id}
                      investorName={
                        investor.firstName + ' ' + investor.lastName
                      }
                      investorDataType={investor.investorDataType}
                      fetchInvestorData={fetchData}
                    />
                  }
                />
              )}
              <Route
                path={`${toDashCase(tabs[2].name)}/*`}
                element={
                  <ViewSessionsByConnectionTab
                    apiResponse={viewSessionByConnectionData}
                    connectionEmail={investor.email}
                  />
                }
              />
              <Route
                path={`${toDashCase(tabs[3].name)}/*`}
                element={
                  <ConnectionDetailIntroPathsTab
                    connectionId={investor._id}
                    connectionDataType={investor.investorDataType}
                    connectionName={
                      investor.firstName + ' ' + investor.lastName
                    }
                    isLoading={isLoadingInvestorIntroPaths}
                    introPathsTowardThem={introPathsTowardConnection}
                    introPathsFacilitatedByThem={
                      introPathsFacilitatedByConnection
                    }
                    facilitatedByThemCount={
                      introPathsFacilitatedByConnectionCount
                    }
                    towardThemCount={introPathsTowardConnectionCount}
                    refetch={refetchInvestorIntroPaths}
                  />
                }
              />

              <Route
                path={`${toDashCase(tabs[4].name)}/*`}
                element={
                  <ConnectionNoteTab
                    connectionNotes={connectionNotesData}
                    isLoading={isConnectionNotesLoading}
                    onChange={handleUpdateOrSaveConnectionNote}
                    onDelete={(id: string) =>
                      setModalConfig({
                        connectionNoteId: id,
                        type: 'deleteConnectionNote',
                        isModalOpen: true,
                      })
                    }
                  />
                }
              />

              <Route
                path={`${toDashCase(tabs[5].name)}/*`}
                element={
                  <InvestorDetailMeetingsTab
                    investorDataType={InvestorDataType.Unverified}
                    investorId={investor._id}
                    investorName={investor.name}
                  />
                }
              />
              <Route
                path="*"
                element={<Navigate to={NOT_FOUND_ROUTE} replace />}
              />
            </Routes>
          </>
        </div>
      </main>

      <ModalWrapper open={modalConfig.isModalOpen} onClose={closeModal}>
        {modalConfig.type === 'lists' && (
          <AddInvestorToListsDialog
            investorLists={investorLists}
            onSave={updateListsWithInvestor}
            investorId={investor._id}
            investorName={investor.firstName + ' ' + investor.lastName}
            onCancel={closeModal}
          />
        )}

        {modalConfig.type === 'introPath' && (
          <AddOrUpdateIntroPathDialog
            onCancel={closeModal}
            onSave={() => {
              closeModal();
              refetchInvestorIntroPaths();
            }}
            currentId={investor._id}
            currentDataType={investor.investorDataType}
            currentName={`${investor.firstName} ${investor.lastName}`}
          />
        )}
        {modalConfig.type === 'deleteConnectionNote' && (
          <SimpleDialog
            onCancel={closeModal}
            onPrimaryAction={() => {
              handleDeleteConnectionNote(modalConfig.connectionNoteId);
              closeModal();
            }}
            title="Are you sure you want to delete this note?"
            text="This action cannot be undone."
            primaryAction="Delete Note"
            color="red"
          />
        )}
      </ModalWrapper>
      {toastConfig && (
        <Toast
          isShown={toastConfig !== undefined}
          onClose={() => setToastConfig(undefined)}
          title={toastConfig.isError ? ERROR : SUCCESS}
          isError={toastConfig.isError}
          text={toastConfig.message}
        />
      )}
    </div>
  );
}

export default InvestorDetailRoute;
