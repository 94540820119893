/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { CheckIcon } from '@heroicons/react/20/solid';
import DebugRender from '/src/components/utility/DebugRender';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'validationSchema'> {
  children: React.ReactNode;
  label: string;
  isOptional?: boolean;
  customLayout?: string;
}

interface FormikStepperProps extends FormikConfig<FormikValues> {
  showOptionalStepsCount?: boolean;
  children: React.ReactNode;
}

export function FormikStep({
  children,
  customLayout,
}: FormikStepProps): JSX.Element {
  return <div className={customLayout}>{children}</div>;
}

export function FormikStepper({
  children,
  showOptionalStepsCount = false,
  ...props
}: FormikStepperProps): JSX.Element {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormikStepProps>[];
  const [stepIndex, setStepIndex] = useState(0);
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();

  const currentChild = childrenArray[stepIndex];
  const maxStepsCountLabel = showOptionalStepsCount
    ? childrenArray.length
    : childrenArray.filter((x) => !x.props.isOptional).length;

  function isLastStep() {
    return stepIndex === childrenArray.length - 1;
  }

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          helpers.setSubmitting(true);
          if (isLastStep()) {
            await props.onSubmit(values, helpers);
            setCompleted(true);
          } else {
            setStepIndex((s) => s + 1);
            Object.keys(values).forEach((fieldName) =>
              helpers.setFieldTouched(fieldName, false)
            );
          }
          helpers.setSubmitting(false);
        }}
      >
        {({ values, errors, dirty, isSubmitting }) => (
          <Form autoComplete="off" className="flex flex-col justify-between">
            <WarnBeforeExit isEnabled={dirty} />
            <h2 className="login-signup-header text-center">
              {currentChild.props.label}
            </h2>

            {currentChild}

            <div className="mt-6 grid w-full grid-rows-2 gap-4 md:grid-rows-1">
              <span className="col-span-1 col-start-1 row-span-1 row-start-1">
                {stepIndex > 0 ? (
                  <button
                    className="app-button--neutral"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => setStepIndex((s) => s - 1)}
                  >
                    Back
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={navigateBack}
                    className="app-button--neutral"
                  >
                    Back
                  </button>
                )}
              </span>

              <span className="col-span-2 col-start-2 row-span-1 row-start-1 place-self-center md:col-span-1 md:col-start-2">
                <p className="text-base font-medium">
                  Step{' '}
                  {stepIndex + 1 >= maxStepsCountLabel
                    ? maxStepsCountLabel
                    : stepIndex + 1}{' '}
                  of {maxStepsCountLabel}
                </p>
              </span>

              <span className="col-span-4 col-start-1 row-span-1 row-start-2 auto-cols-max place-self-center md:col-span-1 md:col-start-3 md:row-span-1 md:row-start-1">
                <div className="relative flex">
                  <div
                    className="flex items-center justify-center"
                    aria-label="Progress"
                  >
                    <ol className="flex cursor-default items-center space-x-4">
                      {childrenArray
                        .filter(
                          (x) => showOptionalStepsCount || !x.props.isOptional
                        )
                        .map((step, index) => (
                          <li key={step.key}>
                            {index < stepIndex ? (
                              <div
                                className={`block h-4 w-4 rounded-full ${
                                  step.props.isOptional
                                    ? 'bg-yellow-400'
                                    : 'bg-blue-600'
                                }`}
                              >
                                <CheckIcon className="text-white" />
                                <span className="sr-only">
                                  {step.props.label}
                                </span>
                              </div>
                            ) : index === stepIndex ? (
                              <div
                                className="relative flex items-center justify-center"
                                aria-current="step"
                              >
                                <span
                                  className="absolute flex h-5 w-5 p-px"
                                  aria-hidden="true"
                                >
                                  <span
                                    className={`h-full w-full rounded-full ${
                                      step.props.isOptional
                                        ? 'bg-yellow-200'
                                        : 'bg-blue-200'
                                    }`}
                                  />
                                </span>
                                <span
                                  className={`relative block h-2.5 w-2.5 rounded-full ${
                                    step.props.isOptional
                                      ? 'bg-yellow-400'
                                      : 'bg-blue-600'
                                  }`}
                                  aria-hidden="true"
                                />
                                <span className="sr-only">
                                  {step.props.label}
                                </span>
                              </div>
                            ) : (
                              <div
                                className={`block h-2.5 w-2.5 rounded-full ${
                                  step.props.isOptional
                                    ? 'bg-yellow-300'
                                    : 'bg-gray-200'
                                }`}
                              >
                                <span className="sr-only">
                                  {step.props.label}
                                </span>
                              </div>
                            )}
                          </li>
                        ))}
                    </ol>
                  </div>
                  {currentChild.props.isOptional && (
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="absolute inset-x-0 top-6 text-gray-400 hover:text-gray-500 hover:underline"
                    >
                      Skip Step
                    </button>
                  )}
                </div>
              </span>

              <span className="col-span-1 col-start-4 row-span-1 row-start-1 text-right">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="app-button--primary"
                >
                  {isSubmitting
                    ? 'Submitting'
                    : isLastStep()
                    ? 'Complete'
                    : 'Continue'}
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </span>
            </div>

            {/* Debug Formik Values */}
            <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Can Submit Form:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre>Cannot submit if any of the following is true:</pre>
                  <pre>{`isSubmitting: ${isSubmitting}`}</pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-2">{JSON.stringify(values, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik File Upload:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-6">
                    {values.profilePic
                      ? JSON.stringify(
                          {
                            fileName: values.profilePic.name,
                            type: values.profilePic.type,
                            size: `${values.profilePic.size} bytes`,
                          },
                          null,
                          2
                        )
                      : null}
                  </pre>
                  <pre className="mb-6">
                    {values.logo
                      ? JSON.stringify(
                          {
                            fileName: values.logo.name,
                            type: values.logo.type,
                            size: `${values.logo.size} bytes`,
                          },
                          null,
                          2
                        )
                      : null}
                  </pre>
                  <pre className="mb-6">
                    {values.deck
                      ? JSON.stringify(
                          {
                            fileName: values.deck.name,
                            type: values.deck.type,
                            size: `${values.deck.size} bytes`,
                          },
                          null,
                          2
                        )
                      : null}
                  </pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Errors:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-2">{JSON.stringify(errors, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </>
  );
}
