import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  convertDatePickerInputToUtc,
  formatDatePickerInput,
  howLongBetween,
} from '/src/util/formatting/dates';
import { Round } from '/../libs/shared-types/src/types/model/Round';
import DateString from '/src/components/utility/DateString';
import DebugRender from '/src/components/utility/DebugRender';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import Logger from '/src/services/logger';
import RoundFieldNames from '/src/enums/RoundFieldNames';
import isInputShown from './InputShown';
import { ROUND_MANAGER_ROUND_FIELD_MAPPING } from './RoundFormFieldMapping';
import {
  LeadInvestor,
  PercentSold,
  RemainingAllocation,
  RemainingTime,
  RevenueMultiple,
  TermSheet,
} from './RoundStatistics';
import { calculatePercentFunded } from '/../libs/shared-types/src/util/RoundCalculations';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import WarnBeforeExit from '/src/components/utility/WarnBeforeExit';
import FormCard, { FormSection } from '/src/components/FormCard';
import Alert from '/src/components/notifications/Alert';
import RoundView from '/../libs/shared-types/src/types/view/RoundView';
import { getLatestClosedRound } from '/src/util/rounds';
import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { formatRoundDisplayName } from '/../libs/shared-types/src/extensions/RoundExtensions';
import {
  abbreviateNumber,
  numberWithCommas,
} from '/src/util/formatting/numbers';
import { enumToList } from '/../libs/shared-types/src/extensions/SelectOptionsExtensions';
import { buildRound } from './BuildRound';
import { SlideUp } from '/src/components/animations/Slide';
import PremiumCTA from '../../../components/PremiumCTA';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { BoltIcon } from '@heroicons/react/20/solid';
import { joinClassNames } from '/src/util/formatting/strings';
import { PrivateStartup } from '/../libs/shared-types/src/types/view/startup/PrivateStartup';
import { StartupGet } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import { SafeType } from '/../libs/shared-types/src/constants/SafeType';
import { MARK_SALES_CALL } from '/src/constants/Marketing/CalendlyLinks';
import { FlowLink } from '/../libs/shared-types/src/types/model/FlowLink';
import { StartupOnePagerFeatures } from '/../libs/shared-types/src/constants/StartupOnePagerFeatures';
import LiveOnOnePagerTag from '/src/components/Tags/LiveOnOnePagerTag';

const sections: FormSection[] = [
  {
    title: 'Funding Progress',
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RaiseTarget],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RaisedAmount],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasLeadInvestor],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasTermSheet],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.EstimatedCloseDate],
    ],
  },
  {
    title: 'Structure',
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.IsBridge],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RoundStage],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.BridgeNumber],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RoundType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.SafeType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.RunwayTarget],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.OpenToInvestorTypes],
    ],
  },
  {
    title: 'Terms',
    fields: [
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ValuationCap],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ValuationCapType],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.PricePerShare],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Discount],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsMfn],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Interest],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.ConversionTerm],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.Prepayment],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.LiquidationPreference],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[
        RoundFieldNames.LiquidationParticipation
      ],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsPayToPlay],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.AntidilutionRights],
      ROUND_MANAGER_ROUND_FIELD_MAPPING[RoundFieldNames.HasRightsDragAlong],
    ],
  },
];

interface ActiveRoundProps {
  flowLink?: FlowLink;
  isEditable: boolean;
  onSubmit: (values: Round) => Promise<any>;
  round: RoundView | Round;
  roundHistory: RoundView[];
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>;
  toggleConfirmCloseRoundModal: () => void;
}

function ActiveRound({
  flowLink,
  isEditable,
  onSubmit,
  round,
  roundHistory,
  setIsEditable,
  toggleConfirmCloseRoundModal,
}: ActiveRoundProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const [initialValues, setInitialValues] = useState<any>(
    formatRoundForForm(round),
  );
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [startup, setStartup] = useState<PrivateStartup>();

  const latestClosedRoundStage = getLatestClosedRound(roundHistory)?.roundStage;

  const isOverSubscribed =
    round.raiseTarget &&
    round.raisedAmount &&
    Number(round.raisedAmount) > Number(round.raiseTarget);

  function formatRoundWithDateInput(roundToFormat: Round) {
    return {
      ...roundToFormat,
      [RoundFieldNames.EstimatedCloseDate]: formatDatePickerInput(
        roundToFormat[RoundFieldNames.EstimatedCloseDate] ?? 0,
      ),
    };
  }

  function formatRoundForForm(roundToFormat: Round) {
    return {
      ...formatRoundWithDateInput(roundToFormat),
      [RoundFieldNames.BridgeNumber]: roundToFormat.bridgeNumber?.toString(),
      [RoundFieldNames.IsEditable]: isEditable.toString(),
    };
  }

  async function fetchStartup() {
    try {
      const data = await API.get<PrivateStartup>(StartupGet.buildEndpoint());
      setStartup(data);
    } catch (error) {
      Logger.error(error);
    }
  }

  async function onSubmitWrapper(
    values: any,
    { setSubmitting, setFieldValue }: FormikHelpers<any>,
  ): Promise<void> {
    setFieldValue(RoundFieldNames.IsEditable, 'false');
    try {
      setSubmitting(true);
      await onSubmit(values);
      setIsEditable(false);
    } catch (error) {
      Logger.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    setInitialValues({
      ...formatRoundForForm(round),
      [RoundFieldNames.RoundHistory]: roundHistory,
    });

    fetchStartup();

    const timer = setTimeout(() => {
      setProgressBarWidth((round.raisedAmount / round.raiseTarget) * 100);
    }, 100);
    return () => clearTimeout(timer);
  }, [round]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={sections
          .map((section) => section.fields)
          .flat()
          .map((field) => field.validation)
          .reduce((merged, schema) => merged.concat(schema))}
        onSubmit={onSubmitWrapper}
      >
        {({
          initialValues,
          values,
          resetForm,
          errors,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form className="my-4">
            <WarnBeforeExit isEnabled={dirty} />

            <div className="mb-4 bg-white p-0.5 shadow sm:rounded-md 2xl:mb-0 2xl:min-w-[700px]">
              <header className="sticky top-0 z-40 rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <div className="items-center justify-between space-y-4 sm:flex sm:flex-row">
                  <div>
                    <h3 className="text-lg font-bold leading-6 text-gray-900">
                      {isEditable
                        ? 'Update Round Progress'
                        : `Your ${formatRoundDisplayName(
                            round.roundStage,
                            round.isBridge,
                            round.bridgeNumber,
                          )} Round`}
                    </h3>
                    <div className="mt-2 max-w-2xl space-x-2 text-sm text-gray-500">
                      {flowLink &&
                        !flowLink.excludedFromOnePager.includes(
                          StartupOnePagerFeatures.Round,
                        ) && <LiveOnOnePagerTag />}

                      {isOverSubscribed ? (
                        <span
                          className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                          title="This round is oversubscribed, you have exceeded your raise target"
                        >
                          Oversubscribed
                        </span>
                      ) : (
                        <></>
                      )}

                      {round.openedOn && (
                        <span>
                          Opened&nbsp;
                          <DateString date={round.openedOn} />
                          {howLongBetween(round.openedOn) !== 'Today' && (
                            <>({howLongBetween(round.openedOn)})</>
                          )}
                        </span>
                      )}
                    </div>
                  </div>

                  <aside className="flex flex-row">
                    {!isEditable && (
                      <>
                        <button
                          type="button"
                          className="app-button--primary mx-1"
                          onClick={() => {
                            setIsEditable(true);
                            setFieldValue(RoundFieldNames.IsEditable, 'true');

                            // The estimated close date may not be valid anymore
                            // Set the field as touched to show any error if data is invalid or missing
                            const allFieldNames = enumToList(RoundFieldNames);
                            allFieldNames.forEach((x) => {
                              setFieldTouched(x, true);
                            });
                          }}
                        >
                          Update Progress
                        </button>
                        <div className="tooltip-wrapper">
                          {round.roundType === RoundTypes.ToBeDetermined && (
                            <span className="tooltip tooltip-top">
                              To be determined rounds
                              <br />
                              cannot be closed
                            </span>
                          )}

                          <button
                            type="button"
                            className="app-button--red mx-1"
                            disabled={
                              round.roundType === RoundTypes.ToBeDetermined
                            }
                            onClick={toggleConfirmCloseRoundModal}
                          >
                            Close Round
                          </button>
                        </div>
                      </>
                    )}

                    {isEditable && (
                      <>
                        <button
                          type="button"
                          className="app-button--neutral mx-1"
                          onClick={() => {
                            resetForm();
                            setIsEditable(false);
                          }}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="app-button--green mx-1"
                          disabled={!isValid || !dirty || isSubmitting}
                        >
                          Save
                          {isSubmitting && (
                            <div className="ml-2">
                              <LoadingSpinner color="white" />
                            </div>
                          )}
                        </button>
                      </>
                    )}
                  </aside>
                </div>
              </header>

              {!isEditable && (
                <section className="items-center space-y-10 p-8">
                  <div>
                    <div className="mb-3 flex items-center justify-between font-bold">
                      <span>${numberWithCommas(round.raisedAmount)}</span>

                      <span className="flex">
                        {calculatePercentFunded(
                          round.raisedAmount,
                          round.raiseTarget,
                        )}
                        % raised of ${abbreviateNumber(round.raiseTarget)}{' '}
                        target
                      </span>
                    </div>

                    <div className="overflow-hidden rounded-full bg-gray-200">
                      <div
                        className="transition-width h-3 rounded-full bg-blue-600 delay-500 duration-1000 ease-in-out"
                        style={{
                          width: `${progressBarWidth}%`,
                        }}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-2 grid-rows-2 gap-y-3 lg:grid-cols-4 lg:grid-rows-1">
                    <RemainingAllocation round={buildRound(round)} />
                    <RemainingTime round={round} />
                    <LeadInvestor round={round} />
                    <TermSheet round={round} />
                  </div>
                  <Disclosure as="div">
                    {({ open }) => (
                      <>
                        <DisclosureButton
                          className={joinClassNames(
                            'flex justify-between rounded-lg px-3 py-1 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75',
                            open ? 'bg-purple-200' : 'bg-purple-100',
                          )}
                        >
                          <BoltIcon className="mr-1 mt-0.5 h-4 w-4" />
                          <span>Show Round Analysis</span>
                        </DisclosureButton>
                        <DisclosurePanel className="grid grid-cols-1 gap-x-12 gap-y-4 px-4 pb-2 pt-3 xl:grid-cols-2">
                          {round.roundType !== RoundTypes.ToBeDetermined &&
                            round.safeType !== SafeType.Uncapped && (
                              <>
                                <PercentSold round={round} />
                                <RevenueMultiple
                                  round={round}
                                  monthlyRevenue={startup?.revenue}
                                />
                              </>
                            )}

                          {round.roundType === RoundTypes.ToBeDetermined && (
                            <p className="col-span-full">
                              Select a fundraising instrument for the round to
                              unlock the analysis.
                            </p>
                          )}
                          {round.safeType === SafeType.Uncapped && (
                            <p className="col-span-full">
                              Dilution and revenue multiple cannot be calculated
                              because you are raising at an uncapped valuation.
                            </p>
                          )}
                        </DisclosurePanel>
                      </>
                    )}
                  </Disclosure>
                </section>
              )}

              {isEditable && (
                <>
                  <section className="content-end px-4 py-5 sm:px-6 lg:grid lg:grid-cols-2 lg:gap-x-10">
                    {sections[0].fields.map((field, index) => (
                      <Fragment key={index}>{field.fieldComponent}</Fragment>
                    ))}
                  </section>
                </>
              )}
            </div>

            {isEditable && (
              <section>
                <div className="my-4 bg-white p-0.5 shadow sm:rounded-md">
                  <div className="bg-white sm:rounded-md">
                    <header className="sticky top-0 z-40 rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                      <div className="flex justify-between">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Your {values[RoundFieldNames.RoundStage]} Round
                          Analysis
                        </h3>
                      </div>
                    </header>

                    <div className="grid grid-cols-1 gap-x-12 gap-y-4 px-4 py-5 sm:px-6 xl:grid-cols-2">
                      {isInputShown('percentSold', values) && (
                        <div className="col-span-1">
                          <PercentSold round={buildRound(values)} />
                        </div>
                      )}
                      {isInputShown('revenueMultiple', values) && (
                        <div className="col-span-1">
                          <RevenueMultiple
                            round={buildRound(values)}
                            monthlyRevenue={startup?.revenue}
                          />
                        </div>
                      )}
                      {values[RoundFieldNames.RoundType] ===
                        RoundTypes.ToBeDetermined && (
                        <p className="col-span-full">
                          Select a fundraising instrument for the round to
                          unlock the analysis.
                        </p>
                      )}
                      {values[RoundFieldNames.SafeType] ===
                        SafeType.Uncapped && (
                        <p className="col-span-full">
                          Dilution and revenue multiple cannot be calculated
                          because you are raising at an uncapped valuation.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            )}

            {isEditable && (
              <section className="space-y-4">
                {sections
                  .filter((x, index) => index !== 0)
                  .map((section, index) => (
                    <div key={'section_' + section.title}>
                      {index === 0 &&
                        values[RoundFieldNames.RoundStage] &&
                        initialValues[RoundFieldNames.RoundStage] !==
                          values[RoundFieldNames.RoundStage] &&
                        latestClosedRoundStage !==
                          values[RoundFieldNames.RoundStage] && (
                          <div className="my-3">
                            <Alert
                              alertType="Info"
                              color="blue"
                              content={
                                <>
                                  By changing the round stage your startup’s
                                  stage will be automatically updated to&nbsp;
                                  <b>{values[RoundFieldNames.RoundStage]}</b>,
                                  and your&nbsp;
                                  <b>
                                    Data Room Checklist will be reset for
                                    the&nbsp;
                                    {values[RoundFieldNames.RoundStage]} stage
                                  </b>
                                  .
                                </>
                              }
                              isShown={isEditable}
                              canDismiss={false}
                            />
                          </div>
                        )}
                      {(index !== 1 ||
                        values[RoundFieldNames.RoundType] !==
                          RoundTypes.ToBeDetermined) && (
                        <FormCard
                          key={section.title}
                          title={section.title}
                          fields={section.fields}
                          headerStyles="top-0"
                          fieldsLayout="py-5 grid grid-cols-1 gap-x-12 gap-y-4 sm:grid-cols-2 px-4 sm:px-6 xl:gap-x-48"
                        />
                      )}
                    </div>
                  ))}
                {subscriptionTier !== SubscriptionTiers.StartupPremium && (
                  <SlideUp>
                    <PremiumCTA
                      id="cta_update_round_consultation"
                      href={MARK_SALES_CALL}
                      content="Do you want to review the terms and progress of your
                     round with an expert? See if you qualify for our premium services, book a free call now."
                    />
                  </SlideUp>
                )}
              </section>
            )}

            <DebugRender className="my-4 flex flex-col space-y-2 text-xs">
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Can Submit Form:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre>Cannot submit if any of the following is true:</pre>
                  <pre>{`is not Valid: ${!isValid}`}</pre>
                  <pre>{`isSubmitting: ${isSubmitting}`}</pre>
                  <pre>{`not modified yet: ${!dirty}`}</pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-4">{JSON.stringify(values, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>

              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Errors:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-4">{JSON.stringify(errors, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ActiveRound;
