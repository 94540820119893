import { RoundTypes } from '/../libs/shared-types/src/constants/RoundTypes';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import { formatRoundDisplayName } from '/../libs/shared-types/src/extensions/RoundExtensions';
import { RoundOpenToInvestorType } from '/../libs/shared-types/src/constants/InvestorTypes';
import { numberWithCommas } from '/src/util/formatting/numbers';
import { Round } from '../../../types/model/Round';

function getRoundDisplayName(roundHelper: RoundHelperInfo): string {
  return formatRoundDisplayName(
    roundHelper.roundStage,
    roundHelper.isBridge,
    roundHelper.bridgeNumber,
  );
}

interface RoundHelperInfo
  extends Pick<Round, 'roundStage' | 'isBridge' | 'bridgeNumber'> {
  roundStage: RoundStages;
  isBridge: boolean;
  bridgeNumber?: number;
  raiseTargetMin: number;
  raiseTargetMax: number;
  runwayTargetMonthsMin: number;
  runwayTargetMonthsMax: number;
  roundTypes: RoundTypes[];
  openToInvestorTypes: RoundOpenToInvestorType[];
}

export function getHelperTextAvgRaiseTargetRanges(
  helper: RoundHelperInfo,
): string {
  return `The average ${getRoundDisplayName(
    helper,
  )} round is between $${numberWithCommas(
    helper.raiseTargetMin,
  )} and $${numberWithCommas(helper.raiseTargetMax)}`;
}

export function getHelperTextUsualRoundType(helper: RoundHelperInfo): string {
  // If it contains SAFE, the display string must be "SAFE (ASA in the UK)"
  const displayRoundTypes = helper.roundTypes.map((type) =>
    type === RoundTypes.Safe ? 'SAFE (ASA in the UK)' : type,
  );
  return `The usual ${getRoundDisplayName(
    helper,
  )} round uses a ${displayRoundTypes.join(' or a ')}`;
}

// A lead investor is usually
// Not required: Angel, Pre-Seed, all bridges
// Required: Seed, Series A, Series B, Series C, Series D, Series E, Series F
export function getHelperTextIsLeadRequired(helper: RoundHelperInfo): string {
  const isRequired =
    !helper.isBridge &&
    helper.roundStage !== RoundStages.Angel &&
    helper.roundStage !== RoundStages.PreSeed;

  const roundDisplayName = getRoundDisplayName(helper);

  return `A lead investor is usually ${
    !isRequired ? 'not ' : ''
  }required for a${
    roundDisplayName.startsWith('A') ? 'n' : ''
  } ${roundDisplayName} round`;
}

export function getHelperTextRunwayTarget(helper: RoundHelperInfo): string {
  return `The usual ${getRoundDisplayName(
    helper,
  )} round should provide at least ${helper.runwayTargetMonthsMin} to ${
    helper.runwayTargetMonthsMax
  } months of runway`;
}

export function getHelperTextOpenToInvestorTypes(
  helper: RoundHelperInfo,
): string {
  // It should join with a comma and a space, and the last two should be joined with " and "
  const allButLast = helper.openToInvestorTypes.slice(0, -1);
  const last = helper.openToInvestorTypes.slice(-1);
  const displayInvestorTypes =
    allButLast.join(', ') + (last.length ? ' and ' + last[0] : '');
  return `The usual ${getRoundDisplayName(
    helper,
  )} round primarily targets ${displayInvestorTypes}`;
}

export const roundHelper: RoundHelperInfo[] = [
  {
    roundStage: RoundStages.Angel,
    isBridge: false,
    raiseTargetMin: 100_000,
    raiseTargetMax: 300_000,
    roundTypes: [RoundTypes.Safe],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [
      RoundOpenToInvestorType.AngelIndividuals,
      RoundOpenToInvestorType.Accelerators,
    ],
  },
  {
    roundStage: RoundStages.Angel,
    isBridge: true,
    raiseTargetMin: 100_000,
    raiseTargetMax: 200_000,
    roundTypes: [RoundTypes.Safe],
    runwayTargetMonthsMin: 3,
    runwayTargetMonthsMax: 6,
    openToInvestorTypes: [RoundOpenToInvestorType.AngelIndividuals],
  },
  {
    roundStage: RoundStages.PreSeed,
    isBridge: false,
    raiseTargetMin: 400_000,
    raiseTargetMax: 1_500_000,
    roundTypes: [RoundTypes.Safe, RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 12,
    runwayTargetMonthsMax: 18,
    openToInvestorTypes: [
      RoundOpenToInvestorType.AngelIndividuals,
      RoundOpenToInvestorType.AngelSyndicates,
      RoundOpenToInvestorType.FamilyOffices,
      RoundOpenToInvestorType.VcFirms,
    ],
  },
  {
    roundStage: RoundStages.PreSeed,
    isBridge: true,
    raiseTargetMin: 200_000,
    raiseTargetMax: 400_000,
    roundTypes: [RoundTypes.Safe, RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 3,
    runwayTargetMonthsMax: 6,
    openToInvestorTypes: [
      RoundOpenToInvestorType.AngelIndividuals,
      RoundOpenToInvestorType.AngelSyndicates,
      RoundOpenToInvestorType.FamilyOffices,
    ],
  },
  {
    roundStage: RoundStages.Seed,
    isBridge: false,
    raiseTargetMin: 1_000_000,
    raiseTargetMax: 3_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 12,
    runwayTargetMonthsMax: 18,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.FamilyOffices,
      RoundOpenToInvestorType.AngelSyndicates,
    ],
  },
  {
    roundStage: RoundStages.Seed,
    isBridge: true,
    raiseTargetMin: 500_000,
    raiseTargetMax: 1_500_000,
    roundTypes: [RoundTypes.Safe, RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 3,
    runwayTargetMonthsMax: 6,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.FamilyOffices,
    ],
  },
  {
    roundStage: RoundStages.SeriesA,
    isBridge: false,
    raiseTargetMin: 5_000_000,
    raiseTargetMax: 12_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
      RoundOpenToInvestorType.FamilyOffices,
    ],
  },
  {
    roundStage: RoundStages.SeriesA,
    isBridge: true,
    raiseTargetMin: 1_000_000,
    raiseTargetMax: 2_500_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 3,
    runwayTargetMonthsMax: 6,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
  {
    roundStage: RoundStages.SeriesB,
    isBridge: false,
    raiseTargetMin: 10_000_000,
    raiseTargetMax: 20_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
    ],
  },
  {
    roundStage: RoundStages.SeriesB,
    isBridge: true,
    raiseTargetMin: 3_000_000,
    raiseTargetMax: 6_000_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
  {
    roundStage: RoundStages.SeriesC,
    isBridge: false,
    raiseTargetMin: 15_000_000,
    raiseTargetMax: 40_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
    ],
  },
  {
    roundStage: RoundStages.SeriesC,
    isBridge: true,
    raiseTargetMin: 5_000_000,
    raiseTargetMax: 7_500_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
  {
    roundStage: RoundStages.SeriesD,
    isBridge: false,
    raiseTargetMin: 25_000_000,
    raiseTargetMax: 75_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
    ],
  },
  {
    roundStage: RoundStages.SeriesD,
    isBridge: true,
    raiseTargetMin: 5_000_000,
    raiseTargetMax: 15_000_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
  {
    roundStage: RoundStages.SeriesE,
    isBridge: false,
    raiseTargetMin: 50_000_000,
    raiseTargetMax: 100_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
    ],
  },
  {
    roundStage: RoundStages.SeriesE,
    isBridge: true,
    raiseTargetMin: 10_000_000,
    raiseTargetMax: 25_000_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
  {
    roundStage: RoundStages.SeriesF,
    isBridge: false,
    raiseTargetMin: 50_000_000,
    raiseTargetMax: 100_000_000,
    roundTypes: [RoundTypes.PricedPreferred],
    runwayTargetMonthsMin: 18,
    runwayTargetMonthsMax: 24,
    openToInvestorTypes: [
      RoundOpenToInvestorType.VcFirms,
      RoundOpenToInvestorType.VcCorporateFirms,
    ],
  },
  {
    roundStage: RoundStages.SeriesF,
    isBridge: true,
    raiseTargetMin: 10_000_000,
    raiseTargetMax: 25_000_000,
    roundTypes: [RoundTypes.ConvertibleNote],
    runwayTargetMonthsMin: 6,
    runwayTargetMonthsMax: 12,
    openToInvestorTypes: [RoundOpenToInvestorType.VcFirms],
  },
];
