import React, { Fragment, useContext } from 'react';
import { StartupUpdateDataRoomChecklist } from '/../libs/shared-types/src/constants/ApiRoutes';
import API from '/src/middleware/API';
import Logger from '/src/services/logger';
import FormCard from '/src/components/FormCard';
import { RoundStages } from '/../libs/shared-types/src/constants/RoundStages';
import {
  getDataRoomTasksPerRoundStage,
  getDataRoomGroupedTasksPerRoundStage,
} from '/../libs/shared-types/src/constants/DataRoom/DataRoomTasksByRoundStage';
import { getDataRoomTaskView } from '/../libs/shared-types/src/constants/DataRoom/DataRoomTasksViewMap';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Tooltip from '/src/components/utility/Tooltip';
import DebugRender from '/src/components/utility/DebugRender';
import { DataRoomView } from '/../libs/shared-types/src/types/view/APIResponse';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { DataRoomTasks } from '/../libs/shared-types/src/constants/DataRoom/DataRoomTasks';
import {
  StartupPaidFeatures,
  hasFeatureAccess,
} from '/../libs/shared-types/src/extensions/SubscriptionAccess';
import { SubscriptionCTAPill } from '/src/components/SubscriptionCTA';
import { SlideUp } from '/src/components/animations/Slide';
import { AccountMetadataContext } from '/src/contexts/AccountMetadataContext';
import { joinClassNames } from '/src/util/formatting/strings';
import PremiumCTA from '../../../components/PremiumCTA';
import { BILLING_ROUTE } from '/src/constants/Routes';
import { getBaseAppUrl } from '/src/util/urls';
import { SubscriptionTiers } from '/../libs/shared-types/src/constants/SubscriptionTiers';
import { MARK_SALES_CALL } from '/src/constants/Marketing/CalendlyLinks';

const placeholderLabels = [
  'Placeholder',
  'Label',
  'Example',
  'Placeholder label',
  'Pro Feature',
  'Placeholder label with extra words',
  'You do not have access to this',
];

const getRandomPlaceholderLabel = () => {
  const randomIndex = Math.floor(Math.random() * placeholderLabels.length);
  return placeholderLabels[randomIndex];
};

function getCheckboxes(
  roundStage: RoundStages,
  isDisabled: boolean,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<unknown>>,
  submitForm: (() => Promise<void>) & (() => Promise<any>),
): JSX.Element[] {
  const groupedTasks = getDataRoomGroupedTasksPerRoundStage(roundStage);
  const fields = groupedTasks.map((group) => {
    return (
      <fieldset key={group.group} className="text-lg font-bold">
        <legend className="mb-3 font-medium">{group.group}</legend>
        <div className="ml-4 space-y-5">
          {group.tasks.map((task) => {
            const taskView = isDisabled
              ? {
                  id: task,
                  label: getRandomPlaceholderLabel(),
                  secondaryLabel: undefined,
                  tooltip:
                    "placeholder tooltip. You don't have access to this feature.",
                  isOptional: false,
                }
              : getDataRoomTaskView(task, roundStage);
            return (
              <div key={task} className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <Field
                    id={task}
                    name={task}
                    type="checkbox"
                    disabled={isDisabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { checked } = e.target;
                      setFieldValue(task, checked, false);
                      submitForm();
                    }}
                    className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor={task}
                    className="text-base font-medium text-gray-900"
                  >
                    <span className="flex items-center space-x-3">
                      {taskView?.label}
                      {taskView?.isOptional && (
                        <span className="ml-1 text-gray-500">(Optional)</span>
                      )}
                      {taskView?.tooltip && (
                        <Tooltip tooltipText={taskView?.tooltip ?? ''} />
                      )}
                    </span>
                  </label>
                  <DebugRender>
                    <pre className="text-xs font-normal">taskId: {task}</pre>
                  </DebugRender>
                </div>
              </div>
            );
          })}
        </div>
      </fieldset>
    );
  });

  return fields;
}

interface DataRoomChecklistProps {
  dataRoomView: DataRoomView;
}

function DataRoomChecklist({
  dataRoomView,
}: DataRoomChecklistProps): JSX.Element {
  const { subscriptionTier } = useContext(AccountMetadataContext);
  const hasDataRoomAccess = hasFeatureAccess(
    StartupPaidFeatures.DataRoom,
    subscriptionTier,
  );

  const totalTasksCount = getDataRoomTasksPerRoundStage(
    dataRoomView.currentStage,
  ).length;

  async function onSubmit(
    values: Record<DataRoomTasks, boolean>,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    try {
      setSubmitting(true);
      await API.put(StartupUpdateDataRoomChecklist.buildEndpoint(), {
        checklist: values,
      });
    } catch (error) {
      Logger.error(error);
    } finally {
      setSubmitting(false);
    }
  }

  const initialValues: any = {};
  dataRoomView.dataRoom.checklist.forEach((task) => {
    initialValues[task._id] = task.completedOn.getTime() !== MIN_DATE.getTime();
  });

  return (
    <div className="relative mb-8">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({})}
        onSubmit={onSubmit}
      >
        {({ values, submitForm, setFieldValue }) => (
          <Form className="my-4">
            <FormCard
              headerStyles="top-10"
              fieldsLayout={joinClassNames(
                'px-4 py-5 sm:px-6 grid grid-cols-1 gap-10 lg:grid-cols-2 2xl:grid-cols-3',
                hasDataRoomAccess
                  ? ''
                  : 'blur-[5px] select-none pointer-events-none',
              )}
              title={
                hasDataRoomAccess
                  ? `${dataRoomView.currentStage} Data Room Checklist (${Object.values(values).filter((x) => x === true).length} / ${Object.values(values).length} items)`
                  : `${dataRoomView.currentStage} Data Room Checklist (${totalTasksCount} items)`
              }
              fields={getCheckboxes(
                dataRoomView.currentStage,
                !hasDataRoomAccess,
                setFieldValue,
                submitForm,
              ).map((checkbox) => {
                return {
                  fieldComponent: checkbox,
                  validation: yup.object().shape({}),
                };
              })}
              description={[
                'A data room is a collection of documents that helps investors get up to speed on your company during the due diligence process',
                'A well-organized data room can impress investors and help you close your round faster',
                'It takes significant time and effort to prepare a data room, so it is important to start early and keep it up to date throughout fundraising',
                'Use this comprehensive checklist to ensure you have the right documents for your data room at your current round stage',
                'Flowlie provides a secure way to share your data room with the right individuals by protecting your data room link with a password and tracking everyone who accessed it',
              ]}
            />
            <DebugRender>
              <pre>totalTasksCount: {totalTasksCount}</pre>
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </DebugRender>
          </Form>
        )}
      </Formik>

      {subscriptionTier !== SubscriptionTiers.StartupPremium && (
        <SlideUp>
          <PremiumCTA
            id="cta_data_room_consultation"
            href={MARK_SALES_CALL}
            content={`Do you want hands-on support to prepare for your ${dataRoomView.currentStage} fundraising? We can help! See if you qualify for our premium services, book a free call now.`}
          />
        </SlideUp>
      )}

      {!hasDataRoomAccess && (
        <SlideUp as={Fragment}>
          <div className="absolute left-0 right-0 top-[50%] z-30 space-y-4 rounded-lg border-2 border-gray-200 bg-white p-8 shadow-md sm:left-[20%] sm:top-[30%] sm:w-[60%]">
            <p className="font-medium">
              Accelerate the due diligence process and build investor trust by
              providing easy access to all critical documents in one secure
              location.
            </p>
            <p>
              Unlock the data room checklist and learn how to prepare all the
              relevant documents for your fundraising stage.
            </p>

            <div className="w-max">
              <SubscriptionCTAPill id="cta_data_room_checklist" />
            </div>
          </div>
        </SlideUp>
      )}
    </div>
  );
}

export default DataRoomChecklist;
