import React from 'react';
import { StartupAccessManagerViewSessionView } from '../../../../../types/view/StartupAccessManagerView';

export interface AccessManagerViewSessionsReceivedFromCellProps {
  viewSession: StartupAccessManagerViewSessionView;
}

function AccessManagerViewSessionsReceivedFromCell({
  viewSession,
}: AccessManagerViewSessionsReceivedFromCellProps) {
  return (
    <>
      <div className="app-table-row-text">{viewSession.receivedFrom.name}</div>
      <div className="app-table-row-text--light">
        {viewSession.receivedFrom.firm ?? '-'}
      </div>
    </>
  );
}

export default AccessManagerViewSessionsReceivedFromCell;
