// In Mega bytes
export const MAX_IMAGE_SIZE = 2;

// In Mega bytes
export const MAX_DECK_SIZE = 40;

// In Mega bytes
export const MAX_TRANSCRIPT_SIZE = 1;

export const MAX_TRANSCRIPT_PAGES = 75;

export enum FileType {
  Image = '.png, .gif, .jpeg, .jpg',
  Pdf = '.pdf',
  Txt = '.txt',
  TxtPlain = '.plain',
}
