import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ACTIVE_DEALFLOW_ROUTE,
  INVESTOR_ACCESS_REQUESTS_ROUTE,
  INVESTOR_CONTACTS_ROUTE,
  INVESTOR_FLOWLINK_MANAGER_ROUTE,
  INVESTOR_PROFILE_ROUTE,
  PASSED_ROUTE,
  PORTFOLIO_ROUTE,
  STARTUP_ACCESS_MANAGER_ROUTE,
  STARTUP_DILUTION_CALCULATOR_ROUTE,
  STARTUP_FLOWLINK_MANAGER_ROUTE,
  STARTUP_INVESTOR_LEADS_ROUTE,
  STARTUP_PROFILE_PREVIEW_ROUTE,
  STARTUP_PROFILE_ROUTE,
  STARTUP_ROUND_MANAGER_ROUTE,
  SCREENING_ROUTE,
  STARTUP_DECK_MANAGER_ROUTE,
  SETTINGS_ROUTE,
  STARTUP_PERKS_ROUTE,
  STARTUP_DASHBOARD_ROUTE,
  REFERRALS_ROUTE,
  START_ACTIVATION_TOUR_ROUTE,
  UNVERIFIED_INVESTOR_DETAIL,
  INVESTOR_DETAIL,
  STARTUP_INVESTOR_LIST_DETAIL_ROUTE,
  STARTUP_DATA_ROOM_ROUTE,
  STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
  STARTUP_MY_NETWORK_ROUTE,
  STARTUP_PERSON_DETAIL_ROUTE,
  STARTUP_MANAGE_INTRO_PATHS_ROUTE,
  STARTUP_MEETING_DETAIL_ROUTE,
} from '/src/constants/Routes';

import ProtectedRoute from '/src/components/utility/ProtectedRoute';

import InvestorEditProfileForm from '/src/components/InvestorForm/InvestorEditProfileForm';
import StartupEditProfileForm from '/src/components/StartupForm/StartupEditProfileForm';
import AccessManagerRoute from './startup/AccessManager/AccessManagerRoute';
import ActiveDealflowRoute from './investor/ActiveDealflow/ActiveDealflowRoute';
import ContactsRoute from './investor/Connections/ConnectionsRoute';
import DealAccessRequests from './investor/DealAccessRequests/DealAccessRequestsRoute';
import DealDetailRoute from './investor/DealDetail/DealDetailRoute';
import DealPreviewRoute from './investor/DealAccessRequests/DealPreviewRoute';
import InvestorDetailRoute from './investor/InvestorDetail/InvestorDetailRoute';
import InvestorFlowLinkRoute from './investor/InvestorFlowLink/InvestorFlowLinkRoute';
import InvestorDatabaseRoute from './startup/InvestorDatabase/InvestorDatabaseRoute';
import NotFoundRoute from './common/NotFound/NotFoundRoute';
import PassedRoute from './investor/Passed/PassedRoute';
import PortfolioRoute from './investor/Portfolio/PortfolioRoute';
import RoundManagerRoute from './startup/RoundManager/RoundManagerRoute';
import StartupFlowLinkRoute from './startup/StartupFlowLink/StartupFlowLinkRoute';
import ScreeningRoute from './investor/Screening/ScreeningRoute';
import StartupDilutionCalculatorRoute from './startup/RoundManager/DilutionCalculatorRoute';
import { AccountTypes } from '/../libs/shared-types/src/constants/AccountTypes';
import { formatRoute } from '/src/util/formatting/routes';
import StartupProfilePreview from './startup/ProfilePreview/StartupProfilePreview';
import DeckManagerRoute from './startup/DeckManager/DeckManagerRoute';
import SettingsRoute from './common/Settings/SettingsRoute';
import StartupPartnerOffersRoute from './startup/RoundManager/PartnerOffersRoute';
import StartupDashboardRoute from './startup/Dashboard/StartupDashboardRoute';
import ReferralsRoute from './common/Referrals/ReferralsRoute';
import StartActivationTourRoute from './common/StartActivationTour/StartActivationTourRoute';
import UnverifiedInvestorDetailRoute from './UnverifiedInvestor/UnverifiedInvestorDetail/UnverifiedInvestorDetailRoute';
import InvestorListDetailRoute from './startup/InvestorListDetail/InvestorListDetailRoute';
import DataRoomRoute from './startup/DataRoom/DataRoomRoute';
import { InvestorDatabaseProvider } from './startup/InvestorDatabase/InvestorDatabaseContext';
import InvestorFragmentDetailRoute from './investorFragment/investorFrragmentDetail/InvestorFragmentDetailRoute';
import MyNetworkRoute from './startup/MyNetwork/MyNetworkRoute';
import PersonDetailRoute from './person/personDetail/PersonDetailRoute';
import ManageIntrosRoute from './startup/ManageIntros/ManageIntrosRoute';
import MeetingDetailRoute from './startup/MeetingDetail/MeetingDetailRoute';

export default (
  <Routes>
    {/* Startup Routes */}
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_ACCESS_MANAGER_ROUTE}
        >
          <AccessManagerRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_ACCESS_MANAGER_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_DECK_MANAGER_ROUTE}
        >
          <DeckManagerRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_DECK_MANAGER_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_DATA_ROOM_ROUTE}
        >
          <DataRoomRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_DATA_ROOM_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_ROUND_MANAGER_ROUTE}
        >
          <RoundManagerRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_ROUND_MANAGER_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_MANAGE_INTRO_PATHS_ROUTE}
        >
          <ManageIntrosRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_MANAGE_INTRO_PATHS_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_INVESTOR_LEADS_ROUTE}
        >
          <InvestorDatabaseProvider>
            <InvestorDatabaseRoute />
          </InvestorDatabaseProvider>
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_INVESTOR_LEADS_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_MY_NETWORK_ROUTE}
        >
          <MyNetworkRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_MY_NETWORK_ROUTE, '/app/', true)}
    />

    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE}
        >
          <InvestorDatabaseProvider>
            <InvestorFragmentDetailRoute />
          </InvestorDatabaseProvider>
        </ProtectedRoute>
      }
      path={formatRoute(
        STARTUP_INVESTOR_FRAGMENT_DETAIL_ROUTE,
        '/app/',
        true,
        'id',
      )}
    />

    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_PERSON_DETAIL_ROUTE}
        >
          <PersonDetailRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_PERSON_DETAIL_ROUTE, '/app/', true, 'id')}
    />

    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_INVESTOR_LIST_DETAIL_ROUTE}
        >
          <InvestorDatabaseProvider>
            <InvestorListDetailRoute />
          </InvestorDatabaseProvider>
        </ProtectedRoute>
      }
      path={formatRoute(
        STARTUP_INVESTOR_LIST_DETAIL_ROUTE,
        '/app/',
        true,
        'id',
      )}
    />

    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_MEETING_DETAIL_ROUTE}
        >
          <MeetingDetailRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_MEETING_DETAIL_ROUTE, '/app/', true, 'id')}
    />

    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_DILUTION_CALCULATOR_ROUTE}
        >
          <StartupDilutionCalculatorRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_DILUTION_CALCULATOR_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_PERKS_ROUTE}
        >
          <StartupPartnerOffersRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_PERKS_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_FLOWLINK_MANAGER_ROUTE}
        >
          <StartupFlowLinkRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_FLOWLINK_MANAGER_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_PROFILE_ROUTE}
        >
          <StartupEditProfileForm />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_PROFILE_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_PROFILE_PREVIEW_ROUTE}
        >
          <StartupProfilePreview />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_PROFILE_PREVIEW_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Startup}
          path={STARTUP_DASHBOARD_ROUTE}
        >
          <StartupDashboardRoute />
        </ProtectedRoute>
      }
      path={formatRoute(STARTUP_DASHBOARD_ROUTE, '/app/', true)}
    />

    {/* Investor Routes */}
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Investor}
          path={INVESTOR_ACCESS_REQUESTS_ROUTE}
        >
          <DealAccessRequests />
        </ProtectedRoute>
      }
      path={formatRoute(INVESTOR_ACCESS_REQUESTS_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Investor}
          path={ACTIVE_DEALFLOW_ROUTE}
        >
          <ActiveDealflowRoute />
        </ProtectedRoute>
      }
      path={formatRoute(ACTIVE_DEALFLOW_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Investor}
          path={PORTFOLIO_ROUTE}
        >
          <PortfolioRoute />
        </ProtectedRoute>
      }
      path={formatRoute(PORTFOLIO_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute accountType={AccountTypes.Investor} path={PASSED_ROUTE}>
          <PassedRoute />
        </ProtectedRoute>
      }
      path={formatRoute(PASSED_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Investor}
          path={INVESTOR_CONTACTS_ROUTE}
        >
          <ContactsRoute />
        </ProtectedRoute>
      }
      path={formatRoute(INVESTOR_CONTACTS_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute accountType={AccountTypes.Investor}>
          <DealDetailRoute />
        </ProtectedRoute>
      }
      path="/deal-detail/:id/*"
    />
    <Route
      element={
        <ProtectedRoute accountType={AccountTypes.Investor}>
          <InvestorFlowLinkRoute />
        </ProtectedRoute>
      }
      path={formatRoute(INVESTOR_FLOWLINK_MANAGER_ROUTE, '/app/', true)}
    />
    <Route
      element={
        <ProtectedRoute accountType={AccountTypes.Investor}>
          <InvestorEditProfileForm />
        </ProtectedRoute>
      }
      path={formatRoute(INVESTOR_PROFILE_ROUTE, '/app/')}
    />
    <Route
      element={
        <ProtectedRoute accountType={AccountTypes.Investor}>
          <DealPreviewRoute />
        </ProtectedRoute>
      }
      path="/deal-preview/:requestId/*"
    />
    <Route
      element={
        <ProtectedRoute
          accountType={AccountTypes.Investor}
          path={SCREENING_ROUTE}
        >
          <ScreeningRoute />
        </ProtectedRoute>
      }
      path={formatRoute(SCREENING_ROUTE, '/app/')}
    />

    {/* Shared Routes */}
    <Route
      element={
        <ProtectedRoute>
          <InvestorDetailRoute />
        </ProtectedRoute>
      }
      path={formatRoute(INVESTOR_DETAIL, '/app/', true, 'id')}
    />
    <Route
      element={
        <ProtectedRoute>
          <InvestorDatabaseProvider>
            <UnverifiedInvestorDetailRoute />
          </InvestorDatabaseProvider>
        </ProtectedRoute>
      }
      path={formatRoute(UNVERIFIED_INVESTOR_DETAIL, '/app/', true, 'id')}
    />
    <Route
      element={
        <ProtectedRoute path={SETTINGS_ROUTE}>
          <SettingsRoute />
        </ProtectedRoute>
      }
      path={formatRoute(SETTINGS_ROUTE, '/app/', true)}
    />

    <Route
      element={
        <ProtectedRoute path={REFERRALS_ROUTE}>
          <ReferralsRoute />
        </ProtectedRoute>
      }
      path={formatRoute(REFERRALS_ROUTE, '/app/', true)}
    />

    <Route
      element={
        <ProtectedRoute path={START_ACTIVATION_TOUR_ROUTE}>
          <StartActivationTourRoute />
        </ProtectedRoute>
      }
      path={formatRoute(START_ACTIVATION_TOUR_ROUTE, '/app/', true)}
    />

    {/* Page not found */}
    <Route
      path="*"
      element={
        <ProtectedRoute>
          <NotFoundRoute />
        </ProtectedRoute>
      }
    />
  </Routes>
);
