import React, { useState } from 'react';
import * as yup from 'yup';
import LoadingSpinner from '/src/components/utility/LoadingSpinner';
import { InvestorList } from '/../libs/shared-types/src/types/model/InvestorList';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
  LIST_NAME_LENGTH_MAX,
  LONG_TEXTAREA_LENGTH_MAX,
} from '/../libs/shared-types/src/constants/TextLengthRanges';
import FormikInput from '/src/components/inputs/FormikInput';
import API from '/src/middleware/API';
import Alert from '/src/components/notifications/Alert';
import { joinClassNames } from '/src/util/formatting/strings';
import {
  StartupUpdateInvestorList,
  StartupCreateInvestorList,
} from '/../libs/shared-types/src/constants/ApiRoutes';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import DebugRender from '/src/components/utility/DebugRender';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import TextCharacterCounter from '/src/components/TextCharacterCounter';
import ToggleSwitchInput from '/src/components/inputs/ToggleSwitchInput';

enum FormFieldNames {
  InvestorLists = 'investorLists',
  Name = 'name',
  Description = 'description',
}

const emptyList = {
  _id: '',
  name: 'NEW LIST',
  createdOn: new Date(),
  startupId: '',
  investors: [],
  updatedOn: new Date(),
  shareSetting: {
    createdOn: new Date(),
    slug: '',
    disabledOn: new Date(),
    updatedOn: new Date(),
  },
};

const validationSchema = yup.object().shape({
  [FormFieldNames.Name]: yup
    .string()
    .max(
      LIST_NAME_LENGTH_MAX,
      `Must be at most ${LIST_NAME_LENGTH_MAX} characters`,
    )
    .required('Required'),
  [FormFieldNames.Description]: yup
    .string()
    .nullable()
    .max(
      LONG_TEXTAREA_LENGTH_MAX,
      `Must be at most ${LONG_TEXTAREA_LENGTH_MAX} characters`,
    ),
});

interface EditInvestorListDialogProps {
  investorList?: InvestorList;
  onCancel: () => void;
  onSave: (newList: InvestorList) => void;
}

function EditInvestorListDialog({
  investorList,
  onCancel,
  onSave,
}: EditInvestorListDialogProps): JSX.Element {
  const [initialValues, setInitialValues] = useState<any>({
    ...emptyList,
    ...investorList,
    isSharingEnabled:
      investorList?.shareSetting?.disabledOn?.getTime() ===
      new Date(MIN_DATE).getTime(),
  });
  const [errorMessage, setErrorMessage] = useState('');

  async function onSubmit(
    data: InvestorList,
    { setSubmitting }: FormikHelpers<any>,
  ) {
    const cleanData = {
      _id: data._id ? data._id : undefined,
      name: data.name,
      description: data.description,
    };
    try {
      setSubmitting(true);
      // If we have a valid Id, we are updating an existing list
      const result = cleanData._id
        ? await API.put<InvestorList>(
            StartupCreateInvestorList.buildEndpoint(cleanData._id),
            {
              investorList: cleanData,
            },
          )
        : await API.post<InvestorList>(
            StartupUpdateInvestorList.buildEndpoint(),
            {
              investorList: cleanData,
            },
          );
      onSave(result);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="w-screen bg-white p-4 sm:max-w-3xl sm:p-7">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, isSubmitting, isValid }) => (
          <Form>
            <header className="relative mb-6 flex items-center justify-between">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {!initialValues._id ? 'Create' : 'Edit'} Target List
              </h3>

              <div>
                <button
                  type="button"
                  onClick={() => onCancel()}
                  className="app-button--neutral"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="app-button--green ml-3"
                  disabled={!isValid || isSubmitting}
                >
                  Save
                  {isSubmitting && (
                    <div className="ml-2">
                      <LoadingSpinner color="white" />
                    </div>
                  )}
                </button>
              </div>
            </header>

            <section>
              <Field
                component={FormikInput}
                label="Name"
                name={FormFieldNames.Name}
                type="text"
              />
              <Field
                component={FormikInput}
                boxType="textarea"
                customStyle="h-24 max-h-28"
                label="Description"
                name={FormFieldNames.Description}
                type="text"
                tooltip="Optionally add some context for this target list, such as what you are trying to accomplish with it or what kinds of investors you are targeting"
                placeholder="Add a short description for this list..."
              />
              <TextCharacterCounter
                textLength={values[FormFieldNames.Description]?.length ?? 0}
                maxLength={LONG_TEXTAREA_LENGTH_MAX}
              />
            </section>

            <div className="my-4">
              <Alert
                color="red"
                alertType="Error"
                content={errorMessage}
                isShown={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
              />
            </div>

            <DebugRender>
              <Disclosure>
                <DisclosureButton className="app-button--neutral">
                  <pre>Formik Values:</pre>
                </DisclosureButton>
                <DisclosurePanel>
                  <pre className="mb-24">{JSON.stringify(values, null, 2)}</pre>
                </DisclosurePanel>
              </Disclosure>
            </DebugRender>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditInvestorListDialog;
