import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import JoyRide, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';

import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import { SharedStateContext } from '../../contexts/SharedStateContext';
import { Tour } from '/../libs/shared-types/src/types/model/Tour';
import API from '/src/middleware/API';
import confetto from 'confetto';
import Logger from '/src/services/logger';
import AuthService from '/src/services/AuthService';

/**
 *
 * @param tourId The Id of the tour to check
 * @returns True if the tour has been completed, False otherwise
 */
async function isTourCompleted(
  tourId: string,
  apiRoute: string,
): Promise<boolean> {
  const tours = await API.get<Tour[]>(apiRoute);

  const activationTour = tours.find((tour) => tour._id === tourId);

  if (activationTour) {
    return activationTour.completedOn.getTime() !== MIN_DATE.getTime();
  }

  // We should never get here. If the tour does not exist, check that the migrations have run
  Logger.error(`${apiRoute} - Activation Tour with Id: ${tourId} not found!`);

  return false;
}

export interface ActivationStep extends Step {
  redirectUrl: string;
}

interface ActivationTourProps {
  localStorageKey: string;
  steps: ActivationStep[];
  tourApiRoute: string;
  tourId: string;
  onTourFinished: () => void;
}

function ActivationTour({
  tourId,
  tourApiRoute,
  steps,
  localStorageKey,
  onTourFinished,
}: ActivationTourProps): JSX.Element {
  const navigate = useNavigate();
  const {
    joyrideActivationTourState,
    setJoyrideActivationTourState,
    isActivationTourStopped,
    setIsActivationTourStopped,
  } = useContext(SharedStateContext);

  const stopTour = () =>
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: false,
    });

  const startTour = () =>
    setJoyrideActivationTourState({
      ...joyrideActivationTourState,
      run: true,
    });

  useEffect(() => {
    const checkTourAndStartIt = async () => {
      if (!AuthService.isLoggedIn()) {
        return;
      }

      const isCompleted = await isTourCompleted(tourId, tourApiRoute);

      if (isCompleted) {
        setIsActivationTourStopped(true);
        localStorage.removeItem(localStorageKey);
      }

      if (!isCompleted && !isActivationTourStopped) {
        navigate(steps.at(0)?.redirectUrl ?? '');
        startTour();
        localStorage.setItem(localStorageKey, 'true');
      }
    };

    checkTourAndStartIt();
  }, []);

  useEffect(() => {
    window.onpopstate = (event) => {
      stopTour();
      setIsActivationTourStopped(true);
      localStorage.removeItem(localStorageKey);
    };
  }, []);

  const callback = async (data: CallBackProps) => {
    const { action, index, type, status } = data;
    // Logger.info('Tour Callback', action, index, type, status);
    if (isActivationTourStopped) {
      Logger.info('isActivationTourStopped');
      return;
    }

    if (
      (status === STATUS.SKIPPED && joyrideActivationTourState.run) ||
      status === STATUS.FINISHED
    ) {
      // call the API to update the tour status
      await API.put(tourApiRoute, {
        tourId: tourId,
        isSkipped: STATUS.SKIPPED === status || false,
      });
      stopTour();

      localStorage.removeItem(localStorageKey);
      onTourFinished();
      confetto();
      setIsActivationTourStopped(true);
    } else if (type === EVENTS.STEP_AFTER && status !== STATUS.PAUSED) {
      const nextStep = steps.at(index + 1);
      // Logger.info('nextStep', nextStep);
      if (nextStep) {
        navigate(nextStep.redirectUrl);
      }

      setJoyrideActivationTourState({
        ...joyrideActivationTourState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        run: false,
      });
    }
  };

  return isActivationTourStopped ? (
    <></>
  ) : (
    <JoyRide
      {...joyrideActivationTourState}
      steps={steps}
      callback={callback}
      showSkipButton={false}
      styles={{
        options: {
          primaryColor: 'rgba(37, 99, 235)',
        },
      }}
    />
  );
}

export default ActivationTour;
