import React from 'react';
import BasePaginatedTable from '/src/components/table/baseTable/BasePaginatedTable';
import {
  ComparableStartupAccessManagerViewSessionView,
  StartupAccessManagerViewSessionView,
} from '../../../../types/view/StartupAccessManagerView';
import {
  ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS,
  AccessManagerViewSessionPropsActionProps,
  AccessManagerViewSessionsMapper,
} from './AccessManagerViewSessionsMapper';
import { isRowBlurred } from '/src/components/table/BackendPaginatedTable';
import { getConnectionDetailUrl } from '/src/util/navigation';
import { useNavigate } from 'react-router-dom';
import { cn } from '/src/util/cn';
import { PaginatedRequestParams } from '../../../../types/view/APIResponse';
import { rowBlurClass } from '/src/util/styles';

interface AccessManagerViewSessionsTableInterface {
  viewSessions: StartupAccessManagerViewSessionView[];
  pageCount: number;
  viewSessionsCount: number;

  requestParams: Required<
    Omit<
      PaginatedRequestParams<ComparableStartupAccessManagerViewSessionView>,
      'isReturnAllResults'
    >
  >;
  onImportInvestor: (viewSession: StartupAccessManagerViewSessionView) => void;
  blurRowsAfterIndex?: number;
}

function AccessManagerViewSessionsTable({
  viewSessions,
  pageCount,
  viewSessionsCount,
  requestParams,
  onImportInvestor,
  blurRowsAfterIndex,
}: AccessManagerViewSessionsTableInterface): JSX.Element {
  const navigate = useNavigate();

  const onRowClick = (rowKey: string, rowIndex: number) => {
    const viewSession = viewSessions?.find((x) => x.sessionId === rowKey);
    if (!viewSession) {
      return;
    }
    if (
      !isRowBlurred(requestParams.page, rowIndex, blurRowsAfterIndex) &&
      viewSession.connectionDataType &&
      viewSession.connectionId
    ) {
      const detailUrl = getConnectionDetailUrl(
        viewSession.connectionDataType,
        viewSession.connectionId,
      );
      navigate(detailUrl + '/views');
    }
  };

  const onAction = async ({
    type,
    payload,
  }: AccessManagerViewSessionPropsActionProps) => {
    if (type === ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS.IMPORT_INVESTOR) {
      onImportInvestor(payload.viewSession);
    }
  };

  const rows = new AccessManagerViewSessionsMapper(onAction).mapAllTo(
    viewSessions,
  );

  return (
    <BasePaginatedTable
      tableName="view-sessions-table"
      enableSort
      onRowClick={onRowClick}
      headerStructure={[
        {
          headerKey: 'name',
          label: 'Name',
        },
        {
          headerKey: 'lastViewedOn',
          label: 'Accessed',
          title: 'Latest time your profile was viewed',
        },
        {
          headerKey: 'totalViewTime',
          label: 'Duration',
          title: 'Total time spent viewing your profile',
        },
        {
          headerKey: 'deckViewed',
          label: 'Deck Viewed',
          title: 'Which deck version was viewed',
        },
        {
          headerKey: 'dataRoomViewStatus',
          label: 'Data Room Viewed',
          title: 'Whether the data room was viewed',
        },
        {
          headerKey: 'insights',
          label: 'Insights',
        },
        {
          headerKey: 'receivedFrom',
          label: 'Received From',
        },
        {
          headerKey: 'action',
          label: '',
          className: 'sr-only',
        },
      ]}
      rows={rows.map((row, index) => ({
        rowKey: row.id,
        data: row,
        className: cn(
          'bg-white',
          row.isClickable &&
            !isRowBlurred(requestParams.page, index, blurRowsAfterIndex)
            ? 'cursor-pointer hover:bg-gray-100'
            : 'select-none cursor-default hover:bg-white',
          rowBlurClass({
            parentPage: requestParams.page,
            index,
            blurRowsAfterIndex,
          }),
        ),
      }))}
      resultCount={viewSessionsCount}
      perPage={requestParams.perPage}
      page={requestParams.page}
      pageCount={pageCount}
      requestParams={{
        page: requestParams.page,
        perPage: requestParams.perPage,
        sortOrder: requestParams.sortOrder,
        sortKey: requestParams.sortKey,
        filter: requestParams.filter,
        fieldFilters: requestParams.fieldFilters,
      }}
    />
  );
}

export default AccessManagerViewSessionsTable;
