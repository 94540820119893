import React from 'react';
import { PipelineInvestorItemView } from '/../libs/shared-types/src/types/view/InvestorPipelineView';
import { MIN_DATE } from '/../libs/shared-types/src/constants/MinimumDate';
import CopyTextButton from '/src/components/CopyTextButton';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import TimeDurationString from '/src/components/utility/TimeDurationString';
import {
  howLongBetween,
  formatDate,
  formatDateTime,
} from '/src/util/formatting/dates';
import {
  splitOnWhiteSpace,
  formatImageAddress,
  joinClassNames,
} from '/src/util/formatting/strings';
import { generateTag, generateFitScoreTag } from '/src/util/generateTagCloud';
import DropdownButton from '/src/components/DropdownButton';
import { Menu, MenuButton, MenuItem } from '@headlessui/react';
import {
  ArrowLongUpIcon,
  ArrowsRightLeftIcon,
  CheckIcon,
  QueueListIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/react/20/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import SocialHandles, { SocialHandleName } from '/src/components/SocialHandles';
import { abbreviateNumber } from '/src/util/formatting/numbers';
import { InvestorDataType } from '/../libs/shared-types/src/constants/InvestorDataType';
import { Priority } from '/../libs/shared-types/src/constants/Priority';
import PrioritySelect from '/src/routes/investor/DealDetail/PrioritySelect';
import ConnectedOnLinkedInBadge from '/src/components/ConnectedOnLinkedInBadge';

interface InvestorPipelineTableRowProps {
  investorItem: PipelineInvestorItemView;
  onAssignInvestorToList: (investor: PipelineInvestorItemView) => void;
  onRemoveInvestor: (investor: PipelineInvestorItemView) => void;
  onUpdateInvestor: (investor: PipelineInvestorItemView) => void;
  onUpdateRelationship: (investor: PipelineInvestorItemView) => void;
  onPrioritySelected: (
    investor: PipelineInvestorItemView,
    priority: Priority,
  ) => void;
}

function InvestorPipelineTableRow({
  investorItem,
  onAssignInvestorToList,
  onRemoveInvestor,
  onUpdateInvestor,
  onUpdateRelationship,
  onPrioritySelected,
}: InvestorPipelineTableRowProps): JSX.Element {
  const meetingTasksCompleted = investorItem.meetingTasks.filter(
    (x) => x.completedOn.getTime() !== MIN_DATE.getTime(),
  );

  return (
    <>
      <td className="app-table-row-item">
        <div className="flex items-center">
          <div className="h-10 w-10 shrink-0">
            <UserAvatarWithInitials
              containerStyles="h-10 w-10"
              firstName={splitOnWhiteSpace(investorItem.name)[0]}
              lastName={splitOnWhiteSpace(investorItem.name)[1]}
              textStyles="text-xl"
              imgAlt="Investor Profile"
              imgSrc={formatImageAddress(investorItem.profilePicKey)}
            />
          </div>
          <div className="ml-2">
            <div className="app-table-row-text--bold flex items-center space-x-2">
              <span>{investorItem.name}</span>
              <ConnectedOnLinkedInBadge
                isConnectedOnLinkedIn={
                  investorItem.relationship?.isConnectedOnLinkedIn
                }
              />
              <span className="-mt-1.5">
                <SocialHandles
                  socialUrls={[
                    {
                      name: SocialHandleName.LinkedIn,
                      url: investorItem.linkedIn,
                    },
                    {
                      name: SocialHandleName.Other,
                      url: investorItem.website,
                    },
                  ]}
                />
              </span>
            </div>
            {investorItem.email && (
              <div className="text-sm text-gray-500">
                <a
                  className="truncate hover:underline"
                  href={`mailto:${investorItem.email}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {investorItem.email}
                </a>
                <CopyTextButton
                  text={investorItem.email}
                  className="absolute ml-1"
                />
              </div>
            )}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-4 py-4">
        {generateTag(investorItem.type, true)}
      </td>
      <td className="app-table-row-item">
        {investorItem.firm && investorItem.role ? (
          <>
            <div className="app-table-row-text">{investorItem.firm}</div>
            <div className="app-table-row-text--light">{investorItem.role}</div>
          </>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>

      <td>
        <span className="flex flex-row items-center">
          {investorItem.fitScore !== undefined
            ? generateFitScoreTag(investorItem.fitScore)
            : '-'}
        </span>
      </td>

      <td className="app-table-row-item">
        <div className="app-table-row-text w-fit">
          <PrioritySelect
            value={investorItem?.priority?.priority ?? Priority.None}
            onSelect={(priority) => {
              onPrioritySelected(investorItem, priority);
            }}
          />
        </div>
      </td>

      <td className="app-table-row-item">
        {investorItem.relationship?.lastContactedOn ? (
          <div className="app-table-row-text">
            {howLongBetween(investorItem.relationship.lastContactedOn)}
            <div className="app-table-row-text--light">
              {formatDate(investorItem.relationship?.lastContactedOn)}
            </div>
          </div>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>

      <td className="app-table-row-item">
        {investorItem.meetingTasks.length ? (
          <div className="app-table-row-text">
            {meetingTasksCompleted.length}
            {meetingTasksCompleted.length > 0 &&
              meetingTasksCompleted.length ===
                investorItem.meetingTasks.length && (
                <CheckIcon className="ml-2 inline-flex size-[1rem] rounded border border-gray-300 text-green-600" />
              )}
            <div className="app-table-row-text--light">
              out of {investorItem.meetingTasks.length}
            </div>
          </div>
        ) : (
          <div className="app-table-row-text">-</div>
        )}
      </td>
      <td className="app-table-row-item">
        <div className="app-table-row-text">{investorItem.notesCount}</div>
      </td>

      <td className="app-table-row-item">
        <div className="app-table-row-text">{investorItem.totalViewCount}</div>
        {investorItem.lastViewedOn.getTime() !== MIN_DATE.getTime() && (
          <div className="app-table-row-text--light">
            {howLongBetween(investorItem.lastViewedOn)}
          </div>
        )}
      </td>

      <td className="app-table-row-item">
        <div className="app-table-row-text">
          {investorItem.commitment?.amount
            ? `$${abbreviateNumber(investorItem.commitment?.amount)}`
            : '-'}
        </div>
      </td>

      <td
        className="whitespace-nowrap px-3 py-3 text-right text-sm font-medium"
        role="gridcell"
      >
        <DropdownButton
          label={'Manage Investor'}
          button={
            <MenuButton
              className="rounded-md px-2 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              onClick={(e: any) => e.stopPropagation()}
            >
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          }
        >
          <MenuItem>
            {({ focus }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onUpdateInvestor(investorItem);
                }}
                className={joinClassNames(
                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Update investor status"
              >
                <ArrowsRightLeftIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Update Status
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ focus }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onUpdateRelationship(investorItem);
                }}
                className={joinClassNames(
                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Update investor relationship"
              >
                <UsersIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Update Relationship
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ focus }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onAssignInvestorToList(investorItem);
                }}
                className={joinClassNames(
                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Assign investor to Target Lists"
              >
                <div className="relative mr-3 text-gray-400 group-hover:text-gray-500">
                  <QueueListIcon className="h-5 w-5" aria-hidden="true" />
                  <ArrowLongUpIcon
                    className="absolute -bottom-1 -right-1 h-3.5 w-3.5 bg-white group-hover:bg-gray-100"
                    aria-hidden="true"
                  />
                </div>
                Add to Target Lists
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ focus }) => (
              <button
                type="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  onRemoveInvestor(investorItem);
                }}
                className={joinClassNames(
                  focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'group flex w-full items-center px-4 py-2 text-sm',
                )}
                title="Remove investor from pipeline"
              >
                <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                Remove
              </button>
            )}
          </MenuItem>
        </DropdownButton>
      </td>
    </>
  );
}

export default InvestorPipelineTableRow;
