import React, { Fragment, useState } from 'react';
import FormField from '/src/interfaces/FormField';
import { joinClassNames } from '../util/formatting/strings';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

export type FormSection = {
  title: string;
  fields: FormField[];
  fieldsLayout?: string;
  headerStyles?: string;
  description?: string[];
  footer?: JSX.Element;
};

function FormCard({
  title,
  fields,
  fieldsLayout = 'px-4 py-5 sm:px-6',
  headerStyles = 'top-20',
  description,
  footer,
}: FormSection): JSX.Element {
  const [isShowingDescription, setIsShowingDesciption] = useState(false);
  const hasDescription = description && description.length > 0 ? true : false;
  return (
    <div>
      <div className="bg-white p-0.5 shadow sm:rounded-md">
        <div className="bg-white sm:rounded-md">
          <header
            className={joinClassNames(
              headerStyles,
              'sticky z-10 rounded-t-md border-b border-gray-200 bg-white px-4 py-5 sm:px-6',
            )}
          >
            <div className="flex justify-between">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {title}
              </h3>

              {hasDescription && (
                <button
                  type="button"
                  className={joinClassNames(
                    'flex justify-between rounded-lg px-3 py-1 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75',
                    isShowingDescription ? 'bg-blue-200' : 'bg-blue-100',
                  )}
                  onClick={() => setIsShowingDesciption(!isShowingDescription)}
                >
                  <InformationCircleIcon className="mr-1 mt-0.5 h-4 w-4" />
                  <span>Show best practices</span>
                </button>
              )}
            </div>

            {isShowingDescription && description && (
              <div className="mt-2 p-1.5 text-gray-700">
                <ul className="list-inside list-disc space-y-2 text-sm">
                  {description.map((line) => (
                    <li key={line}>{line}</li>
                  ))}
                </ul>
              </div>
            )}
          </header>

          <div className={fieldsLayout}>
            {fields.map((field) => (
              <Fragment key={fields.indexOf(field)}>
                {field.fieldComponent}
              </Fragment>
            ))}
          </div>

          {footer && footer}
        </div>
      </div>
    </div>
  );
}

export default FormCard;
