import React, { ReactNode } from 'react';
import { BaseMapper } from '/src/util/mapper';
import { StartupAccessManagerViewSessionView } from '../../../../types/view/StartupAccessManagerView';
import InsightCell from '/src/components/table/cells/InsightCell';
import DurationCell from '/src/components/table/cells/DurationCell';
import LastViewCell from '/src/components/table/cells/LastViewCell';
import ViewSessionsReceivedFromCell from './Cells/AccessManagerViewSessionsReceivedFromCell';
import ViewSessionsNameCell from './Cells/AccessManagerViewSessionsNameCell';
import ViewSessionsActionCell from './Cells/AccessManagerViewSessionsActionCell';
import { DeckViewedCell } from '/src/components/table/cells/DeckViewedCell';
import { DataRoomViewStatusCell } from '/src/components/table/cells/DataRoomViewStatusCell';

export enum ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS {
  IMPORT_INVESTOR,
}

export type AccessManagerViewSessionPropsActionProps = {
  type: ACCESS_MANAGER_VIEW_SESSIONS_ACTIONS.IMPORT_INVESTOR;
  payload: {
    viewSession: StartupAccessManagerViewSessionView;
  };
};

export interface AccessManagerViewSessionsRow
  extends Record<string, ReactNode> {
  id: string;
  name: ReactNode;
  lastViewedOn: ReactNode;
  totalViewTime: ReactNode;
  deckViewed: ReactNode;
  dataRoomViewStatus: ReactNode;
  insights: ReactNode;
  receivedFrom: ReactNode;
  action: ReactNode;
  isClickable: boolean;
}

export class AccessManagerViewSessionsMapper extends BaseMapper<
  StartupAccessManagerViewSessionView,
  AccessManagerViewSessionsRow
> {
  private _onAction: ({
    type,
    payload,
  }: AccessManagerViewSessionPropsActionProps) => void;
  constructor(
    onAction: ({
      type,
      payload,
    }: AccessManagerViewSessionPropsActionProps) => void,
  ) {
    super();
    this._onAction = onAction;
  }

  mapTo(viewSession: StartupAccessManagerViewSessionView) {
    const isClickable = viewSession.connectionId !== undefined;
    return {
      id: viewSession.sessionId,

      name: <ViewSessionsNameCell viewSession={viewSession} />,
      lastViewedOn: <LastViewCell lastViewedOn={viewSession.lastViewedOn} />,
      totalViewTime: (
        <DurationCell durationInMs={viewSession.totalMillisecondsSpentOnDeal} />
      ),
      deckViewed: (
        <DeckViewedCell
          deckViewed={viewSession.deckViewed}
          totalMillisecondsSpentOnDeck={
            viewSession.totalMillisecondsSpentOnDeck
          }
          totalDeckDownloadCount={viewSession.totalDeckDownloadCount}
        />
      ),
      dataRoomViewStatus: (
        <DataRoomViewStatusCell
          dataRoomViewStatus={viewSession.dataRoomViewStatus}
        />
      ),
      insights: (
        <InsightCell
          location={viewSession.location}
          deviceType={viewSession.deviceType}
          deviceOs={viewSession.deviceOs}
          browser={viewSession.browser}
        />
      ),
      receivedFrom: <ViewSessionsReceivedFromCell viewSession={viewSession} />,

      action: (
        <ViewSessionsActionCell
          viewSession={viewSession}
          onAction={this._onAction}
        />
      ),
      isClickable,
    };
  }
}
