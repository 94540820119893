import React, { useState, useCallback, useEffect } from 'react';
import { Description, Field, Label, Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/react';
import { joinClassNames } from '/src/util/formatting/strings';

export interface ToggleSwitchProps {
  canToggleOnLabelClick?: boolean;
  initialState?: boolean;
  isDisabled?: boolean;
  label?: string;
  name: string;
  secondaryLabel?: string;
  showToggleIcon?: boolean;
  toggleOff: () => void;
  toggleOn: () => void;
}

function ToggleSwitch({
  canToggleOnLabelClick = true,
  initialState = false,
  isDisabled = false,
  label,
  name,
  secondaryLabel,
  showToggleIcon,
  toggleOff,
  toggleOn,
}: ToggleSwitchProps): JSX.Element {
  const [enabled, setEnabled] = useState(initialState);

  function updateEnabled() {
    if (!enabled) {
      setEnabled(true);
      toggleOn();
    } else {
      setEnabled(false);
      toggleOff();
    }
  }

  const handleChange = useCallback(() => {
    updateEnabled();
  }, [updateEnabled]);

  useEffect(() => {
    setEnabled(initialState);
  }, [initialState]);

  return (
    <Field
      as="div"
      className="flex items-center justify-between space-x-5"
    >
      {(label || secondaryLabel) && (
        <span className="flex flex-col space-y-1">
          {label && (
            <Label as="span" passive={canToggleOnLabelClick}>
              {label}
            </Label>
          )}
          {secondaryLabel && (
            <Description as="span" className="text-xs text-gray-800">
              {secondaryLabel}
            </Description>
          )}
        </span>
      )}
      <Switch
        name={name}
        checked={enabled}
        onChange={handleChange}
        disabled={isDisabled}
        className={joinClassNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex h-[23.2px] w-[43.2px] min-w-[43px] cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
        )}
      >
        <span
          className={joinClassNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        >
          {showToggleIcon && (
            <>
              <span
                className={joinClassNames(
                  enabled
                    ? 'opacity-0 duration-100 ease-out'
                    : 'opacity-100 duration-200 ease-in',
                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                )}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-gray-400"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                className={joinClassNames(
                  enabled
                    ? 'opacity-100 duration-200 ease-in'
                    : 'opacity-0 duration-100 ease-out',
                  'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
                )}
                aria-hidden="true"
              >
                <svg
                  className="h-3 w-3 text-blue-600"
                  fill="currentColor"
                  viewBox="0 0 12 12"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </>
          )}
        </span>
      </Switch>
    </Field>
  );
}

export default ToggleSwitch;
