import { isRowBlurred } from '../components/table/BackendPaginatedTable';

export const rowBlurClass = ({
  parentPage,
  index,
  blurRowsAfterIndex,
}: {
  parentPage: number;
  index: number;
  blurRowsAfterIndex?: number;
}) =>
  isRowBlurred(parentPage, index, blurRowsAfterIndex)
    ? 'transform-gpu blur cursor-default select-none'
    : '';
