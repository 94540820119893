import React from 'react';
import UserAvatarWithInitials from '/src/components/UserAvatarWithInitials';
import {
  formatImageAddress,
  splitOnWhiteSpace,
} from '/src/util/formatting/strings';
import { StartupAccessManagerView } from '../../../../../types/view/StartupAccessManagerView';
import CopyTextButton from '/src/components/CopyTextButton';

export interface AccessManagerInvestorsNameCellProps {
  accessManagerEntry: StartupAccessManagerView;
  isBlurred: boolean;
}
function AccessManagerInvestorsNameCell({
  accessManagerEntry,
  isBlurred,
}: AccessManagerInvestorsNameCellProps) {
  return (
    <div className="flex items-center">
      <div className="h-10 w-10 shrink-0">
        <UserAvatarWithInitials
          containerStyles="h-10 w-10"
          firstName={splitOnWhiteSpace(accessManagerEntry.name)[0]}
          lastName={splitOnWhiteSpace(accessManagerEntry.name)[1]}
          textStyles="text-xl"
          imgAlt="Investor Profile"
          imgSrc={formatImageAddress(accessManagerEntry.picKey)}
        />
      </div>
      <div className="ml-2">
        <div className="app-table-row-text--bold">
          {accessManagerEntry.name}
        </div>
        {!isBlurred && (
          <div className="text-sm text-gray-500">
            <a
              className="truncate hover:underline"
              href={`mailto:${accessManagerEntry.email}`}
              onClick={(e) => e.stopPropagation()}
            >
              {accessManagerEntry.email}
            </a>
            <CopyTextButton
              text={accessManagerEntry.email}
              className="absolute ml-1"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AccessManagerInvestorsNameCell;
